///////////////////////////////////////////////////////////////////////////////////////////////////
//     _                            __
//    (_)___ ___  ____  ____  _____/ /_
//   / / __ `__ \/ __ \/ __ \/ ___/ __/
//  / / / / / / / /_/ / /_/ / /  / /_
// /_/_/ /_/ /_/ .___/\____/_/   \__/
//            /_/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

import Errors from '@/components/pages/Errors'
import Form from '@/components/forms/Form'
import FormField from '@/components/forms/FormField'
import Footer from '@/components/pages/Footer'
import LoadingScreen from '@/components/pages/LoadingScreen'
import LoadingSpinner from '@/components/pages/LoadingSpinner'
import LoadingBar from '@/components/pages/LoadingBar'
import Logo from '@/components/images/Logo'
import MaskInput from '@/components/forms/MaskInput'
import Page from '@/components/pages/Page'
import PageHeading from '@/components/pages/PageHeading'
import Snackbar from '@/components/pages/Snackbar'
import LargeAvatar from '@/components/avatars/LargeAvatar'
import SmallAvatar from '@/components/avatars/SmallAvatar'
import CookieDialog from '@/components/pages/CookieDialog'
import AvatarConsentDialog from '@/components/avatars/ConsentDialog'
import ConfirmDialog from '@/components/ConfirmDialog'

import AvatarWizard from '@/components/avatars/create-wizard/Wizard'
import AvatarWebCam from '@/components/avatars/create-wizard/WebCam'
import AvatarWizardOnboarding from '@/components/avatars/create-wizard/Onboarding'
import AvatarWizardFileSelect from '@/components/avatars/create-wizard/FileSelect'
import AvatarWizardErrorImage from '@/components/avatars/create-wizard/ErrorImage'
import AvatarWizardAdjustImage from '@/components/avatars/create-wizard/AdjustImage'
import AvatarWizardAvatarMask from '@/components/avatars/create-wizard/AvatarMask'
import AvatarWizardGenerating from '@/components/avatars/create-wizard/Generating'
import AvatarWizardGeneratingImage from '@/components/avatars/create-wizard/GeneratingImage'

import AvatarWizardTakePhoto1Header from '@/components/avatars/create-wizard/TakePhoto1Header'
import AvatarWizardTakePhoto1Footer from '@/components/avatars/create-wizard/TakePhoto1Footer'

import AvatarWizardTakePhoto2Header from '@/components/avatars/create-wizard/TakePhoto2Header'
import AvatarWizardTakePhoto2Footer from '@/components/avatars/create-wizard/TakePhoto2Footer'

import AvatarWizardTakePhoto3Header from '@/components/avatars/create-wizard/TakePhoto3Header'
import AvatarWizardTakePhoto3Footer from '@/components/avatars/create-wizard/TakePhoto3Footer'

import AvatarWizardTimerHeader from '@/components/avatars/create-wizard/TimerHeader'
import AvatarWizardTimerFooter from '@/components/avatars/create-wizard/TimerFooter'

import AvatarWizardPhotoTaken from '@/components/avatars/create-wizard/PhotoTaken'
import AvatarWizardCameraPermissionsDialog from '@/components/avatars/create-wizard/CameraPermissionsDialog'
import DashboardNav from '@/components/nav/DashboardNav'
import UserMenu from '@/components/nav/UserMenu'
import UserAvatarCircle from '@/components/nav/UserAvatarCircle'
import Session from '@/components/sessions/Session'
import SessionCard from '@/components/sessions/SessionCard'

import SessionDialogDetails from '@/components/sessions/DialogDetails'
import SessionDialogRemindMe from '@/components/sessions/DialogRemindMe'
import SessionTimeZoneSelect from '@/components/sessions/TimeZoneSelect'
import SessionUnion from '@/components/sessions/Union'

import EditFileModal from '@/components/files/EditFileModal'
import DeleteModal from '@/components/modals/DeleteModal'
import FilePreviewModal from '@/components/files/FilePreviewModal'

///////////////////////////////////////////////////////////////////////////////////////////////////
//                     _      __
//    ________  ____ _(_)____/ /____  _____
//   / ___/ _ \/ __ `/ / ___/ __/ _ \/ ___/
//  / /  /  __/ /_/ / (__  ) /_/  __/ /
// /_/   \___/\__, /_/____/\__/\___/_/
//           /____/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

export default {
  'c-errors': Errors,
  'c-form': Form,
  'c-form-field': FormField,
  'c-footer': Footer,
  'c-loading-screen': LoadingScreen,
  'c-loading-spinner': LoadingSpinner,
  'c-logo': Logo,
  'c-mask-input': MaskInput,
  'c-page': Page,
  'c-page-heading': PageHeading,
  'c-snackbar': Snackbar,
  'c-loading-bar': LoadingBar,

  'c-large-avatar': LargeAvatar,
  'c-small-avatar': SmallAvatar,

  'c-cookie-dialog': CookieDialog,
  'c-avatar-consent-dialog': AvatarConsentDialog,
  'c-confirm-dialog': ConfirmDialog,

  'c-avatar-wizard': AvatarWizard,
  'c-avatar-wizard-web-cam': AvatarWebCam,
  'c-avatar-wizard-onboarding': AvatarWizardOnboarding,
  'c-avatar-wizard-file-select': AvatarWizardFileSelect,
  'c-avatar-wizard-error-image': AvatarWizardErrorImage,
  'c-avatar-wizard-adjust-image': AvatarWizardAdjustImage,
  'c-avatar-wizard-avatar-mask': AvatarWizardAvatarMask,
  'c-avatar-wizard-generating': AvatarWizardGenerating,
  'c-avatar-wizard-generating-image': AvatarWizardGeneratingImage,

  'c-avatar-wizard-take-photo-1-header': AvatarWizardTakePhoto1Header,
  'c-avatar-wizard-take-photo-1-footer': AvatarWizardTakePhoto1Footer,

  'c-avatar-wizard-take-photo-2-header': AvatarWizardTakePhoto2Header,
  'c-avatar-wizard-take-photo-2-footer': AvatarWizardTakePhoto2Footer,

  'c-avatar-wizard-take-photo-3-header': AvatarWizardTakePhoto3Header,
  'c-avatar-wizard-take-photo-3-footer': AvatarWizardTakePhoto3Footer,

  'c-avatar-wizard-timer-header': AvatarWizardTimerHeader,
  'c-avatar-wizard-timer-footer': AvatarWizardTimerFooter,

  'c-avatar-wizard-photo-taken': AvatarWizardPhotoTaken,
  'c-camera-permissions-dialog': AvatarWizardCameraPermissionsDialog,
  'c-dashboard-nav': DashboardNav,
  'c-user-menu': UserMenu,
  'c-user-avatar-circle': UserAvatarCircle,

  'c-session': Session,
  'c-session-card': SessionCard,
  'c-session-dialog-details': SessionDialogDetails,
  'c-session-dialog-remind-me': SessionDialogRemindMe,
  'c-session-time-zone-select': SessionTimeZoneSelect,
  'c-session-union': SessionUnion,

  'c-delete-modal': DeleteModal,
  'c-file-preview-modal': FilePreviewModal,
  'c-edit-file-modal': EditFileModal,
}
