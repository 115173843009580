import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'

const defaultState = () => ({
  sounds: {
    countdown: {
      filePath: '/sounds/countdown.mp3',
      instance: undefined,
    },
    camera: {
      filePath: '/sounds/camera.mp3',
      instance: undefined,
    },
  },
})

const properties = () => Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties())

const defaultMutations = makeDefaultMutations(properties(), defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  play({ commit }, sound) {
    commit('play', sound)
  },
  stop({ commit }, sound) {
    commit('stop', sound)
  },
}

const mutations = {
  ...defaultMutations,
  play(state, sound) {
    state.sounds[sound].instance = new Audio(state.sounds[sound].filePath)
    state.sounds[sound].instance.play()
  },
  stop(state, sound) {
    if (state.sounds[sound].instance) {
      state.sounds[sound].instance.pause()
      state.sounds[sound].instance.currentTime = 0
    }
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
