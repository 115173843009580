<template>
  <div class="session-container clickable">
    <div class="session-card" @click="$emit('showSession', session)">
      <div class="session-card-header"></div>

      <div class="session-card-detail">
        <div class="session-card-text">
          <div class="event-name">Morningstar conference</div>
          <div class="session-name">{{ sessionName }}</div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="session-date mb-4" v-bind="attrs" v-on="on">
                {{ `${sessionDate(inUserTimeZone)}` }} <span class="gray-text">•</span>
                {{ `${sessionTime(inUserTimeZone)}` }}
                <span class="gray-text"
                  >({{ `${sessionTimeZoneAbbreviation(inUserTimeZone)})` }}</span
                >
              </div>
            </template>
            <div>{{ toolTipTime(inUserTimeZone) }}</div>
            <div>{{ toolTipUTCAndCities(inUserTimeZone) }}</div>
          </v-tooltip>

          <div class="d-flex align-center">
            <c-user-avatar-circle
              v-if="sessionSpeakerImageUrl"
              size="32"
              class="ml-2"
              :url="sessionSpeakerImageUrl"
              :name="sessionSpeakerName"
            />
            <div v-else class="ml-2">
              <v-avatar :size="32" class="mr-4" color="#EEEEEE">
                <img
                  :src="'/images/person.svg'"
                  :alt="`${this.session.speakers[0].name}`"
                  class="no-image"
                />
              </v-avatar>
            </div>
            <div class="">
              <div class="speaker-name">{{ sessionSpeakerName }}</div>
              <div class="speaker-role">{{ sessionSpeakerRole }}</div>
            </div>
          </div>
        </div>

        <div class="remind-me-button mt-4" @click="$emit('remindMe')">
          <v-icon dark size="20" class="mr-2">mdi-bell</v-icon>
          <div class="remind-me-text">
            {{ $t('sessions.list.remindMe') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SessionMixin from './SessionMixin'
export default {
  mixins: [SessionMixin],
  props: ['session', 'timeZone'],
  computed: {
    inUserTimeZone() {
      return this.timeZone === 'Local'
    },
    sessionName() {
      let characterLimit = this.$vuetify.breakpoint.smAndUp ? 50 : 70
      return this.session.header.length > characterLimit
        ? this.session.header.substring(0, characterLimit - 3) + '...'
        : this.session.header
    },
    sessionSpeakerImageUrl() {
      return this.session.speakers[0].imageLink ?? undefined
    },
    sessionSpeakerName() {
      let speaker = this.session.speakers[0]
      return speaker.name.length > 25 ? speaker.name.substring(0, 22) + '...' : speaker.name
    },
    sessionSpeakerRole() {
      let speaker = this.session.speakers[0]
      let string = speaker.company ? `${speaker.role} @ ${speaker.company}` : speaker.role
      return string.length > 25 ? string.substring(0, 22) + '...' : string
    },
  },
}
</script>
<style lang="scss" scoped>
.gray-text {
  color: #898a8d;
}
.session-container {
  width: 100%;
  margin-bottom: 24px;
}
@media screen and (min-width: 600px) {
  .session-container {
    width: auto;
    margin: 24px;
  }
}
</style>
