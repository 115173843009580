<template>
  <div>
    <span>
      <h1 class="cardHeader">
        <p class="mb-0" data-cy="welcomeTo">
          {{ $t('auth.login.welcomeTo') }}
        </p>
        <p class="primary--text gatherings" data-cy="gatherings">
          {{ $t('auth.login.gatherings') }}
        </p>
      </h1>
    </span>
    <c-form :fields="fields" @submit="onSubmit" class="mt-4 pa-2 login-form">
      <template v-slot:card-form-footer>
        <p class="grey--text text--darken-2" data-cy="signInOrCreateAnAccountUsingYourEmail">
          <small>{{ $t('auth.login.signInOrCreateAnAccountUsingYourEmail') }}</small>
        </p>
      </template>
    </c-form>
  </div>
</template>

<script>
import { isEmailAddress, isRequired } from '@/utilities/validations'
import { addId } from '@/utilities/functions'
import { mapActions } from 'vuex'

export default {
  computed: {
    emailField() {
      return {
        class: 'mt-3 text-center login-input',
        color: 'grey darken-2',
        // label: title(this.$t('fields.emailAddress')),
        rules: [isEmailAddress, isRequired],
        type: 'email',
        name: 'email',
        autofocus: true,
        placeholder: this.$t('fields.emailAddress'),
        flat: true,
        solo: true,
        dataCy: 'email-input',
      }
    },
    fields() {
      return addId([this.emailField], true)
    },
  },
  data: () => {
    return {
      isSubmitting: false,
    }
  },
  methods: {
    ...mapActions('auth', ['requestPasscode']),
    onSubmit(formData) {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('auth/requestPasscode', formData).then(
        () => {
          this.isSubmitting = false
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.$router.push('/passcode')
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.isSubmitting = false
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.cardHeader {
  font-size: 2.2rem;
  line-height: 1.2;
}
</style>
