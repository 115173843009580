<template>
  <div class="registerPageContainer">
    <div class="cardHeader">
      <h2 class="mb-0 primary--text">Before we go ahead...</h2>
      <small>
        <p class="pt-2 mt-5 mb-0 grey--text text--darken-1">
          {{ $t('onboarding.terms.toUseThisSiteYouNeedToAcceptTheFollowingTerms') }}
        </p>
      </small>
    </div>

    <div style="width: 75%; margin: 0 auto; padding-top: 0.5rem; min-width: 220px">
      <div v-if="requireGeneralTerms">
        <div class="py-1" data-cy="terms-of-use-link">
          <a class="primary--text noUnderline" target="_blank" :href="$links.generalTerms">
            {{ $t('onboarding.terms.termsOfUse') | title }}
          </a>
        </div>
        <div class="py-1" data-cy="privacy-policy-link">
          <a class="primary--text noUnderline" target="_blank" :href="$links.privacyPolicy">
            {{ $t('onboarding.terms.privacyPolicy') | title }}
          </a>
        </div>
      </div>
      <div v-if="requireSpecialTerms">
        <div class="py-1" data-cy="special-data-terms-link">
          <a class="primary--text noUnderline" target="_blank" :href="$links.specialDataTerms">
            {{ $t('onboarding.terms.specialDataTerms') | title }}
          </a>
        </div>
      </div>

      <div style="max-width: 220px; margin: 0 auto" class="justify-start">
        <!-- Terms of use -->
        <div v-if="requireGeneralTerms">
          <v-checkbox
            class="pt-1 mt-0 mr-1"
            color="primary darken-2"
            hide-details
            v-model="termsAgreed"
            data-cy="terms-of-use-checkbox"
          >
            <template v-slot:label>
              <small>
                <span class="grey--text text--darken-1">
                  {{ $t('onboarding.terms.iAgreeToTheTermsOfUse') }}
                </span>
              </small>
            </template>
          </v-checkbox>
        </div>

        <!-- Age -->
        <div v-if="requireGeneralTerms">
          <v-checkbox
            color="primary darken-2"
            hide-details
            v-model="ageConfirmed"
            data-cy="age-confirmed-checkbox"
            class="mt-0"
          >
            <template v-slot:label>
              <small>
                <span class="grey--text text--darken-1">
                  {{ $t('onboarding.register.iConfirmIAmAtLeast_18YearsOld') }}.
                </span>
              </small>
            </template>
          </v-checkbox>
        </div>

        <!-- Special data terms -->
        <div v-if="requireSpecialTerms">
          <v-checkbox
            class="pt-1 mt-0 mr-1"
            color="primary darken-2"
            hide-details
            v-model="specialTermsAgreed"
            data-cy="special-data-terms-checkbox"
          >
            <template v-slot:label>
              <small>
                <span class="grey--text text--darken-1">
                  {{ $t('onboarding.terms.iAgreeToTheSpecialDataTerms') }}
                </span>
              </small>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>

    <v-btn
      fab
      color="secondary"
      data-cy="submit-button"
      class="mt-8"
      @click="onSubmit"
      :disabled="buttonDisabled"
    >
      <v-icon>{{ 'chevron_right' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['type'],
  data: () => ({
    submitAttempted: false,
    termsAgreed: false,
    ageConfirmed: false,
    specialTermsAgreed: false,
  }),
  computed: {
    buttonDisabled() {
      return !this.confirmed
    },
    requireSpecialTerms() {
      return this.$store.getters['auth/userHasAvatarButHasntGivenAvatarConsent']
    },
    requireGeneralTerms() {
      return !this.$store.getters['auth/userHasBasicConsents']
    },
    requireAgeConsent() {
      return !this.$store.getters['auth/userHasConfirmedAge']
    },
    confirmed() {
      return (
        (this.requireGeneralTerms &&
          this.requireSpecialTerms &&
          this.termsAgreed &&
          this.ageConfirmed &&
          this.specialTermsAgreed) ||
        (this.requireGeneralTerms &&
          !this.requireSpecialTerms &&
          this.termsAgreed &&
          this.ageConfirmed) ||
        (this.requireSpecialTerms && !this.requireGeneralTerms && this.specialTermsAgreed)
      )
    },
  },
  methods: {
    onSubmit() {
      if (this.confirmed) {
        this.$store.dispatch('ui/toggleLoading', { show: true })

        if (this.requireGeneralTerms && this.requireSpecialTerms) {
          this.$store.dispatch('auth/grantBasicConsents').then(
            this.$store.dispatch('auth/registerAvatarConsent').then(() => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.$router.push('/')
            })
          ),
            error => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.loading = false
              this.message =
                (error.response && error.response.data) || error.message || error.toString()
            }
        } else if (this.requireGeneralTerms) {
          this.$store.dispatch('auth/grantBasicConsents').then(
            () => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.$router.push('/')
            },
            error => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.loading = false
              this.message =
                (error.response && error.response.data) || error.message || error.toString()
            }
          )
        } else if (this.requireSpecialTerms) {
          this.$store.dispatch('auth/registerAvatarConsent').then(
            () => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.$router.push('/')
            },
            error => {
              this.$store.dispatch('ui/toggleLoading', { show: false })
              this.loading = false
              this.message =
                (error.response && error.response.data) || error.message || error.toString()
            }
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cardHeader {
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
.cCheckbox {
  ::v-deep {
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
}
</style>
