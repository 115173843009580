import { mergeWith, cloneDeep } from 'lodash'

let settingsLoaded = false
let env = cloneDeep(process.env) || {}
let settings = {}

export const resolveAppSettings = async () => {
  const response = await fetch(process.env.BASE_URL + 'config/appSettings.json')
  const _settings = await response.json()
  settings = mergeWith(env, _settings, (a, b) => (b === null ? a : undefined))

  settingsLoaded = true
}

export const getSettings = () => {
  return settings
}

export const getAppSetting = key => {
  if (!settingsLoaded) {
    console.warn('Warning app settings have not been loaded')
  }

  return settings[key]
}
