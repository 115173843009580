import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'
import api from '@/utilities/api'

const defaultState = () => ({
  myEcosystems: [],
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  fetchMine({ commit, state }, force = false) {
    if (state.myEcosystems.length > 0 && !force) {
      return state.myEcosystems
    }
    let options = { method: 'GET' }
    // const endpoint = 'workspaces/admin'
    const endpoint = 'api/users/workspaces'
    return api(endpoint, options).then(response => {
      let ecosystems = response.data.data
      // sort results alphabetically
      ecosystems.sort((a, b) => a.name.localeCompare(b.name))
      commit('setMyEcosystems', response.data.data)
    })
  },
}

const mutations = {
  ...defaultMutations,
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
