<template>
  <v-container
    class="py-0 px-4 mx-0 my-0"
    :class="{ 'px-8': $vuetify.breakpoint.smAndUp, 'px-15': $vuetify.breakpoint.mdAndUp }"
  >
    <v-row class="mt-6 align-self-center" :class="{ 'mt-8': $vuetify.breakpoint.smAndUp }">
      <v-col>
        <div
          class="text-left mesmerise-background"
          style="max-width: 922px"
          :class="{ marginAuto: $vuetify.breakpoint.xl }"
        >
          <button
            class="mb-6 back-button-container"
            :class="{ 'mb-8': $vuetify.breakpoint.smAndUp, 'mb-10': $vuetify.breakpoint.lgAndUp }"
            @click="goBack"
            data-cy="backButton"
          >
            <v-icon color="primary">mdi-chevron-left</v-icon>
            <span class="back-button-text">
              {{ $t('account.titles.back') }}
            </span>
          </button>

          <div
            class="mb-6"
            :class="{ 'mb-12': $vuetify.breakpoint.xs, 'mb-8': $vuetify.breakpoint.mdAndUp }"
          >
            <div
              class="page-header mb-1"
              :class="{
                pageHeaderSmAndUp: $vuetify.breakpoint.smAndUp,
                pageHeaderLgAndUp: $vuetify.breakpoint.lgAndUp,
              }"
            >
              {{ $t('account.titles.settings') }}
            </div>
            <div
              class="page-sub-header"
              :class="{ pageSubHeaderLgAndUp: $vuetify.breakpoint.lgAndUp }"
            >
              {{ $t('account.titles.edit') }}
            </div>
          </div>

          <v-card flat outlined class="card mesmerise-background mb-6">
            <div
              class="card-title"
              :class="{ 'pa-6': $vuetify.breakpoint.smAndUp, 'px-8': $vuetify.breakpoint.mdAndUp }"
            >
              {{ $t('account.titles.generalSettings') }}
            </div>
            <div
              class="card-inner"
              :class="{ 'pa-6': $vuetify.breakpoint.smAndUp, 'px-8': $vuetify.breakpoint.mdAndUp }"
            >
              <m-form ref="form" @submit="onSubmit" :buttons="formButtons" v-slot="{ errors }">
                <v-row :class="{ 'mb-6': $vuetify.breakpoint.smAndUp }">
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0 mb-4"
                    :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }"
                  >
                    <m-text-input
                      name="fullName"
                      type="text"
                      class="text-input"
                      :label="$t('fields.fullName')"
                      :placeholder="$t('fields.fullName')"
                      rules="required|firstName|hasSurname|surname"
                      :hintIcon="getHintIcon('fullName', errors)"
                      v-model="values.fullName"
                      data-cy="fullName"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pa-0 mb-4">
                    <m-text-input
                      name="email"
                      disabled
                      type="email"
                      class="text-input"
                      :label="$t('fields.email')"
                      :placeholder="$t('fields.emailAddress')"
                      rules="required|email"
                      v-model="values.email"
                      :hint="$t('fields.hints.email.contactSupportToChangeYourEmail')"
                      :hintIcon="getHintIcon('email', errors)"
                      data-cy="email"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0 mb-4"
                    :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }"
                  >
                    <m-text-input
                      name="companyName"
                      type="text"
                      class="text-input"
                      :label="$t('fields.companyName')"
                      :placeholder="$t('fields.companyName')"
                      rules="min:2|max:26"
                      v-model="values.company"
                      :hintIcon="getHintIcon('companyName', errors)"
                      data-cy="companyName"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pa-0 mb-4 d-none d-sm-flex">
                    <m-text-input
                      name="companyRole"
                      style="width: 100%"
                      type="text"
                      class="text-input"
                      :label="$t('fields.companyRole')"
                      rules="min:2|max:26"
                      :placeholder="$t('fields.companyRole')"
                      v-model="values.jobTitle"
                      :hintIcon="getHintIcon('companyRole', errors)"
                      data-cy="companyRole"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="pa-0"
                    :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }"
                    data-cy="userTimeZone-input"
                  >
                    <label class="timezone-label" for="timezonepicker">
                      {{ $t('fields.userTimeZone') }}</label
                    >
                    <m-timezone-picker
                      class="timezone-picker"
                      showSearch
                      showTimezoneField
                      :timezones="timeZones"
                      orderBy="utcOffset"
                      :menuProps="{ ...timezonePickerMenuProps, maxHeight: timeZoneMenuMaxHeight }"
                      :value="values.userTimeZone"
                      v-model="values.userTimeZone"
                      rules="required"
                      data-cy="timezonePicker"
                    />
                  </v-col>
                </v-row>
              </m-form>
            </div>
          </v-card>

          <v-card flat outlined class="card mesmerise-background">
            <div
              class="card-title"
              :class="{ 'pa-6': $vuetify.breakpoint.smAndUp, 'px-8': $vuetify.breakpoint.mdAndUp }"
            >
              {{ $t('account.delete.deleteAccount') }}
            </div>
            <div
              class="card-inner"
              :class="{ 'pa-6': $vuetify.breakpoint.smAndUp, 'px-8': $vuetify.breakpoint.mdAndUp }"
            >
              <v-row class="mb-4" :class="{ 'mb-6': $vuetify.breakpoint.smAndUp }">
                <v-col class="pa-0">
                  <div>
                    <div class="delete-account-text" v-if="!onlyAdminInEcosystem">
                      <div>
                        {{ $t('account.delete.youCanDeleteYourAccount') }}
                      </div>
                      <div>
                        {{ $t('account.delete.forMoreInformationPleaseRead') }}
                        <a href="https://gatheringsvr.com/privacy-policy/" target="_blank"
                          ><span class="privacy-policy">{{
                            $t('account.delete.privacyPolicy')
                          }}</span></a
                        >
                      </div>
                    </div>
                    <div
                      data-cy="unable-to-delete-account-text"
                      class="delete-account-text"
                      v-if="onlyAdminInEcosystem"
                    >
                      <div>{{ $t('account.delete.youAreUnableToDeleteThisAccount') }}</div>
                      <div>
                        {{ $t('account.delete.ifYouWouldLikeToDeleteYourAccount') }}
                        <a href="https://www.mesmerisevr.com/contact" target="_blank"
                          ><span class="privacy-policy">{{
                            $t('account.delete.contactUs')
                          }}</span></a
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <div class="text-center">
                    <m-modal
                      :value="showDeleteModal"
                      v-on:close="showDeleteModal = false"
                      :icon="getSVG"
                      :title="$t('account.delete.areYouSureYouWantToDeleteYourAccount')"
                      :message="$t('account.delete.byDeletingYourAccountYouWillLoseAllData')"
                      width="610px"
                    >
                      <template v-slot:activator="{}">
                        <m-button
                          :label="$t('account.delete.deleteAccount')"
                          error
                          @click="showDeleteModal = true"
                          class="buttonDefault"
                          :class="{ buttonSmAndUp: $vuetify.breakpoint.smAndUp }"
                          data-cy="showModalButton"
                          :disabled="onlyAdminInEcosystem"
                        />
                      </template>
                      <template v-slot:buttons>
                        <m-button
                          variant="secondary"
                          :label="$t('ui.cancel')"
                          style="height: 48px"
                          @click="showDeleteModal = false"
                          data-cy="cancelModalButton"
                        />
                        <m-button
                          error
                          :label="$t('account.delete.deleteAccount')"
                          style="height: 48px"
                          @click="deleteAccount"
                          data-cy="deleteAccountButton"
                        />
                      </template>
                    </m-modal>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MTimezonePicker, MTextInput, MButton, MModal, MForm } from 'gatherings-storybook'
import DeleteAccountSvg from '../../../public/images/svgs/delete-account.svg'

export default {
  components: {
    MTimezonePicker,
    MTextInput,
    MButton,
    MModal,
    MForm,
  },
  data: () => ({
    submitAttempted: false,
    showDeleteModal: false,
    user: undefined,
    values: {
      givenName: '',
      surname: '',
      fullName: '',
      email: '',
      jobTitle: '',
      company: '',
      userTimeZone: '',
      timeZone: '',
    },
    timeZones: [],
    timezonePickerMenuProps: {
      nudgeRight: '10',
    },
  }),
  created() {
    this.user = this.$store.getters['auth/user']
    Object.keys(this.user).forEach(key => {
      if (this.values[key] !== undefined) {
        this.values[key] = this.user[key]
      }
    })
    this.values.fullName = `${this.values.givenName} ${this.values.surname}`
    this.$store.dispatch('auth/isUserOnlyAdminInEcosystem')
  },
  computed: {
    onlyAdminInEcosystem() {
      return this.$store.getters['auth/userOnlyAdminInEcosystem']
    },
    getSVG() {
      return DeleteAccountSvg
    },
    formButtons() {
      return {
        Submit: {
          show: true,
          align: 'left',
          variant: 'primary',
          label: this.$t('ui.saveChanges'),
          method: this.submit,
          'data-cy': 'submitButton',
        },
      }
    },
    timeZoneMenuMaxHeight() {
      return this.$vuetify.breakpoint.height < 900 ? '200px' : '350px'
    },
  },
  methods: {
    onSubmit() {
      this.submitAttempted = true

      const fullNameSpacesCount = this.values.fullName.split(' ').length - 1
      const name = this.values.fullName.split(' ')
      if (fullNameSpacesCount < 2) {
        this.values.givenName = name[0]
        this.values.surname = name[1]
      } else {
        this.values.givenName = name[0]
        this.values.surname = name[1] + ' ' + name[2]
      }

      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('auth/accountEdit', this.values).then(
        () => {
          const params = {
            color: 'green',
            message: 'Account updated',
            buttonColor: 'white',
          }
          this.$store.dispatch('ui/launchSnackbar', params)
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.$router.push({ path: '/' })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    getHintIcon(field, errors) {
      if (errors && errors[field] && errors[field].length > 0) return 'alert-triangle-solid'
      else return null
    },
    deleteAccount() {
      this.$store.dispatch('auth/accountDelete')
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  async mounted() {
    this.timeZones = await this.$store.getters['timeZones/all']
  },
}
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}
.col {
  padding: 0;
}
.back-button-container {
  display: flex;
  align-items: center;
  max-width: fit-content;
  margin-left: -0.375rem;
  cursor: pointer;
}
.back-button-text {
  color: #807ef0;
  font-weight: 700;
  font-size: 1.1429rem;
  line-height: 24px;
}

// mobile header (xs)
.page-header {
  font-weight: 700;
  font-size: 1.7143rem;
  line-height: 30px;
  color: #1f2329;
}
.pageHeaderSmAndUp {
  font-size: 2.2857rem;
  line-height: 36px;
}
.pageHeaderLgAndUp {
  font-size: 2.7143rem;
  line-height: 46px;
}
// mobile subheader (xs)
.page-sub-header {
  font-weight: 300;
  font-size: 1.1429rem;
  line-height: 24px;
  color: #1f2329;
}
.pageSubHeaderLgAndUp {
  font-size: 1.2857rem;
}
.card {
  border: 1px solid #dce2e9;
  border-radius: 12px;
}
.card-title {
  border-bottom: 1px solid #dce2e9;
  font-weight: 400;
  font-size: 1.4286rem;
  line-height: 32px;
  padding: 1rem;
}
.card-inner {
  padding: 1rem;
}
.button-text {
  font-size: 0.75rem;
  text-transform: initial;
  font-weight: 600;
}
.buttonDefault {
  width: 100%;
}
.buttonSmAndUp {
  width: auto;
}
.text-input {
  :deep(*) {
    font-size: 1rem;
    line-height: 20px;
  }
  :deep(.text-input__hint) {
    font-size: 0.71429rem;
  }
}
.timezone-label {
  color: #464d54;
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  flex-grow: 1;
}
.timezone-picker {
  display: flex;
  max-width: fit-content;
}
.delete-account-text {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
}
.privacy-policy {
  color: #807ef0;
}
a {
  text-decoration: none;
}
//
.v-list-item--highlighted .timezone-item__subtitle {
  color: white;
}
::v-deep .text-input--filled .text-input__inner {
  background-color: white !important;
}
</style>
