<template>
  <m-modal
    v-model="localValue"
    :width="width"
    :maxWidth="maxWidth"
    @close="closePreview"
    @closed="
      showFilePreview = false
      video = null
    "
  >
    <slot></slot>
    <m-file-preview
      v-if="showFilePreview"
      :style="{ height: height }"
      :title="title"
      :subtitle="subtitle"
      :file="video ? video : file"
      :loading="loading"
      :message="message"
      :fill="fill"
    >
      <template #toolbar-after>
        <v-spacer />
        <m-button @click="closePreview" icon="cross-stroke" variant="clear" no-border />
      </template>

      <template #footer="{ type }">
        <!-- Only present the footer controls for for images -->
        <template v-if="footer && type && type.includes('image/')">
          <m-button class="delete" @click="onDelete" icon="delete-stroke" variant="clear" iconRight>
            {{ $t('files.list.delete') }}
          </m-button>
          <v-spacer />
          <m-button
            @click="onDownload"
            icon="import-stroke"
            variant="clear"
            class="download"
          ></m-button>
          <m-button @click="onEdit" class="edit" icon="edit-stroke" variant="clear"></m-button>
        </template>
        <!-- Only present the footer controls for for videos -->
        <template v-if="footer && type && type.includes('video/')">
          <div class="video-buttons">
            <m-button @click="onEdit" class="edit" icon="edit-stroke" variant="clear"></m-button>
            <m-button
              class="delete"
              @click="onDelete"
              icon="delete-stroke"
              variant="clear"
              iconRight
            >
              {{ $t('files.list.delete') }}
            </m-button>
          </div>
        </template>
      </template>
    </m-file-preview>
  </m-modal>
</template>

<script>
import { MModal, MButton, MFilePreview } from 'gatherings-storybook'
export default {
  name: 'c-file-preview-modal',
  components: {
    MModal,
    MButton,
    MFilePreview,
  },
  props: {
    /**
     * v-model True/false to show/hide the file preview modal
     */
    value: {
      type: Boolean,
    },
    /**
     * The title
     */
    title: {
      type: String,
    },
    /**
     * The subtitle
     */
    subtitle: {
      type: String,
    },
    /**
     * The file object to show
     */
    file: {
      type: [File, String, Object],
    },
    /**
     * set true to show a loading spinner
     */
    loading: {
      type: Boolean,
    },
    /**
     * A message to display
     */
    message: {
      type: String,
    },
    /**
     * determines if it should fill the container
     */
    fill: {
      type: Boolean,
    },
    /**
     * determines if it should show the footer
     */
    footer: {
      type: Boolean,
      default: true,
    },
    /**
     * width of modal
     */
    width: {
      type: [String, Number],
      default: '100%',
    },
    /**
     * max width of modal
     */
    maxWidth: {
      type: [String, Number],
      default: '90%',
    },
    /**
     * height of modal
     */
    height: {
      type: [String, Number],
      default: '90vh',
    },
  },
  data() {
    return {
      localValue: this.value,
      showFilePreview: true,
      video: null,
    }
  },
  watch: {
    localValue(newVal) {
      this.$emit('input', newVal)
      if (newVal) {
        this.showFilePreview = true
      }
    },
    value(newVal) {
      this.localValue = newVal
    },
    file(newVal) {
      if (newVal?.type?.includes('video/')) {
        this.video = newVal.video
      }
    },
  },
  methods: {
    /**
     * Closes the file preview modal
     */
    closePreview() {
      this.localValue = false
      //   this.previewModal.file = null
      this.video = null
      this.$emit('close')
    },
    /**
     * Fires when the user clicks the download button
     */
    onDownload() {
      this.$emit('download')
      this.closePreview()
    },
    /**
     * Fires when the user clicks the delete button
     */
    onDelete() {
      this.$emit('delete')
      this.closePreview()
    },
    /**
     * Fires when the user clicks the edit button
     */
    onEdit() {
      this.$emit('edit')
      this.closePreview()
    },
  },
}
</script>

<style lang="scss" scoped>
.file-preview {
  :deep(*) {
    font-family: 'Kiro' !important;
  }

  &-progress {
    bottom: 0;
  }
}
.video-buttons {
  display: flex;
  gap: 1rem;
}
</style>
