<template>
  <!-- Enable camera notice -->
  <v-dialog v-model="show" max-width="600" min-height="500">
    <v-card>
      <v-card-title class="text-16 grey lighten-2 justify-space-between">
        {{ $t('avatars.input.yourCameraIsBlocked') }}
        <v-icon @click="closeMe">close</v-icon>
      </v-card-title>

      <v-card-text v-if="isSafari" class="d-flex align-center">
        <div class="text-16 pt-4">
          {{ $t('avatars.input.chooseSafariPreferencesWebsitesCamera') }}<br />
          {{ $t('avatars.input.thenFindThisWebsiteInTheListAndSelectAllow') }}<br />
          {{ $t('avatars.input.finallyRefreshThePageYouMayAlsoNeedToRestartSafari') }}
        </div>
      </v-card-text>

      <v-card-text v-else class="d-flex align-center">
        <div class="text-16 pt-4">
          {{ $t('avatars.input.clickTheCameraBlockedIcon') }}
          <span v-if="isFirefox" class="pt-4">
            <v-icon>mdi-video-off-outline</v-icon>
          </span>
          <span v-else class="px-2 pt-4 mt-4">
            <img style="vertical-align: middle" src="/images/camera_blocked.svg" />
          </span>
          {{ $t('avatars.input.inYourBrowserSAddressBar') }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['show'],
  data() {
    return {
      isSafari: window.safari !== undefined,
      isFirefox: navigator.userAgent.indexOf('Firefox') > 0,
    }
  },
  methods: {
    closeMe() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.text-16 {
  font-size: 16px;
}
</style>
