<template>
  <div v-show="show">
    <!-- Video stream -->
    <div class="video-container" v-if="!cameraIsBlocked">
      <div class="relative video-square">
        <video
          id="video-player"
          ref="videoPlayer"
          class="video"
          autoplay
          data-cy="web-cam"
          @loadedmetadata="getVideoDimensions"
        ></video>
        <c-avatar-wizard-avatar-mask v-if="hasMask" class="mask" />
      </div>
    </div>

    <div v-else class="blocked">
      <img class="blocked-image" src="/images/camera_blocked.svg" />
    </div>

    <c-camera-permissions-dialog :show="showDialog" v-on:close="showDialog = false" />

    <!-- Invisible canvas to capture video image -->
    <canvas id="canvas" ref="canvas" class="d-none" width="1000" height="1000"></canvas>
  </div>
</template>

<script>
import { delay } from '@/utilities/functions'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    capture: {
      type: Boolean,
    },
  },
  data() {
    return {
      cameraIsBlocked: false,
      showDialog: false,
      webCamOpen: false,
      image: {},
      video: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    hasMask() {
      return ['take-photo-3', 'timer'].includes(this.$route.query.step)
    },
  },
  methods: {
    openWebcam() {
      if (this.webCamOpen) {
        return
      }

      this.webCamOpen = true

      // Handle legacy browsers that don't implement getUserMedia like chrome
      if (!('mediaDevices' in navigator)) {
        navigator.mediaDevices = {}
      }

      if (!('getUserMedia' in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented!'))
          }

          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }

      // Open the web cam, asking for permission if none exists
      const vm = this

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          vm.$nextTick(function () {
            vm.$refs.videoPlayer.srcObject = stream
            vm.$refs.videoPlayer.style.display = 'block'
          })
        })
        .catch(function (error) {
          // If user refuses, show dialog explaining how they should reenable their cameras
          if (error.name === 'NotAllowedError') {
            vm.showDialog = true
            vm.cameraIsBlocked = true
            vm.$emit('cameraIsBlocked')
          }
          /* eslint-disable no-console */
          console.error(error) // other errors are possible...
        })
    },
    async closeWebcam() {
      await delay(500)
      const video = document.querySelector('#video-player')
      if (video && video.srcObject) {
        video.srcObject.getVideoTracks().map(function (track) {
          track.stop()
        })
      }
      this.webCamOpen = false
    },
    captureImage() {
      // If the video is a horizontal rectangle, get the central square
      let sx = 0
      let sy = 0
      let sWidth = this.video.width
      let sHeight = this.video.height

      if (this.video.height !== this.video.width) {
        if (this.video.height < this.video.width) {
          // landscape
          sx = (this.video.width - this.video.height) / 2
          sWidth = this.video.height
        } else {
          // portrait
          sy = (this.video.height - this.video.width) / 2
          sHeight = this.video.width
        }
      }

      // ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
      let context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.videoPlayer, sx, sy, sWidth, sHeight, 0, 0, 1000, 1000)
      this.photo = this.dataURItoBlob(this.$refs.canvas.toDataURL())
      this.image.file = new File([this.photo], 'webcam-picture')
      this.image.name = 'webcam-picture'
      this.image.dimensions = { height: 1000, width: 1000 }
      this.image.url = this.$refs.canvas.toDataURL('image/png')
      this.$emit('image', this.image)
    },
    getVideoDimensions(e) {
      this.video.width = e.target.videoWidth
      this.video.height = e.target.videoHeight
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(',')[1])
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      var blob = new Blob([ab], { type: mimeString })
      return blob
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue) {
        if (newValue == true) {
          this.openWebcam()
        } else {
          this.closeWebcam()
        }
      },
    },
    capture: {
      immediate: true,
      handler(newValue) {
        if (newValue == true) {
          this.captureImage()
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.blocked {
  margin: auto;
  width: 250px;
}
.blocked-image {
  height: auto;
  width: 250px;
}
.video-square {
  width: 250px;
  height: 250px;

  video {
    transform: scaleX(-1);
    background: #eee;
  }
}
@media screen and (min-width: 600px) {
  .video-square {
    width: 250px;
    height: 250px;
  }
}
</style>
