import auth from '@/store/modules/auth'
import avatars from '@/store/modules/avatars'
import cookies from '@/store/modules/cookies'
import devices from '@/store/modules/devices'
import sounds from '@/store/modules/sounds'
import timeZones from '@/store/modules/timeZones'
import ecosystems from '@/store/modules/ecosystems'
import files from '@/store/modules/files'
import ui from '@/store/modules/ui'

import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })
import cloneDeep from 'lodash/cloneDeep'
import forOwn from 'lodash/forOwn'

export const initialStoreModules = {
  auth,
  avatars,
  cookies,
  devices,
  sounds,
  timeZones,
  ecosystems,
  files,
  ui,
}

const storage = {
  getItem: key => {
    try {
      return ls.get(key)
    } catch (error) {
      localStorage.removeItem('vuex')
    }
  },
  setItem: (key, value) => ls.set(key, value),
  removeItem: key => ls.remove(key),
}

const storeConfig = {
  modules: cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetAllState(state) {
      forOwn(initialStoreModules, (value, key) => {
        state[key] = cloneDeep(value.state)
      })
    },
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'avatars', 'cookies', 'devices', 'timeZones', 'ecosystems', 'files'],
      ...(process.env.VUE_APP_UNENCRYPTED_LOCAL_STORAGE !== 'true' && { storage }),
    }),
  ],
}

export default storeConfig
