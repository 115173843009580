<template>
  <div v-if="deviceCode" class="mt-12 px-4">
    <div>
      <h1 class="text-left">
        {{ $t('devices.connect.connect') }}
      </h1>
      <div class="text-left">
        {{ $t('devices.connect.pairANewHeadsetOrSignBackInToAnExistingHeadset') }}
      </div>

      <v-card elevation="6" class="pa-5 mt-5 rounded-xl connect-card">
        <div class="d-flex justify-space-between">
          <v-card-title class="primary--text" style="font-size: 1.6rem; font-weight: 500">
            {{ $t('devices.connect.pairYourDevice') }}
          </v-card-title>
          <v-icon @click="$router.push('/')" dense color="primary" class="font-weight-bold mb-1">{{
            'close'
          }}</v-icon>
        </div>

        <v-card-text>
          <div class="text-left">
            {{ $t('devices.connect.putOnYourVrHeadsetAndFollowTheStepsBelowReferBackHereAnytime') }}
          </div>
        </v-card-text>

        <ol class="small numbered-list py-3 text-left" style="color: #000">
          <li>{{ $t('devices.connect.inYourHeadsetOpenGatheringsVr') }}</li>
          <li>{{ $t('devices.connect.enterTheCodeBelowThenClickGo') }}</li>
        </ol>

        <!-- Device code -->
        <div>
          <!-- Device code -->
          <div class="device-code" style="justify-content: flex-start">
            <div class="device-code-code" data-cy="device-code">{{ deviceCode }}</div>
          </div>
        </div>

        <div class="pt-5 text-left">
          {{ $t('devices.connect.thisCodeIsValidFor_15Minutes') }}<br />
          <div
            @click="getNewCode"
            class="primary--text"
            style="cursor: pointer"
            data-cy="get-another-code"
          >
            {{ $t('devices.connect.getAnotherCode') }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.getNewCode()
  },
  data: () => ({
    deviceCode: '',
  }),
  methods: {
    getNewCode() {
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('devices/getPairingCode').then(
        () => {
          this.deviceCode = this.$store.getters['devices/pairingCode']
          this.$store.dispatch('ui/toggleLoading', { show: false })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
  },
}
</script>
<style scoped>
.device-code-code {
  width: 100%;
  font-size: medium;
}
@media screen and (min-width: 450px) {
  .device-code-code {
    width: 60%;
  }
}

@media screen and (min-width: 600px) {
  .connect-card {
    width: calc(100vw - (100vw / 2.5));
    max-width: 900px;
  }
}
</style>
