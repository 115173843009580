import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "Register" */ '@/views/onboarding/Register.vue'),
    meta: {
      title: pageTitle(String(i18n.t('onboarding.titles.register'))),
      requires: { ...requirements, registration: false },
    },
    name: 'Register',
    path: '/register',
  },
  {
    component: () => import(/* webpackChunkName: "Tips" */ '@/views/onboarding/Tips.vue'),
    meta: {
      title: pageTitle(String(i18n.t('onboarding.titles.tips'))),
      requires: requirements,
    },
    name: 'Tips',
    path: '/tips',
  },
  {
    component: () => import(/* webpackChunkName: "Tips" */ '@/views/onboarding/Terms.vue'),
    meta: {
      title: pageTitle(String(i18n.t('onboarding.titles.terms'))),
      requires: requirements,
    },
    name: 'Terms',
    path: '/terms',
  },
]

routes = setLayout(routes, 'onboarding')

export default routes
