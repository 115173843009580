export default {
  devices: {
    titles: {
      start: 'Jump straight in',
      connect: 'Connect a device',
    },
    start: {
      letsPairYourDevice: 'Lets pair your device',
      putOnYourVrHeadsetAndFollowTheStepsBelow:
        'Put on your VR headset and follow the steps below.',
      getAnotherCode: 'Get another Gatherings Key',
      thisCodeIsValidFor_15Minutes: 'This Gatherings Key is valid for 15 minutes',
      inYourHeadsetOpenGatheringsVr: 'In your headset, open Gatherings VR',
      enterTheCodeBelowThenClickGo: "Enter the Gatherings Key below then click 'Go'",
    },
    connect: {
      connect: 'Connect',
      pairANewHeadsetOrSignBackInToAnExistingHeadset:
        'Pair a new headset, or sign back in to an existing headset',
      pairYourDevice: 'Pair your device',
      putOnYourVrHeadsetAndFollowTheStepsBelowReferBackHereAnytime:
        'Put on your VR headset and follow the steps below. Refer back here anytime.',
      inYourHeadsetOpenGatheringsVr: 'In your headset, open Gatherings VR',
      enterTheCodeBelowThenClickGo: "Enter the Gatherings Key below then click 'Go'",
      thisCodeIsValidFor_15Minutes: 'This Gatherings Key is valid for 15 minutes',
      getAnotherCode: 'Get another Gatherings Key',
    },
  },
}
