<template>
  <div>
    <div class="cardHeader">
      <strong>
        <p class="mb-0">{{ $t('onboarding.register.whatSYourFirstAndLastName') }}?</p>
        <p class="mb-2 grey--text text--darken-1">
          <small>{{ $t('onboarding.register.weLlUseThisNameForYourAccount') }}</small>
        </p>
      </strong>
    </div>

    <v-form ref="form" @submit="onSubmit" class="mt-8 pa-2">
      <v-row>
        <v-col cols="12" :sm="6" class="py-0 my-0 small-col-fix" data-cy="givenName-input">
          <c-form-field :field="givenNameField" v-on:change="update"></c-form-field>
        </v-col>
        <v-col cols="12" :sm="6" class="py-0 my-0 small-col-fix" data-cy="surname-input">
          <c-form-field :field="surnameField" v-on:change="update"></c-form-field>
        </v-col>
        <v-col cols="12" :sm="6" class="py-0 my-0 small-col-fix" data-cy="company-input">
          <c-form-field :field="companyField" v-on:change="update"></c-form-field>
        </v-col>
        <v-col cols="12" :sm="6" class="py-0 my-0 small-col-fix" data-cy="jobTitle-input">
          <c-form-field :field="jobTitleField" v-on:change="update"></c-form-field>
        </v-col>
        <v-col cols="12" class="py-0 my-0 small-col-fix">
          <m-timezone-picker
            :showSearch="true"
            :timezones="timeZones"
            orderBy="utcOffset"
            :menuProps="{ ...timezonePickerMenuProps, maxHeight: timeZoneMenuMaxHeight }"
            v-model="values.userTimeZone"
            data-cy="userTimeZone"
            style="max-width: 350px; margin: auto"
          />
        </v-col>
      </v-row>

      <div class="pt-5">
        <v-checkbox
          class="cCheckbox"
          color="primary darken-2"
          hide-details
          v-model="values.ageConfirmed"
          data-cy="ageConfirmed"
        >
          <template v-slot:label>
            <small>
              <span class="grey--text text--darken-1">
                {{ $t('onboarding.register.iConfirmIAmAtLeast_18YearsOld') }}.
              </span>
            </small>
          </template>
        </v-checkbox>
        <p class="error--text my-2" v-if="showConfirmAgeError">
          <small>{{ $t('onboarding.register.pleaseConfirmThatYouAreOver18') }}</small>
        </p>
      </div>
      <p class="pt-4 mb-0 grey--text">
        <small>
          <span>{{ $t('onboarding.register.byRegisteringYouConsentToOur') }}</span>
          <a class="primary--text noUnderline" target="_blank" :href="$links.generalTerms">
            {{ $t('onboarding.register.generalTerms') }}
          </a>
          <span>.</span>
          <br />
          {{ $t('onboarding.register.toFindOutMore') }}
          <a class="primary--text noUnderline" target="_blank" :href="$links.privacyPolicy">
            {{ $t('onboarding.register.privacyPolicy') }}
          </a>
          <span>.</span>
        </small>
      </p>
      <v-btn
        fab
        color="secondary"
        class="mt-8"
        @click="onSubmit"
        :disabled="$store.getters['ui/loading']"
        data-cy="button"
        ><v-icon>{{ 'chevron_right' }}</v-icon></v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { isRequired, minChar, maxChar, noSpecialChars } from '@/utilities/validations'
import { addId } from '@/utilities/functions'
import { title } from '@/utilities/filters'
import { MTimezonePicker } from 'gatherings-storybook'
export default {
  components: {
    MTimezonePicker,
  },
  data: () => ({
    submitAttempted: false,
    values: {
      givenName: '',
      surname: '',
      company: '',
      jobTitle: '',
      userTimeZone: '',
      ageConfirmed: false,
    },
    timeZones: [],
    timezonePickerMenuProps: {
      width: '100%',
      nudgeRight: '12px',
    },
  }),

  computed: {
    fields() {
      return addId(
        [
          this.givenNameField,
          this.surnameField,
          this.companyField,
          this.jobTitleField,
          // this.userTimeZoneField,
        ],
        true
      ).map(f => ({
        ...f,
        // TODO: additional styling properties once input style confirmed
        // outlined: true,
        // rounded: true,
      }))
    },
    companyField() {
      return {
        // label: title(this.$t('fields.company')),
        placeholder: title(this.$t('fields.company')),
        name: 'company',
        rules: [minChar(2), maxChar(26), noSpecialChars],
        class: 'text-center',
        flat: true,
        solo: true,
      }
    },
    givenNameField() {
      return {
        // label: title(this.$t('fields.givenName')),
        placeholder: title(this.$t('fields.givenName')),
        name: 'givenName',
        rules: [isRequired, minChar(2), maxChar(100), noSpecialChars],
        class: 'text-center',
        flat: true,
        solo: true,
      }
    },
    jobTitleField() {
      return {
        // label: title(this.$t('fields.jobTitle')),
        placeholder: title(this.$t('fields.jobTitle')),
        name: 'jobTitle',
        rules: [minChar(2), maxChar(26), noSpecialChars],
        class: 'text-center',
        flat: true,
        solo: true,
      }
    },
    surnameField() {
      return {
        // label: title(this.$t('fields.surname')),
        placeholder: title(this.$t('fields.surname')),
        name: 'surname',
        rules: [isRequired, minChar(2), maxChar(100), noSpecialChars],
        class: 'text-center',
        flat: true,
        solo: true,
      }
    },
    showConfirmAgeError() {
      return this.submitAttempted && !this.values.ageConfirmed
    },
    timeZoneMenuMaxHeight() {
      return this.$vuetify.breakpoint.height < 900 ? '200px' : '350px'
    },
  },
  methods: {
    update({ name, value }) {
      this.values[name] = value
    },
    onSubmit() {
      this.submitAttempted = true
      if (this.showConfirmAgeError || !this.$refs.form.validate()) {
        return
      }

      this.$store.dispatch('ui/toggleLoading', { show: true })
      if (this.values.ageConfirmed) {
        this.$store.dispatch('auth/register', this.values).then(
          () => {
            this.$store.dispatch('ui/toggleLoading', { show: false })
            this.$router.push({ path: '/devices/start', query: { onboarding: 'true' } })
          },
          error => {
            this.$store.dispatch('ui/toggleLoading', { show: false })
            this.message =
              (error.response && error.response.data) || error.message || error.toString()
          }
        )
      }
    },
  },
  async mounted() {
    this.timeZones = await this.$store.getters['timeZones/all']
    this.values.userTimeZone = await this.$store.getters['timeZones/default']
  },
}
</script>

<style lang="scss" scoped>
.cardHeader {
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
.cCheckbox {
  text-align: center;
  ::v-deep {
    .v-input__slot {
      justify-content: center;
      .v-label {
        flex: none;
      }
    }
  }
}
::v-deep .v-select__selections input {
  line-height: normal !important;
}
::v-deep .text-input--filled .text-input__inner {
  background-color: white !important;
}
</style>
