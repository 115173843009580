<template>
  <div>
    <div class="wizard-header">
      <div class="wizard-title">
        {{ $t('avatars.photoTaken.howDoYouLook') }}
      </div>

      <div class="intro-text">
        {{ $t('avatars.photoTaken.ifYouReHappyWeLlUseThisImageToCreateYour_3D') }}<br />{{
          $t('avatars.photoTaken.avatarInTheVirtualSpace')
        }}.
      </div>
    </div>

    <div class="wizard-central-content">
      <div class="pa-2 d-flex justify-center align-center">
        <div class="webcam-image-container">
          <img
            v-if="this.file && this.file.url"
            class="webcam-image-preview"
            :src="this.file.url"
            id="avatarImage"
            data-cy="avatar-image"
            ref="avatarImage"
          />
        </div>
      </div>
    </div>

    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center">
        <v-btn color="primary" fab class="wizard-btn" depressed rounded @click="generateAvatar">
          <div>
            {{ $t('avatars.wizard.generateAvatar') }}
          </div>
        </v-btn>
      </div>

      <div class="mt-5 pretend-link" @click="confirmStartAgain">
        {{ $t('avatars.photoTaken.startAgain') }}
      </div>
    </div>
  </div>
</template>

<script>
import { delay } from '@/utilities/functions'
export default {
  props: ['file'],
  methods: {
    confirmStartAgain() {
      this.$emit('confirm', {
        question: this.$t('avatars.adjustImage.areYouSureYouWantToTakeAnotherPhoto'),
        callBack: this.startAgain,
      })
    },
    startAgain() {
      this.$emit('image', {
        file: undefined,
        fileName: '',
        dimensions: {
          width: 0,
          height: 0,
        },
      })
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-3' } })
    },
    generateAvatar() {
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store
        .dispatch('avatars/add', { file: this.file['file'], fileName: this.file['name'] })
        .then(
          () => {
            this.$store.dispatch('ui/toggleLoading', { show: false })
            this.$router.push({ path: '/avatars', query: { step: 'generating' } })
          },
          error => {
            this.$store.dispatch('ui/toggleLoading', { show: false })
            this.loading = false
            this.message =
              (error.response && error.response.data) || error.message || error.toString()
          }
        )
    },
  },
  async mounted() {
    await delay(500) // wait for the file to get passed up and then down...
    if (!this.file || !this.file['file']) {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-1' } })
    }
  },
}
</script>

<style lang="scss" scoped>
.webcam-image-preview {
  transform: scaleX(-1);
}
</style>
