<template>
  <div id="time-zone-list">
    <v-menu
      bottom
      :nudge-bottom="nudgeBottom"
      :nudge-left="0"
      data-cy="session-time-zone-select"
      v-model="active"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          class="time-zone-select d-flex justify-space-between align-center"
          :class="{ 'purple-border': active }"
        >
          <div>
            {{ chosenTimeZoneString }}
          </div>
          <v-icon v-if="active">mdi-menu-up</v-icon>
          <v-icon v-else>mdi-menu-down</v-icon>
        </div>
      </template>

      <v-list>
        <v-list-item class="clickable bottom-border" @click="setTimeZone('Session')">
          <v-list-item-title class="d-flex align-center">
            Session timezone ({{ timeZoneAbbreviation('Session') }})
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="$store.getters['auth/user'].userTimeZone"
          class="clickable"
          @click="setTimeZone('Local')"
        >
          <v-list-item-title class="d-flex align-center"
            >Local timezone ({{ timeZoneAbbreviation('Local') }})</v-list-item-title
          >
        </v-list-item>
        <v-list-item v-else class="clickable" @click="$router.push('/account/edit')">
          <v-list-item-title>
            <span class="no-timezone">Local timezone (not set)</span>
            <div>
              <a class="text-decoration-underline"> Set your timezone </a>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['timeZone', 'nudgeDown'],
  data() {
    return {
      active: false,
      chosenTimeZone: 'Session', // or 'Local'
      timeZones: {
        Session: 'CDT',
        Local: this.$store.getters['auth/userTimeZoneAbbreviation'],
      },
    }
  },
  methods: {
    setTimeZone(timeZone) {
      this.$emit('change', timeZone)
      this.active = false
      this.chosenTimeZone = timeZone
    },
    timeZoneAbbreviation(place) {
      return this.timeZones[place]
    },
  },
  computed: {
    chosenTimeZoneString() {
      return `${this.chosenTimeZone} timezone (${this.timeZones[this.chosenTimeZone]})`
    },
    nudgeBottom() {
      return this.nudgeDown ?? 60
    },
  },
}
</script>
<style lang="scss" scoped>
#time-zone-list {
  position: relative;
  z-index: 1;
}
.time-zone-select {
  padding: 16px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  width: 228px;
  height: 48px;
  border: 1px solid #dfe2e6;
}
.time-zone-select:hover {
  border: 1px solid #807ef0;
}
.purple-border {
  border: 1px solid #807ef0;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.no-timezone {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #9c9c9c;
}
.bottom-border {
  border-bottom: 1px solid #dfe2e6;
}
.v-list {
  padding: 0px;
}
</style>
