<template>
  <v-card
    max-width="220"
    style="position: absolute; bottom: 1rem; justify-content: normal"
    v-show="$store.getters['ui/showCookieDialog']"
  >
    <v-icon style="position: absolute; right: 10px; top: 10px" @click="hide"> close </v-icon>
    <v-card-text class="pt-4 pb-0">
      {{ $t('ui.cookies.dialog.weUseCookiesToImproveYourExperience') }}
    </v-card-text>

    <v-card-text v-if="!hasGeneralCookie">
      {{
        $t(
          'ui.cookies.dialog.clickAcceptToConsentToTheUseOfAllCookiesYouMayRemoveYourConsentAtAnyTime'
        )
      }}
    </v-card-text>
    <v-card-text v-else>
      {{
        $t(
          'ui.cookies.dialog.youHavePreviouslyAcceptedAllCookiesForThisSiteToChangeThisClickReject'
        )
      }}
    </v-card-text>

    <v-card-actions class="justify-start">
      <v-btn v-if="!hasGeneralCookie" color="green" class="white--text" @click="acceptAll">
        {{ $t('common.accept') }}
      </v-btn>
      <v-btn v-else color="primary" class="white--text" @click="rejectAll"> Reject </v-btn>

      <v-card-text class="font-weight-light text-right">
        <a :href="this.$links.privacyPolicy" target="_blank" class="noUnderline">
          {{ $t('common.privacyPolicy') }}
        </a>
      </v-card-text>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  mounted() {
    if (this.$cookies.get('gatherings_accept_cookies') !== undefined) {
      this.$store.dispatch('ui/toggleCookieDialog', false)
    } else {
      this.$store.dispatch('ui/toggleCookieDialog', true)
    }
  },
  methods: {
    hide() {
      this.$store.dispatch('ui/toggleCookieDialog', false)
    },
    acceptAll() {
      this.$store.dispatch('cookies/create', 'gatherings_accept_cookies')
      this.$store.dispatch('ui/toggleCookieDialog', false)
    },
    rejectAll() {
      this.$store.dispatch('cookies/destroy', 'gatherings_accept_cookies')
    },
  },
  computed: {
    show() {
      return this.$store.getters['ui/showCookieDialog']
    },
    hasGeneralCookie() {
      return this.$cookies.get('gatherings_accept_cookies') !== undefined
    },
  },
}
</script>
<style scoped>
.v-dialog__content {
  top: 30%;
  justify-content: normal;
}
.v-dialog > .v-card > .v-card__text {
  padding: 0 12px 10px;
}
</style>
