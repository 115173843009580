<template>
  <v-app v-bind:class="{ loading: isLoading }" class="mesmerise-background">
    <v-main>
      <c-errors></c-errors>
      <c-snackbar></c-snackbar>
      <c-loading-bar></c-loading-bar>
      <v-container fluid fill-height class="layoutContainer pa-6">
        <v-row class="align-self-stretch">
          <v-col>
            <router-link to="/" data-cy="go-home"
              ><img src="/images/svgs/1.svg" alt="logo" class="cLogo ma-3"
            /></router-link>
          </v-col>
          <v-col class="text-right">
            <div class="logOutContainer pt-2" v-if="authenticated">
              <v-btn
                text
                class="noUnderline mr-4 font-weight-bold"
                @click="logOut"
                data-cy="log-out"
                >{{ $t('auth.logOut') | title }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row class="align-self-center my-6">
          <v-col class="small-col-fix">
            <v-card
              flat
              max-width="900px"
              class="cContentCard text-center pa-2 mesmerise-background"
            >
              <router-view class="viewContainer" id="routerViewport"></router-view>
            </v-card>
          </v-col>
        </v-row>
        <v-row align-self-end class="align-self-stretch">
          <v-col class="align-self-end">
            <c-footer></c-footer>
          </v-col>
        </v-row>
        <c-cookie-dialog />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters['auth/authenticated']
    },
    isLoading() {
      return this.$store.getters['ui/loading']
    },
    hasGeneralCookie() {
      return this.$cookies.get('gatherings_accept_cookies') !== undefined
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
.cContentCard {
  margin: auto;
}
.cLogo {
  display: inline;
  max-width: 10rem;
}
.layoutContainer {
  height: 100%;
  ::v-deep .cForm {
    max-width: 500px;
    margin: auto;
  }
}
.logOutContainer {
  ::v-deep .v-btn .v-btn__content {
    text-transform: none;
    font-size: 1rem;
    font-weight: 200;
  }
}
.loading {
  cursor: progress;
}
</style>
