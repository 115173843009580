<template>
  <footer class="footer">
    <v-row>
      <v-col class="text-center">
        <span class="footerText">
          © {{ yearLastUpdated }}
          <a
            :href="this.$links.mesmeriseGlobalLtd"
            target="_blank"
            style="color: inherit; text-decoration: underline"
            >Mesmerise Global Ltd</a
          >
          &nbsp; All rights reserved.
        </span>
      </v-col>
      <v-col class="text-center">
        <span class="footerText">
          <a
            :href="this.$links.privacyPolicy"
            target="_blank"
            style="color: inherit; text-decoration: underline"
          >
            Privacy Policy
          </a>
          &nbsp;
          <a
            :href="this.$links.generalTerms"
            target="_blank"
            style="color: inherit; text-decoration: underline"
          >
            Terms of Use
          </a>
          &nbsp;
          <a
            :href="this.$links.specialTerms"
            target="_blank"
            style="color: inherit; text-decoration: underline"
          >
            Special Data Terms
          </a>
          &nbsp;
          <a
            @click="$store.dispatch('ui/toggleCookieDialog', true)"
            style="color: inherit; text-decoration: underline"
          >
            Cookie Settings
          </a>
        </span>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {
  computed: {
    yearLastUpdated() {
      return process.env.VUE_APP_YEAR_LAST_UPDATED ?? new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.footerText {
  color: #c0bbbb;
  font-size: 13px;
}
.footer {
  padding-bottom: 50px;
}
@media screen and (min-width: 600px) {
  .footer {
    padding-bottom: 0px;
  }
}
</style>
