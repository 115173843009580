import DefaultLayout from '@/layouts/Default'
import DisabledLayout from '@/layouts/Disabled'
import EmptyLayout from '@/layouts/Empty'
import LoadingLayout from '@/layouts/Loading'
import LoginLayout from '@/layouts/Login'
import OnboardingLayout from '@/layouts/Onboarding'

export default {
  DefaultLayout,
  DisabledLayout,
  EmptyLayout,
  LoadingLayout,
  LoginLayout,
  OnboardingLayout,
}
