import store from '@/plugins/store'

/**
 * Formats a file size from bytes to a human readable format e.g. 2.4MB
 * @param {number} bytes The size of the file in bytes
 * @param {number} decimalPoints The number of decimal points to allow
 * @returns
 */
export function formatFileSize(bytes, decimalPoints = 1) {
  const thresh = 1000

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** decimalPoints

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(decimalPoints) + ' ' + units[u]
}

/**
 * Validates a given mime type against a list of valid mime types.
 * The * wildcard is accepted for example a type of "image/*" will match "image/png" and "image/jpeg"
 * @param {string} mimeType The type of file
 * @param {Array<string>} validTypes A list of valid types
 * @returns
 */
export function isValidMimeType(mimeType, validTypes) {
  return validTypes.some(type => {
    const pattern = new RegExp(`${type.replace('*', '([^/]*)')}`)
    return mimeType.match(pattern)
  })
}

/**
 *
 * @param {string} storeModule
 * @param {string} storageName
 * @param {number} lifeTime
 * @param {string} fetchMethod
 * @returns storedObject|null
 */
export async function getUnexpiredSignedUrlObject(
  storeModule,
  storageName,
  lifeTime,
  fetchMethod = 'fetch'
) {
  let currentObject = await store.getters[`${storeModule}/${storageName}`]
  if (!currentObject) {
    return null
  }

  let currentTime = +new Date()
  let expiryTime = +currentObject.retrieved_at + +lifeTime

  if (currentTime < expiryTime) {
    return currentObject
  }

  // if the file was retrieved too long ago, fetch it again to refresh the url signature
  await store.dispatch(`${storeModule}/${fetchMethod}`)
  return store.getters[`${storeModule}/${storageName}`]
}
