export default {
  validations: {
    maxNCharacters: 'Max {n} characters',
    minNCharacters: 'Min {n} characters',
    mustBeAnInteger: 'Must be an integer',
    mustBeAFloat: 'Must be a float',
    mustBeAValidEmailAddress: 'Must be a valid email address',
    mustBeGreaterThanN: 'Must be greater than {n}',
    mustBeGreaterThanOrEqualToN: 'Must be greater than or equal to {n}',
    mustBeLessThanN: 'Must be less than {n}',
    mustBeLessThanOrEqualToN: 'Must be less than or equal to {n}',
    mustBeRoundedToExactlyNDecimalPlaces: 'Must be rounded to exactly {n} decimal places',
    mustBeRoundedToNDecimalPlaces: 'Must be rounded to {n} decimal places',
    mustHaveASingleDecimalPoint: 'Must have a single decimal point',
    requiresBooleanValue: 'Requires boolean value',
    thisDateMustBeInTheFuture: 'This date must be in the future',
    thisDateMustBeInThePast: 'This date must be in the past',
    thisFieldIsRequired: 'This field is required',
    thisFieldMustBeAValidDate: 'This field must be a valid date',
    thisFieldMustContainAX: 'This field must contain a {x}',
    thisFieldMustContainAnX: 'This field must contain an {x}',
    noSpecialCharacters: 'Must not contain special characters',
    firstNameRequiresMin2Characters: 'First name requires min 2 characters',
    surnameRequiresMin2Characters: 'Surname requires min 2 characters',
    pleaseEnterSurname: 'Please enter your surname',
    firstNameMaxCharacters: 'First name max 26 characters',
    surnameMaxCharacters: 'Surname max 26 characters',
  },
}
