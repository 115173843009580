<template>
  <div>
    <div v-if="!error">
      <div class="wizard-header">
        <div class="wizard-title">
          {{ $t('avatars.fileSelect.selectYourPhoto') }}
        </div>

        <div class="intro-text">
          {{
            $t(
              'avatars.fileSelect.pleaseEnsureThatThePhotoThatYouChooseMatchesTheRequirementsListedBelow'
            )
          }}
        </div>
      </div>
      <div v-if="!error" class="wizard-central-content">
        <!-- Round example preview/actual preview -->
        <div
          v-if="!error"
          class="pa-2 d-flex justify-center align-center relative"
          @click="chooseFile"
        >
          <img
            class="image-preview"
            :src="'/images/example-profile.jpg'"
            id="AvatarImage"
            data-cy="avatar-image"
            style="cursor: pointer"
          />
          <div class="tag">{{ $t('avatars.fileSelect.example') }}</div>
        </div>

        <!-- Rules list -->
        <v-row class="pa-1 tip-text wide-tips mt-2 mb-2 d-block d-sm-flex flex-grow-0">
          <v-col cols="12" sm="6" class="pb-0">
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
              <div class="ml-3">
                {{ $t('avatars.fileSelect.colourPhotoNotBlackWhite') }}
              </div>
            </div>
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
              <div class="ml-3">
                {{ $t('avatars.fileSelect.plainUnclutteredBackground') }}
              </div>
            </div>
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
              <div class="ml-3">
                {{ $t('avatars.fileSelect.brightWhiteLighting') }}
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
              <div class="ml-3">
                {{ $t('avatars.fileSelect.neutralFacialExpression') }}
              </div>
            </div>
            <div class="d-flex justify-start align-start">
              <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
              <div class="ml-3">
                {{
                  $t('avatars.fileSelect.avoidAnythingCoveringYourFaceEGFaceMasksGlassesAndHair')
                }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-else>
      <div class="wizard-header">
        <div class="wizard-title">{{ $t('avatars.fileSelect.uhOh') }}</div>

        <div class="intro-text">
          <div v-if="error === 'dimensions'">
            {{
              $t(
                'avatars.fileSelect.weNeedAHigherResolutionPhotoIdeallyLargerThan_1_000pxX_1_000px'
              )
            }}
          </div>
          <div v-if="error === 'type'">
            {{
              $t('avatars.fileSelect.theFileYouSelectedIsNotASupportedFormatPleaseSelectAJpgOrPng')
            }}
          </div>
        </div>
      </div>
      <!-- Red warning triangle image-->
      <div @click="chooseFile" class="wizard-central-content">
        <c-avatar-wizard-error-image class="mx-sm-auto" />
      </div>
    </div>

    <div class="wizard-footer">
      <!-- Button -->
      <div class="d-flex justify-content-center align-center">
        <v-btn
          fab
          color="primary"
          class="wizard-btn"
          @click="chooseFile"
          depressed
          rounded
          data-cy="wizard-file-select-select-btn"
        >
          <div v-if="!error">
            {{ $t('avatars.wizard.selectPhoto') }}
          </div>
          <div v-else>
            {{ $t('avatars.wizard.selectAnotherPhoto') }}
          </div>
        </v-btn>
      </div>

      <div class="mt-5 pretend-link" v-if="error" @click="takePhoto">
        {{ $t('avatars.wizard.takePhotoWithCamera') }}
      </div>
    </div>

    <!-- Invisible file upload component -->
    <file-upload
      extensions="jpg,jpeg,png"
      :size="3072 * 3027"
      accept=".png, .jpg, .jpeg"
      name="avatar"
      type="file"
      :drop="true"
      v-model="files"
      @input-filter="saveImage"
      ref="upload"
      input-id="vueUploadComponent"
    ></file-upload>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      image: {},
      imageName: undefined,
      error: '',
    }
  },
  methods: {
    chooseFile() {
      document.getElementById('vueUploadComponent').click()
    },
    async saveImage(newFile, oldFile, prevent) {
      this.error = ''

      // check file extension
      if (!/\.(jpg|jpeg|png)$/i.test(newFile.name)) {
        this.error = 'type'
        return prevent()
      }

      const img = await new Promise((resolve, reject) => {
        const img = new Image()
        img.addEventListener('load', () => resolve(img))
        img.addEventListener('error', reject)
        img.src = URL.createObjectURL(newFile.file)
      })

      if (img.naturalWidth < 1000 || img.naturalHeight < 1000) {
        this.error = 'dimensions'
        return prevent()
      }

      // Process file and emit
      this.image['file'] = newFile.file
      this.image['dimensions'] = { width: img.naturalWidth, height: img.naturalHeight }
      // Sanitize file name
      this.image['name'] = newFile.name
        .split(' ')
        .join('_')
        .replace(/[^A-Za-z0-9_.-]/g, '')
      // Pass file data to wizard parent component and route to new step
      this.$emit('image', this.image)
      this.$router.push({ path: '/avatars', query: { step: 'adjust-image' } })
    },
    takePhoto() {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-1' } })
    },
  },
}
</script>
<style lang="scss" scoped>
.tag {
  background-color: #7c7a7a;
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  position: absolute;
  border-radius: 4px;
  bottom: 0;
  font-size: 12px;
  line-height: 19.2px;
}
</style>
