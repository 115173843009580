<template>
  <v-dialog v-model="show" max-width="520" data-cy="avatar-consent-dialog" persistent>
    <v-card class="dialog pa-8">
      <!-- Title -->
      <div class="wrap text-center before mb-3" data-cy="before-we-begin">
        {{ $t('avatars.consent.beforeWeBeginPleaseEnsureYouHaveReadAndAgreedWithTheFollowing') }}
      </div>

      <!-- Checkbox and text -->
      <div class="d-flex mb-5">
        <v-simple-checkbox
          v-model="checked"
          data-cy="avatar-consent-checkbox"
          class="ml-1 mr-4"
        ></v-simple-checkbox>
        <div class="i-confirm font-weight-light">
          {{
            $t(
              'avatars.consent.iConfirmThatIAm_18YearsOldOrOverAndThatIHaveReadAndAgreeToGatherings'
            )
          }}
          <a :href="this.$links.specialTerms" target="_blank">Special Data Terms.</a>
        </div>
      </div>

      <!-- Buttons -->
      <div class="d-flex justify-space-between align-center">
        <v-btn
          fab
          color="primary"
          @click="cancel"
          depressed
          outlined
          rounded
          class="submit-btn-text submit-btn"
          data-cy="cancel-avatar-consent-dialog"
        >
          <div data-cy="cancel-avatar-consent-text" class="cancel-avatar-consent-text">
            {{ $t('common.cancel') }}
          </div>
        </v-btn>
        <v-btn
          fab
          color="primary"
          class="submit-btn-text submit-btn"
          @click="confirm"
          depressed
          rounded
          :disabled="!checked"
          data-cy="confirm-avatar-consent-dialog"
        >
          <div data-cy="confirm-avatar-consent-text" class="confirm-avatar-consent-text">
            {{ $t('common.confirm') }}
          </div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    cancel() {
      this.checked = false
      this.$emit('close', 'cancel')
    },
    confirm() {
      this.$store.dispatch('ui/toggleLoading', { show: true })

      this.$store.dispatch('auth/registerAvatarConsent').then(
        () => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.$store.dispatch('avatars/toggleCreateWizard', true)
          this.$emit('close', 'confirm')
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.loading = false
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog {
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(45, 49, 66, 0.3);
  border-radius: 20px;
}
.wrap {
  word-break: normal !important;
}
.before {
  font-size: 20px;
  line-height: 130%;
  font-weight: normal;
  text-align: center;
  color: #2d3142;
}
.i-confirm {
  font-size: 16px;
  line-height: 150%;
  color: #2d3142;
  font-style: normal;
  font-weight: normal;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e6e5fc !important;
}
</style>
