export const filter = (items, filters) => {
  const keys = Object.keys(filters)

  return items.filter(item => {
    return keys.every(key => {
      const filter = filters[key]

      if (typeof filter === 'undefined') return true
      if (typeof filter === 'function') return filter(item, item[key])
      return filter === item[key]
    })
  })
}

export default filter
