<template>
  <div v-show="thumbnailLoaded">
    <div class="avatar-width">
      <img
        :src="avatarSrc"
        id="AvatarSelected"
        class="large-avatar-image"
        @load="thumbnailLoaded = true"
      />
    </div>
    <p class="pt-4 text-center">
      <span style="color: #807ef0"> {{ $t('avatars.list.selectedAvatar') }}</span
      ><br />
      {{ $t('avatars.list.thisIsHowOtherPeople') }} <br />{{ $t('avatars.list.willSeeYouInVr') }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['avatar', 'avatarStyle'],
  data: () => {
    return {
      thumbnailLoaded: false,
      avatarSrc: null,
    }
  },
  mounted() {
    this.getActiveAvatarSrc()
  },
  computed: {
    activeAvatar() {
      return this.$store.getters['avatars/active']
    },
  },
  methods: {
    getActiveAvatarSrc() {
      if (this.activeAvatar?.thumbnail === '/img/avatar-pending.jpg') {
        this.avatarSrc = '/images/avatar-pending.jpg'
        return
      } else {
        this.$store
          .dispatch('avatars/avatarStyle', {
            avatarId: this.activeAvatar.id,
            style: this.avatarStyle,
          })
          .then(response => {
            this.avatarSrc = response
          })
      }
    },
  },
  watch: {
    '$store.state.avatars.active.id': function () {
      if (this.activeAvatar === null) {
        return
      } else {
        this.getActiveAvatarSrc()
      }
    },
    deep: true,
  },
}
</script>
<style scoped>
.large-avatar-image {
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  background-color: #bcbcbc;
}
@media screen and (min-width: 350px) {
  .large-avatar-image {
    max-width: 320px;
    max-height: 320px;
  }
}

.avatar-width {
  width: 250px;
  height: auto;
}
@media screen and (min-width: 350px) {
  .avatar-width {
    width: 320px;
  }
}
</style>
