import routes from '@/routes/index'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/plugins/store'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  linkActiveClass: 'linkActive',
  linkExactActiveClass: 'linkActive',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function userIsAuthenticated() {
  return store.getters['auth/authenticated'] === true
}
function userIsRegistered() {
  return store.getters['auth/userIsRegistered'] === true
}

function userHasBasicConsents() {
  return store.getters['auth/userHasBasicConsents']
}

function userHasAvatarButHasntGivenAvatarConsent() {
  return store.getters['auth/userHasAvatarButHasntGivenAvatarConsent']
}

function userHasAllConsents() {
  return store.getters['auth/userHasBasicConsents'] && store.getters['auth/userHasAvatarConsent']
}

// Following code avoids duplicate navigation errors: https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

function routeRequiresAuthentication(to) {
  return to.meta?.requires?.authentication
}

function userShouldBeIntercepted(to) {
  let result = { pushTo: undefined, query: undefined }

  // Check authentication
  if (!userIsAuthenticated() && !routeRequiresAuthentication(to)) {
    return result
  }

  if (!userIsAuthenticated() && routeRequiresAuthentication(to)) {
    result.pushTo = '/login'
    if (to.path !== '/') {
      result.query = to.path
    }
    return result
  }

  // From this point on all users should be authenticated

  // Prevent logged in users visiting the login or passcode pages
  if (['/passcode', '/login'].includes(to.path)) {
    result.pushTo = '/'
    return result
  }

  // Allow anyone who is logged in to log out
  if (['/account/logout'].includes(to.path)) {
    return result
  }

  // Check registration - i.e. user has first and last name
  if (!userIsRegistered() && to.path !== '/register') {
    result.pushTo = '/register'
    return result
  }
  if (!userIsRegistered() && to.path === '/register') {
    return result
  }

  // From this point on all users should be "registered"

  // Prevent registered user visiting register page
  if (userIsRegistered() && to.path === '/register') {
    result.pushTo = '/'
    return result
  }

  // Deal with partially registered users
  if (!userHasBasicConsents() && to.path !== '/terms') {
    result.pushTo = '/terms'
    return result
  }

  if (userHasAvatarButHasntGivenAvatarConsent() && to.path !== '/terms') {
    result.pushTo = '/terms'
    return result
  }

  // Prevent fully registered users accessing '/terms'
  if (userHasAllConsents() && to.path === '/terms') {
    result.pushTo = '/'
    return result
  }

  return result
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//    ______                     __
//   / ____/_  ______ __________/ /____
//  / / __/ / / / __ `/ ___/ __  / ___/
// / /_/ / /_/ / /_/ / /  / /_/ (__  )
// \____/\__,_/\__,_/_/   \__,_/____/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

router.beforeEach((to, from, next) => {
  const { pushTo, query } = userShouldBeIntercepted(to)
  if (pushTo !== undefined) {
    if (pushTo === false) {
      next(false)
    } else if (query !== undefined) {
      next({ path: pushTo, query: { ReturnUrl: query } })
    } else {
      next(pushTo)
    }
  } else {
    next()
  }
})

export default router
