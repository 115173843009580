<template>
  <div class="pageHeading">
    <h1 v-if="heading || $slots.heading">
      <slot name="heading"></slot>
      {{ heading }}
    </h1>
    <h3 v-if="subheading || $slots.subheading">
      <slot name="subheading"></slot>
      {{ subheading }}
    </h3>
    <p v-if="text">
      <slot name="text"></slot>
      {{ text }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
export default { props: { heading: String, subheading: String, text: String } }
</script>
