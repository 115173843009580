<template>
  <div>
    <div class="wizard-header">
      <div class="my-5 wizard-title">
        {{ $t('avatars.generating.yourAvatarIsBeingGenerated') }}
      </div>

      <div class="intro-text">
        {{ $t('avatars.generating.thisWillTakeAround_5_10Minutes') }}<br />
        {{ $t('avatars.generating.youReGoingToLookIncredible') }}
      </div>
    </div>

    <div class="wizard-central-content">
      <c-avatar-wizard-generating-image class="mx-sm-auto" />
    </div>

    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center">
        <v-btn fab color="primary" class="wizard-btn" @click="closeWizard" depressed rounded>
          <div>
            {{ $t('avatars.wizard.completionAction') }}
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeWizard() {
      this.$emit('close')
    },
  },
}
</script>
