<template>
  <v-card height="80vh" max-height="800px" class="session-dialog pa-6">
    <div class="d-flex justify-space-between align-center icon-container">
      <v-icon class="session-dialog-icon" @click="$emit('back')" data-cy="session-dialog-back"
        >mdi-chevron-left</v-icon
      >
      <v-icon class="session-dialog-icon" data-cy="session-dialog-close" @click="$emit('close')"
        >mdi-close</v-icon
      >
    </div>

    <div class="scrollable">
      <div class="session-remind-me-image"></div>

      <div class="reminder-title">Create a reminder</div>

      <div class="reminder-explanation">
        Add this session to your calendar by selecting <br />
        one of the options below.
      </div>

      <div
        class="calendar-button clickable"
        v-for="(calendar, index) in calendars"
        :key="index"
        @click="addToCalendar(index)"
      >
        <div class="d-flex justify-start align-center calendar-button-content">
          <div>
            <img
              class="calendar-logo"
              :src="`/images/calendars/${calendar.logo}`"
              :alt="`${index} calendar`"
            />
          </div>
          <div>
            {{ calendar.text }}
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { google, outlook, office365, ics } from 'calendar-link'
import SessionMixin from './SessionMixin'
export default {
  mixins: [SessionMixin],
  props: ['session'],
  data() {
    return {
      calendars: {
        ics: {
          logo: 'ics.png',
          text: 'Download ICS file ',
        },
        google: {
          logo: 'google.png',
          text: 'Add to Google calendar',
        },
        outlook: {
          logo: 'outlook.png',
          text: 'Add to Outlook calendar',
        },
        office365: {
          logo: 'office365.png',
          text: 'Add to Office 365 calendar',
        },
      },
    }
  },
  methods: {
    addToCalendar(calendar) {
      let calendarSession = {
        title: this.session.header,
        description: this.session.desText,
        start: this.sessionIsoDate, // e.g. "2019-12-29 18:00:00 +0100",
        duration: this.sessionDurationArray, // e.g. [55, "minute"],
        location: 'Gatherings VR',
        url: 'https://account.gatheringsvr.com',
      }

      let path
      switch (calendar) {
        case 'ics':
          path = ics(calendarSession)
          break
        case 'google':
          path = google(calendarSession)
          break
        case 'outlook':
          path = outlook(calendarSession)
          break
        case 'office365':
          path = office365(calendarSession)
          break
      }

      window.open(path, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.session-dialog {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .session-dialog {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
  }
}

.icon-container {
  padding: 24px;
}
@media screen and (min-width: 600px) {
  .icon-container {
    padding: 0px;
  }
}

.session-dialog-icon {
  font-size: 22px;
  color: #2d3142;
}
.session-remind-me-image {
  width: 252px;
  height: 120px;
  background: url('/images/event-header.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 20px auto 32px auto;
  border-radius: 20px;
}
.reminder-title {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #363332;
  margin-bottom: 16px;
}
.reminder-explanation {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #363332;
  opacity: 0.8;
  margin-bottom: 32px;
}
.calendar-button {
  background: #ffffff;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(128, 126, 240, 0.1);
  border-radius: 46px;
  width: 85%;
  max-width: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 13px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 600px) {
  .calendar-button-content {
    width: 200px;
    margin-left: 50px;
  }
}

.calendar-logo {
  width: 20px;
  height: auto;
  margin-right: 16px;
}
</style>
