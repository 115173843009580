export default {
  events: {
    titles: {
      show: 'Event',
    },
    show: {
      hi: 'Hi',
    },
  },
}
