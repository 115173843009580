import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'

const defaultState = () => ({
  pairingCode: '',
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  getPairingCode({ commit }) {
    let options = { method: 'POST' }
    const endpoint = 'authenticate/request-device-code'
    return api(endpoint, options)
      .then(response => {
        commit('setPairingCode', response.data.data.passcode)
      })
      .catch(error => {
        /* eslint-disable no-console */
        console.error('PAIRING CODE ERROR', error)
      })
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
