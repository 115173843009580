<template>
  <div class="errorsContainer"></div>
</template>

<script>
export default {
  computed: {
    errors() {
      return this.$store.getters['ui/globalErrors']
    },
  },
  watch: {
    errors(e, eOld) {
      if (this.haveNewErrors(e, eOld)) this.launchErrorSnackbar(e)
    },
  },
  methods: {
    concatErrors(e) {
      if (['string', 'number'].includes(typeof e)) return String(e)
      else return e?.length ? e.join('\n') : ''
    },
    haveNewErrors(n, o) {
      return n?.length && n != o
    },
    launchErrorSnackbar(e) {
      const params = {
        color: 'error',
        message: this.concatErrors(e),
        timeout: 100000,
      }
      this.$store.dispatch('ui/launchSnackbar', params)
    },
  },
}
</script>
