<template>
  <v-card height="80vh" max-height="800px" class="session-dialog">
    <div class="session-dialog-header">
      <v-btn fab dark color="#FFFFFF" x-small class="close-button" @click="$emit('close')">
        <v-icon color="#363332" size="24px"> mdi-close </v-icon>
      </v-btn>
    </div>

    <div class="session-details">
      <div class="event-name">Morningstar event</div>
      <div class="session-name">{{ session.header }}</div>

      <!-- e.g. Fri 21st Feb • 1:00pm (CDT)  • 2 hr 30 min -->
      <div class="session-date mb-10">
        {{ sessionDate(inUserTimeZone) }} <span class="gray-text">•</span>
        {{ sessionTime(inUserTimeZone) }} ({{ sessionTimeZoneAbbreviation(inUserTimeZone) }})
        <span class="gray-text">•</span> {{ sessionDuration() }}
      </div>

      <div v-if="session.desText">
        <div class="session-section-title">Overview</div>
        <div class="overview-content">
          {{ session.desText }}
        </div>
      </div>

      <div class="session-section-title">Speakers</div>
      <div v-for="(speaker, index) in session.speakers" :key="index" class="d-flex my-4">
        <!-- Speaker image -->
        <c-user-avatar-circle
          v-if="speaker.imageLink"
          size="32"
          class="ml-2"
          :url="speaker.imageLink"
          :alt="`${speaker.name}`"
        />
        <div v-else class="ml-2">
          <v-avatar :size="32" class="mr-4" color="#EEEEEE">
            <img :src="'/images/person.svg'" :alt="`${speaker.name}`" class="no-image" />
          </v-avatar>
        </div>

        <!-- Speaker description -->
        <div>
          <div class="speaker-title">{{ speakerTitle(speaker) }}</div>
          <div
            v-for="(line, index) in speaker.about.split('\n\n')"
            :key="index"
            class="speaker-about text-wrap mt-2"
          >
            {{ line }}
          </div>
        </div>
      </div>

      <div
        v-if="
          session.sponsors &&
          session.sponsors.length > 0 &&
          session.sponsors.find(sp => sp.imageLink)
        "
      >
        <div class="session-section-title mt-10">Sponsors</div>
        <div class="d-flex flex-wrap justify-space-between">
          <div
            v-for="(sponsor, index) in session.sponsors"
            :key="index"
            class="sponsor-logo-container"
          >
            <img v-if="sponsor.imageLink" class="sponsor-logo" :src="sponsor.imageLink" />
          </div>
        </div>
      </div>
    </div>

    <div class="remind-me" @click="$emit('next')">
      <div class="remind-me-button mt-4">
        <v-icon dark size="20" class="mr-2">mdi-bell</v-icon>
        <div class="remind-me-text">
          {{ $t('sessions.list.remindMe') }}
        </div>
      </div>
    </div>

    <div></div>
  </v-card>
</template>

<script>
import SessionMixin from './SessionMixin'
export default {
  mixins: [SessionMixin],
  props: ['session', 'timeZone'],
  methods: {
    speakerTitle(speaker) {
      let string = speaker.name
      if (speaker.role) {
        string = `${string} | ${speaker.role}`
      }

      if (speaker.company) {
        string = `${string} @ ${speaker.company}`
      }

      return string
    },
  },
  computed: {
    inUserTimeZone() {
      return this.timeZone === 'Local'
    },
  },
}
</script>
<style lang="scss" scoped>
.session-dialog {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .session-dialog {
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
    border-radius: 20px;
  }
}

.session-dialog-header {
  height: 37%;
  min-height: 37%;
  background: url('/images/event-header.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

// If the width breakpoint is xs (less than 600)
// And the height is more than 1.5 the width
// then set the height and max-height to 20%
@media screen and (max-width: 600px) and (min-height: 700px) {
  .session-dialog-header {
    height: 25%;
    min-height: 25%;
  }
}

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;
}
.remind-me {
  height: 98px;
  padding: 24px 40px;
}
.session-details {
  flex-grow: 1;
  overflow: scroll;
  padding: 40px;
}
.event-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #898a8d;
}
.session-name {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #363332;
  margin-bottom: 16px;
}
.session-date {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #363332;
}
.session-section-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
  color: #363332;
  margin-bottom: 24px;
}
.overview-content {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #363332;
  opacity: 0.8;
  margin-bottom: 40px;
}

.speaker-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #363332;
  margin-bottom: 8px;
}

.speaker-about {
  white-space: pre;
  // white-space: pre-line;
  //  word-break: break-all;
  //  word-wrap: normal;
}

.gray-text {
  color: #898a8d;
}

.sponsor-logo-container {
  width: 170px;
  height: 75px;
  padding-left: 5px;
}

.sponsor-logo {
  width: 120px;
  vertical-align: middle;
}
</style>
