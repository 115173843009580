<template>
  <svg class="logo" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
      fill="#807EF0"
    />
    <path
      d="M33.5141 19.7977V20.0321C33.5116 23.5332 32.1388 26.8941 29.6896 29.3959C27.2404 31.8976 23.9094 33.3414 20.4092 33.4183V33.3632H20.3541V28.4373H20.4828C22.6112 28.3265 24.6183 27.4125 26.0991 25.8797C26.2234 25.7509 26.3474 25.6127 26.4717 25.4702C26.8344 25.0418 27.1545 24.5793 27.4278 24.0892C27.7113 23.6432 27.9195 23.1536 28.044 22.6401L28.0947 22.3688L20.2991 22.3735V17.8888C24.5537 17.8888 32.0615 17.8567 33.3117 17.8888C33.4289 18.5188 33.4965 19.1571 33.5141 19.7977Z"
      fill="white"
    />
    <path
      d="M19.8373 11.6142C17.6956 11.6851 15.6615 12.5702 14.1499 14.089C12.6382 15.6078 11.7627 17.646 11.7018 19.7881H6.72046C6.78394 16.3255 8.18508 13.0219 10.6303 10.5696C13.0755 8.11722 16.375 6.70641 19.8373 6.63281V11.6142Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default { props: {} }
</script>

<style scoped>
.logo {
  width: 25px;
  height: 25px;
}
@media screen and (min-width: 600px) {
  .logo {
    width: 40px;
    height: 40px;
  }
}
.logo-circle {
  fill: #807ef0;
}

.logo-circle-g {
  fill: #fff;
}
</style>
