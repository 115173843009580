export default {
  onboarding: {
    titles: {
      register: 'Complete registration',
      tips: 'Top tip',
      terms: 'Terms and conditions',
    },
    tips: {
      tips: 'Tips',
      forMoreHelpAndTipsVisit: 'For more help and tips visit',
      keepSpacesLimitedTo_30Guests: {
        title: 'Keep spaces limited to 30 guests',
        detail:
          "Here at Gatherings, we'd recommend keeping your spaces limited to under 30 guests for the most optimised experience",
      },
    },
    register: {
      whatSYourFirstAndLastName: "What's your first and last name",
      weLlUseThisNameForYourAccount: "We'll use this name for your account",
      iConfirmIAmAtLeast_18YearsOld: 'I confirm I am at least 18 years old',
      pleaseConfirmThatYouAreOver18: 'Please confirm that you are over 18 years old',
      byRegisteringYouConsentToOur: 'By registering you consent to our',
      generalTerms: 'General terms',
      toFindOutMore:
        'To find out more about how gatherings uses and protects your personal data please read our',
      privacyPolicy: 'Privacy policy',
      selectATimeZone: 'Select a time zone',
    },
    terms: {
      toUseThisSiteYouNeedToAcceptTheFollowingTerms:
        'To use this site you need to accept the following terms',
      iAgreeToTheTermsOfUse: 'I agree to the terms of use',
      iAgreeToTheSpecialDataTerms: 'I agree to the special data terms',
      privacyPolicy: 'Privacy Policy',
      termsOfUse: 'Terms of Use',
      specialDataTerms: 'Special Data Terms',
    },
  },
}
