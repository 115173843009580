import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'

const routes = [
  {
    component: () => import(/* webpackChunkName: "SessionsList" */ '@/views/sessions/List.vue'),
    meta: {
      title: pageTitle(String(i18n.t('sessions.titles.list'))),
      requires: requirements,
    },
    name: 'Sessions',
    path: '/sessions',
  },
]

export default routes
