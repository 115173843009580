import api from '@/utilities/api'
import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'

const defaultState = () => ({
  all: [],
})

const properties = () => Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties())

const defaultMutations = makeDefaultMutations(properties(), defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
  default: (state, getters, rootState, rootGetters) => {
    // If the user has a timezone return that
    let authUser = rootGetters['auth/user']
    if (authUser?.userTimeZone) {
      return authUser.userTimeZone
    }

    // If the ip location is known, return that
    let ipDetails = rootGetters['auth/ipDetails']
    if (ipDetails?.location?.country?.timezone?.code) {
      return ipDetails.location.country.timezone.code
    }

    // Else return the environment variable or Europe/London
    return process.env.VUE_APP_DEFAULT_TIMEZONE ?? 'Europe/London'
  },
}

const actions = {
  fetch({ commit }) {
    let endpoint = `system/timezones`
    return api(endpoint).then(response => {
      const timeZones = response.data.data
      commit('setAll', timeZones)
    })
  },
}

const mutations = {
  ...defaultMutations,
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
