<template>
  <c-page :full-height="fullHeight">
    <div class="logoContainer">
      <c-logo></c-logo>
    </div>
    <h1>{{ $t('common.loading') }}</h1>
    <c-loading-spinner :loading="loading" class="mt-2" v-bind="$props"></c-loading-spinner>
  </c-page>
</template>

<script>
export default {
  props: {
    color: { default: 'primary', type: String },
    fullHeight: { default: true, type: Boolean },
    indeterminate: { default: true, type: Boolean },
    loading: { default: true, type: Boolean },
    size: { default: 60, type: [Number, String] },
  },
}
</script>

<style lang="scss" scoped>
.logoContainer {
  max-width: 300px;
  margin: auto;
}
</style>
