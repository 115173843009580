import i18n from '@/plugins/i18n'
import { extend, setInteractionMode } from 'vee-validate'
import { required, email, max, min } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: i18n.t('validations.thisFieldIsRequired'),
})

extend('max', {
  ...max,
  message: (_, { length }) => i18n.t('validations.maxNCharacters', { n: length }),
})

extend('min', {
  ...min,
  message: (_, { length }) => i18n.t('validations.minNCharacters', { n: length }),
})

extend('email', {
  ...email,
  message: i18n.t('validations.mustBeAValidEmailAddress'),
})

extend('validHexCode', {
  validate: v => !v || /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(String(v)),
  message: i18n.t('validations.pleaseProvideAValidHexCode'),
})

extend('noSpecialChars', {
  validate: v => !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(String(v)),
  message: i18n.t('validations.noSpecialCharacters'),
})

let firstNameErrorMessage = null
extend('firstName', {
  validate: v => {
    const min2Chars = /^([\w'\-,.]{2,})/.test(String(v))
    const max26Chars = /^([\w'\-,.]{26,})/.test(String(v))

    if (min2Chars && !max26Chars) {
      return true
    } else if (!min2Chars) {
      firstNameErrorMessage = i18n.t('validations.firstNameRequiresMin2Characters')
      return false
    } else if (max26Chars) {
      firstNameErrorMessage = i18n.t('validations.firstNameMaxCharacters')
      return false
    }
  },
  message: () => {
    return firstNameErrorMessage
  },
})

extend('hasSurname', {
  validate: v => /^([\w'\-,.]{2,})+\s+([\w'\-,.])/.test(String(v)),
  message: i18n.t('validations.pleaseEnterSurname'),
})

let surnameErrorMessage = null
extend('surname', {
  validate: v => {
    const min2Chars = /\s+([\w'\-,.]{2,})/.test(String(v))
    const max26Chars = /\s+([\w'\-,.]{26,})/.test(String(v))

    if (min2Chars && !max26Chars) {
      return true
    } else if (!min2Chars) {
      surnameErrorMessage = i18n.t('validations.surnameRequiresMin2Characters')
      return false
    } else if (max26Chars) {
      surnameErrorMessage = i18n.t('validations.surnameMaxCharacters')
      return false
    }
  },
  message: () => {
    return surnameErrorMessage
  },
})

setInteractionMode('eager')
