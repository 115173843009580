export default {
  sessions: {
    titles: {
      list: 'Sessions',
    },
    list: {
      remindMe: 'Remind me',
      sorrySomethingWentWrongFetchingTheEventData:
        'Sorry, something went wrong fetching the session data. Please try again later...',
      noSessionsTitle: 'There are no sessions yet...',
      noSessionsMessage: 'Looks like you have no upcoming sessions',
    },
  },
}
