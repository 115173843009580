<template>
  <v-container class="mt-md-12">
    <v-row v-if="allAvatars.length > 0">
      <v-col cols="12" :sm="6" class="d-flex justify-center">
        <c-large-avatar :avatar="activeAvatar" avatarStyle="previewIconT" />
      </v-col>

      <v-col cols="12" :sm="6">
        <h2>{{ $t('avatars.list.myAvatars') }}</h2>
        <p>
          {{ $t('avatars.list.createAVarietyOfAvatarsDressedForEveryOccasion') }}<br />
          {{ $t('avatars.list.selectWhichAvatarYouWantToUseOrCreateANewOneBelow') }}
        </p>

        <v-row>
          <v-col v-for="avatar in allAvatars" :key="avatar.id" :cols="avatarCustomBreakpoints">
            <c-small-avatar
              :avatar="avatar"
              avatarStyle="previewIconT"
              v-on:select="selectAvatar"
              v-on:destroy="destroyAvatar"
              data-cy="avatar-thumbnail"
            />
          </v-col>

          <v-col :cols="avatarCustomBreakpoints">
            <div
              class="px-2 py-3"
              @click="checkAvatarConsent"
              style="position: relative; width: 130px"
            >
              <img
                :src="'/images/avatar-icon.jpg'"
                data-cy="avatar-thumbnail"
                :id="'add-new-avatar'"
                height="115"
                width="115"
                style="
                  width: 115px;
                  height: 115px;
                  object-fit: cover;
                  border-radius: 50%;
                  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
                "
              />
              <v-btn
                color="#807ef0"
                data-cy="add-avatar"
                fab
                x-small
                absolute
                bottom
                style="bottom: 5px; left: 51px"
              >
                <v-icon style="color: white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <p class="pt-3 small">{{ $t('avatars.list.itCanTakeUpTo_5MinutesToCreateAnAvatar') }}</p>

        <v-btn
          rounded
          color="primary"
          class="update-button-text"
          @click="getAvatars"
          data-cy="check-for-updates"
          >{{ $t('avatars.list.checkForUpdates') }}</v-btn
        >
      </v-col>
    </v-row>

    <c-avatar-consent-dialog :show="showConsentDialog" v-on:close="closeConsentDialog" />
    <c-avatar-wizard
      :show="this.$store.getters['avatars/showCreateWizard']"
      v-on:close="closeWizard"
    />
  </v-container>
</template>

<script>
export default {
  async created() {
    this.getAvatars()
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      activeAvatar: undefined,
      allAvatars: [],
      showConsentDialog: false,
    }
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    // If there is a step in the url, switch the modal on and show the step
    if (this.$route.query.step) {
      this.$store.dispatch('avatars/toggleCreateWizard', true)
      this.$router.push({ path: '/avatars', query: { step: this.$route.query.step } })

      // If there is no step in the url and the store has the modal switched on, switch it off
    } else if (!this.$route.query.step && this.$store.getters['avatars/showCreateWizard']) {
      this.$store.dispatch('avatars/toggleCreateWizard', false)
    }

    // If the user has no avatars, ask them to create their first one by launching the modal
    if (
      !this.$store.getters['avatars/hasAvatar'] &&
      this.$store.getters['avatars/files']?.length === 0
    ) {
      await this.$store.dispatch('avatars/toggleCreateWizard', true)
      if (this.$store.getters['auth/userHasAvatarConsent'] === false) {
        this.showConsentDialog = true
      }
    }
  },
  methods: {
    closeWizard() {
      // Close the avatar wizard modal and show the avatar list
      this.getAvatars()
      this.$store.dispatch('avatars/toggleCreateWizard', false)

      if (
        !this.$store.getters['avatars/hasAvatar'] &&
        this.$store.getters['avatars/files'].length === 0
      ) {
        this.$router.push('/')
      } else {
        this.$router.push({ path: '/avatars' })
      }
    },
    getAvatars() {
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('avatars/fetch').then(
        () => {
          this.activeAvatar = this.$store.getters['avatars/active']
          this.allAvatars = [...this.$store.getters['avatars/all']]

          this.$store.dispatch('ui/toggleLoading', { show: false })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    selectAvatar(avatar) {
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('avatars/select', avatar).then(
        () => {
          this.activeAvatar = this.$store.getters['avatars/active']
          this.allAvatars = [...this.$store.getters['avatars/all']]
          this.$store.dispatch('ui/toggleLoading', { show: false })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    destroyAvatar(avatar) {
      if (!confirm(this.$t('avatars.list.areYouSureYouWantToDeleteThisAvatar'))) {
        return
      }

      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('avatars/destroy', avatar).then(
        () => {
          this.allAvatars = [...this.$store.getters['avatars/all']]
          this.$store.dispatch('ui/toggleLoading', { show: false })
          const params = {
            color: 'green',
            message: 'Avatar removed',
            buttonColor: 'white',
          }
          this.$store.dispatch('ui/launchSnackbar', params)
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    async checkAvatarConsent() {
      // if user hasn't given avatar consent, display modal asking them to give consent
      if (this.$store.getters['auth/userHasAvatarConsent'] === false) {
        this.showConsentDialog = true
      } else {
        await this.$store.dispatch('avatars/toggleCreateWizard', false)
        await this.$store.dispatch('avatars/toggleCreateWizard', true)
        this.$router.push({ path: '/avatars', query: { step: 'onboarding' } })
      }
    },
    async closeConsentDialog(response) {
      // 'confirm' or 'cancel'
      this.showConsentDialog = false
      if (!this.$store.getters['avatars/hasAvatar'] && response === 'cancel') {
        await this.$store.dispatch('avatars/toggleCreateWizard', false)
        this.$router.push('/')
      }
    },
  },
  computed: {
    avatarCustomBreakpoints() {
      if (this.windowWidth <= 300) {
        return 12
      } else if (this.windowWidth <= 750) {
        return 6
      } else {
        return 4
      }
    },
  },
}
</script>
<style scoped>
.v-btn {
  font-size: 12px;
  text-transform: initial !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
}
</style>
