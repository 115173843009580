<template>
  <div>
    <div
      :data-cy="'small-avatar-' + avatar.id"
      class="px-2 py-3"
      style="position: relative; width: 130px"
      v-show="thumbnailLoaded"
      @mouseover="showIcons = true"
      @mouseleave="showIcons = false"
    >
      <img
        :src="avatarSrc"
        @load="thumbnailLoaded = true"
        class="avatar-thumbnail"
        :id="avatar.id"
        height="115"
        width="115"
      />

      <v-btn
        v-if="avatar.active"
        color="#807ef0"
        fab
        x-small
        absolute
        bottom
        style="bottom: 5px; left: 50px"
        data-cy="active-avatar-check"
      >
        <v-icon style="color: white">{{ 'check' }}</v-icon>
      </v-btn>

      <div
        v-else
        v-show="showIcons === true"
        :data-cy="'avatar-action-buttons-container-' + avatar.id"
      >
        <v-btn
          color="#807ef0"
          fab
          x-small
          absolute
          bottom
          style="bottom: 5px; left: 25px"
          @click="select"
          data-cy="select-icon"
        >
          <v-icon style="color: white">{{ 'check' }}</v-icon>
        </v-btn>

        <v-btn
          color="#F25C5C"
          fab
          x-small
          absolute
          bottom
          style="bottom: 5px; left: 75px"
          @click="destroy"
          data-cy="destroy-icon"
        >
          <v-icon data-cy="trash-icon" style="color: white">mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['avatar', 'avatarStyle'],
  data: () => {
    return {
      thumbnailLoaded: true,
      showIcons: false,
      avatarSrc: null,
    }
  },
  mounted() {
    this.getAvatarSrc()
  },
  methods: {
    getAvatarSrc() {
      if (this.avatar.thumbnail === '/img/avatar-pending.jpg') {
        this.avatarSrc = '/images/avatar-pending.jpg'
        return
      } else {
        this.$store
          .dispatch('avatars/avatarStyle', {
            avatarId: this.avatar.id,
            style: this.avatarStyle,
          })
          .then(response => {
            this.avatarSrc = response
          })
      }
    },
    select() {
      this.$emit('select', this.avatar)
    },
    destroy() {
      this.$emit('destroy', this.avatar)
    },
  },
}
</script>
<style lang="scss" scoped>
.avatar-thumbnail {
  width: 115px;
  height: 115px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  background: #bcbcbc;
}
</style>
