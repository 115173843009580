<template>
  <div class="shape">
    <div class="left">
      <svg
        :width="svgWidth"
        height="336"
        viewBox="0 0 360 336"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M116.007 255.501C138.097 255.778 156.004 273.911 156.004 296.002C156.004 318.094 138.097 335.778 116.007 335.501C93.9172 335.224 76.01 317.092 76.01 295C76.01 272.909 93.9172 255.224 116.007 255.501ZM29.8729 173.119C23.0178 170.67 15.6585 169.294 7.99904 169.199C-29.0015 168.736 -58.9963 198.314 -58.9963 235.262C-58.9963 272.211 -29.0015 302.539 7.99903 303.002C36.0466 303.353 60.0686 286.442 70.0516 262.144C71.3396 259.948 72.6475 257.257 73.9738 254.025C83.0956 231.803 107.226 233.766 129.754 240.134C147.094 249.166 166.723 254.381 187.514 254.641C257.925 255.522 315.004 199.237 315.004 128.924C315.004 58.6118 257.925 0.898428 187.514 0.0177764C117.103 -0.862876 60.0238 55.4226 60.0238 125.735C60.0238 133.007 60.6344 140.145 61.8073 147.099C64.5054 189.221 52.0056 183.166 39.0338 176.883C35.9348 175.382 32.8089 173.868 29.8568 172.995L29.8729 173.119ZM360 44.6263C360 30.8191 348.808 19.4861 335.002 19.3131C321.196 19.1402 310.004 30.1929 310.004 44C310.004 57.8071 321.196 69.1402 335.002 69.3131C348.808 69.4861 360 58.4334 360 44.6263Z"
          fill="#D3D2FA"
        />
      </svg>
    </div>
    <v-icon dark color="primary" class="user-icon"> mdi-account </v-icon>
  </div>
</template>

<script>
export default {
  computed: {
    svgWidth() {
      return this.$vuetify.breakpoint.xs ? '100vw' : '100%'
    },
  },
}
</script>
<style lang="scss" scoped>
.user-icon {
  position: absolute;
  left: calc(50% - 60px);
  bottom: calc(50% - 20px);
  font-size: 100px;
}
.left {
  margin-left: -16px;
}
.shape {
  position: relative;
  width: 100vw;
  height: calc(100vw - 20%);
}

@media screen and (min-width: 600px) {
  .shape {
    width: 510px;
    height: 340px;
    margin-top: 20px;
  }
  .left {
    margin-left: 0px;
  }
  .user-icon {
    position: absolute;
    left: calc(50% - 40px);
    bottom: 50%;
    font-size: 100px;
  }
}
</style>
