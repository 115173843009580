export default function blobToImgSrc(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve(reader.result)
    }

    try {
      reader.readAsDataURL(blob)
    } catch (e) {
      reject(e)
    }
  })
}
