<template>
  <v-container align-items="center" fluid :class="{ fullHeight }">
    <v-row justify="center" align="center" :class="{ fullHeight }">
      <v-col class="text-center mx-3">
        <c-page-heading v-if="heading" class="text-decoration-underline">
          {{ heading }}
        </c-page-heading>
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default { props: { fullHeight: Boolean, heading: String } }
</script>

<style lang="scss" scoped>
.fullHeight {
  height: 100%;
}
</style>
