<template>
  <div>
    <v-avatar v-if="avatarSrc" :size="size" class="mr-4">
      <img :src="avatarSrc" :alt="userName" class="user-menu-avatar" />
    </v-avatar>
    <v-avatar v-else color="primary" :size="size" class="mr-4">
      <span class="white--text text-h5">
        {{ userInitials }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  props: ['size', 'url', 'name', 'avatarStyle'],
  data() {
    return {
      avatarSrc: null,
    }
  },
  mounted() {
    this.getActiveAvatarSrc()
  },
  //   asyncComputed: {
  //     avatarUrl() {
  //       // Allow prop to determine url instead of defaulting to logged in user
  //       if (this.url) {
  //         return this.url
  //       }
  //       let avatarLifetime = process.env.VUE_APP_AVATAR_IMAGE_MILLISECOND_LIFETIME
  //       // Get logged in user's active avatar image, if there is one
  //       return getUnexpiredSignedUrlObject('avatars', 'active', avatarLifetime).then(activeAvatar => {
  //         let thumbnail = activeAvatar?.thumbnail
  //           ? activeAvatar?.thumbnail.replace('/img/', '/images/')
  //           : undefined
  //         return thumbnail
  //       })
  //     },
  //   },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    userName() {
      return this.name ?? `${this.user?.givenName} ${this.user?.surname}`
    },
    userInitials() {
      if (
        this.user?.givenName &&
        this.user?.givenName.length &&
        this.user?.surname &&
        this.user?.surname.length
      ) {
        return `${this.user.givenName[0]}${this.user.surname[0]}`.toUpperCase()
      }
      return ''
    },
    activeAvatar() {
      return this.$store.getters['avatars/active']
    },
    hasAvatar() {
      return this.$store.getters['avatars/hasAvatar']
    },
  },
  methods: {
    getActiveAvatarSrc() {
      if (!this.hasAvatar || this.activeAvatar?.thumbnail === '/img/avatar-pending.jpg') {
        return
      } else {
        this.$store
          .dispatch('avatars/avatarStyle', {
            avatarId: this.activeAvatar.id,
            style: this.avatarStyle,
          })
          .then(response => {
            this.avatarSrc = response
          })
      }
    },
  },
  watch: {
    '$store.state.avatars.active.id': function () {
      this.getActiveAvatarSrc()
    },
    deep: true,
  },
}
</script>

<style>
.user-menu-avatar {
  background-color: #bcbcbc;
  transform: scale(1.7);
  object-position: 0 6px;
}
</style>
