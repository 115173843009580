export default {
  mesmeriseGlobalLtd: 'https://mesmeriseglobal.com',
  generalTerms: 'https://gatheringsvr.com/terms-of-use/',
  specialTerms: 'https://gatheringsvr.com/special-data-terms/',
  privacyPolicy: 'https://gatheringsvr.com/privacy-policy/',
  downloadFromOculus:
    'https://www.oculus.com/experiences/quest/3620763894706423/?ranking_trace=0_3620763894706423_QUESTSEARCH_f8720a3f-c752-4697-9d09-36163c4ab7b5',
  support: 'https://gatheringsvr.com/support/',
  setup: 'https://gatheringsvr.com/setup',
  setupFAQs: 'https://gatheringsvr.com/setup#faq',
  specialDataTerms: 'https://gatheringsvr.com/special-data-terms/',
}
