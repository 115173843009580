import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'
import blobToDataUrl from '@/utilities/blobToDataUrl'

const defaultState = () => ({
  files: [],
  active: null,
  all: [],
  createWizard: {
    show: false,
  },
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  hasAvatar(state) {
    return state.all.length > 0
  },
  active(state) {
    return state.active
  },
  all(state) {
    return state.all
  },
  showCreateWizard(state) {
    return state.createWizard.show
  },
}

const actions = {
  add({ commit }, { file, fileName }) {
    let data = new FormData()

    data.append('avatar', file, fileName)

    const endpoint = `users/avatars/create`
    const options = {
      data,
      method: 'POST',
      json: false,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
    }

    return api(endpoint, options).then(() => {
      commit('setAvatarFile', file)
    })
  },
  fetch({ commit }) {
    const endpoint = `users/avatars`

    return api(endpoint).then(response => {
      commit('setActiveAvatar', response.data.data.active)
      commit('setAllAvatars', response.data.data)
    })
  },
  select({ commit }, avatar) {
    const endpoint = `users/avatars/${avatar.id}/set-active`
    const options = { method: 'POST' }

    return api(endpoint, options).then(() => {
      commit('changeActiveAvatar', avatar.id)
    })
  },
  destroy({ commit }, avatar) {
    const endpoint = `users/avatars/${avatar.id}`
    const options = { method: 'DELETE' }

    return api(endpoint, options).then(() => {
      commit('destroyAvatar', avatar.id)
    })
  },
  toggleCreateWizard({ commit }, boolean) {
    commit('toggleCreateWizard', boolean)
  },
  async avatarStyle({ rootState }, { avatarId, style }) {
    const endpoint = `users/${rootState.auth.user.id}/avatars/${avatarId}/${style}.png`
    try {
      const { data } = await api(
        endpoint,
        { headers: { Accept: 'image/png' }, json: false },
        { responseType: 'blob' }
      )
      return await blobToDataUrl(data)
    } catch (e) {
      return
    }
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  setAvatarFile(state, file) {
    state.files.push(file)
  },
  setActiveAvatar(state, avatar) {
    state.active = { ...avatar, retrieved_at: +new Date() }
  },
  setAllAvatars(state, avatars) {
    let now = +new Date()
    state.all = avatars.items.map(avatar => ({ ...avatar, retrieved_at: now }))
    state.active = { ...avatars.active, retrieved_at: +new Date() }
  },
  changeActiveAvatar(state, avatarId) {
    state.all = state.all.map(avatar => {
      if (avatar.id === avatarId) {
        return { ...avatar, active: true }
      }
      return { ...avatar, active: false }
    })

    let avatar = state.all.find(avatar => avatar.id === avatarId)

    state.active = { ...avatar, retrieved_at: +new Date() }
  },
  destroyAvatar(state, avatarId) {
    state.all = state.all.filter(avatar => avatar.id !== avatarId)
  },
  toggleCreateWizard(state, boolean) {
    state.createWizard.show = boolean
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
