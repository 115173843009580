<template>
  <v-app>
    <v-main>
      <c-errors></c-errors>
      <c-snackbar></c-snackbar>
      <c-page full-height>
        <div class="logoContainer">
          <c-logo></c-logo>
        </div>
        <p class="error--text">{{ $t('errors.sorrySomethingWentWrong') }}</p>
      </c-page>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.logoContainer {
  max-width: 300px;
  margin: auto;
}
</style>
