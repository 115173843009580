export default {
  avatars: {
    titles: {
      consent: 'Special data consent',
      add: 'Create an avatar',
      list: 'Avatars',
    },
    consent: {
      toCreateYourAvatarWeNeedYour: 'To create your avatar we need your',
      consentForSpecialData: 'consent for special data',
      iAgreeToThe: 'I agree to the',
      specialDataTerms: 'Special data terms',
      pleaseConfirmThatYouAgreeToTheSpecialDataTerms:
        'Please confirm that you agree to the special data terms',
      byTickingThisBox:
        'By ticking this box you explicitly consent to the use of you personal data to generate your avatar and for use in this service for further details about how your personal details may be processed please see our',
      privacyPolicy: 'Privacy policy',
      beforeWeBeginPleaseEnsureYouHaveReadAndAgreedWithTheFollowing:
        'Before we begin please ensure you have read and agreed with the following',
      iConfirmThatIAm_18YearsOldOrOverAndThatIHaveReadAndAgreeToGatherings:
        'I confirm that I am 18 years old or over and that I have read and agree to Gatherings’ ',
    },
    add: {
      letsMakeYour: 'Lets make your',
      avatar: 'avatar',
      yourAvatarWillBeWhatOtherPeople: 'Your avatar will be what other people',
      seeOnceYouReInGatherings: "see once you're in Gatherings",
      tips: {
        useANeutralBackdrop: 'Use a neutral backdrop',
        useBrightWhiteLighting: 'Use bright, white lighting',
        faceTheCameraStraightOn: 'Face the camera straight-on',
        keepANeutralFace: 'Keep a neutral face',
        removeYourGlasses: 'Remove your glasses',
        keepYourHairOutOfYourFace: 'Keep your hair out of your face',
      },
    },
    list: {
      selectedAvatar: 'Selected avatar',
      thisIsHowOtherPeople: 'This is how other people',
      willSeeYouInVr: 'will see you in VR',
      myAvatars: 'My Avatars',
      createAVarietyOfAvatarsDressedForEveryOccasion:
        'Create a variety of avatars, dressed for every occasion. ',
      selectWhichAvatarYouWantToUseOrCreateANewOneBelow:
        'Select which avatar you want to use or create a new one below.',
      itCanTakeUpTo_5MinutesToCreateAnAvatar: 'It can take up to 5 minutes to create an avatar.',
      checkForUpdates: 'Check for updates',
      areYouSureYouWantToDeleteThisAvatar: 'Are you sure you want to delete this avatar?',
    },
    input: {
      removeThisImage: 'Remove this image',
      fileTooLarge: 'This file is too large - the maximum size is 3MB',
      fileInvalidName:
        'It looks like the name of the file you selected contains spaces or special characters (e.g. % or $), please rename it without these and resubmit',
      fileInvalidExtension:
        "It seems like the kind of file you uploaded isn't one we accept - you can upload .jpg, .jpeg or .png",
      uploadAnImageFromYourComputer: 'Upload an image from your computer',
      takeAPictureUsingTheCameraOnYourDevice: 'Take a picture using the camera on your device',
      capture: 'Capture',
      yourCameraIsBlocked: 'Your camera is blocked',
      chooseSafariPreferencesWebsitesCamera:
        'Choose "Safari" > "Preferences" > "Websites" > "Camera"',
      thenFindThisWebsiteInTheListAndSelectAllow:
        'Then find this website in the list and select "Allow"',
      finallyRefreshThePageYouMayAlsoNeedToRestartSafari:
        'Finally, refresh the page - you may also need to restart Safari',
      clickTheCameraBlockedIcon: 'Click the camera blocked icon',
      inYourBrowserSAddressBar: "in your browser's address bar and reload the page.",
    },
    wizard: {
      takePhotoWithCamera: 'Take photo with camera',
      selectPhoto: 'Select photo',
      selectAnotherPhoto: 'Select another photo',
      generateAvatar: 'Generate avatar',
      completionAction: 'View avatars',
      nextStep: 'Next step',
      takePhoto: 'Take photo',
    },
    onboarding: {
      createYourAvatar: 'Create your avatar',
      personaliseYourAppearanceInTheVrEnvironmentMeetYourVirtualSelf:
        'Personalise your appearance in the VR environment. Meet your virtual self!',
      uploadExistingPhoto: 'Upload existing photo',
    },
    fileSelect: {
      selectYourPhoto: 'Select your photo',
      uhOh: 'Uh oh!',
      pleaseEnsureThatThePhotoThatYouChooseMatchesTheRequirementsListedBelow:
        'Please ensure that the photo that you choose matches the requirements listed below.',
      weNeedAHigherResolutionPhotoIdeallyLargerThan_1_000pxX_1_000px:
        'We need a higher resolution photo (ideally larger than 1,000px x 1,000px).',
      theFileYouSelectedIsNotASupportedFormatPleaseSelectAJpgOrPng:
        'The file you selected is not a supported format. Please select a JPG or PNG.',
      example: 'example',
      colourPhotoNotBlackWhite: 'Colour photo (not black & white).',
      plainUnclutteredBackground: 'Plain, uncluttered background.',
      brightWhiteLighting: 'Bright, white lighting.',
      neutralFacialExpression: 'Neutral facial expression.',
      avoidAnythingCoveringYourFaceEGFaceMasksGlassesAndHair:
        'Avoid anything covering your face e.g. face masks, glasses and hair.',
    },
    adjustImage: {
      finalAdjustments: 'Final adjustments',
      positionFaceGuides: 'Position your face using the guides.',
      zoomAndDragYourPhotoToLineUpYourFaceWithTheGuidesBelow:
        'Drag and zoom your photo to line up your face with the guides below.',
      positionYourFaceUsingTheGuides: 'Position your face using the guides.',
      areYouSureYouWantToUseYourCameraInstead: 'Are you sure you want to use your camera instead?',
      areYouSureYouWantToRemoveThisPhotoAndSelectAnother:
        'Are you sure you want to remove this photo and select another?',
      areYouSureYouWantToTakeAnotherPhoto: 'Are you sure you want to take another photo?',
    },
    generating: {
      yourAvatarIsBeingGenerated: 'Your avatar is being generated...',
      thisWillTakeAround_5_10Minutes: 'This will take around 5-10 minutes.',
      youReGoingToLookIncredible: "You're going to look incredible.",
    },
    takePhoto1: {
      beautifulSurroundings: '- Beautiful surroundings',
      ensureYouHaveAPlainBackground: 'Ensure you have a plain background',
      andBrightLighting: 'and bright lighting.',
      selectAPlainUnclutteredBackgroundUseBrightWhiteLighting:
        'Select a plain, uncluttered background.',
      useBrightWhiteLighting: 'Use bright, white lighting.',
    },
    takePhoto2: {
      noDistractions: 'No distractions',
      avatarsWorkBestWhenYouKeepYourFace: 'Avatars work best when you keep your face',
      expressionlessAndClearOfAccessories: 'expressionless and clear of accessories',
      keepANeutralFacialExpression: 'Keep a neutral facial expression',
      removeAnythingCoveringYourFaceEGFaceMasksGlassesAndHair:
        'Remove anything covering your face e.g. face masks, glasses and hair',
    },
    takePhoto3: {
      primePosition: 'Prime position',
      positionYourselfToLineUpYour: 'Position yourself to line up your',
      eyesAndHeadBelow: 'eyes and head below',
      positionYourFaceUsingTheGuides: 'Position your face using the guides',
    },
    timer: {
      theFinalCountdown: 'The final countdown',
      okHereWeGoOnTheCountOfThree: 'OK, here we go! On the count of three...',
    },
    photoTaken: {
      howDoYouLook: 'How do you look?',
      ifYouReHappyWeLlUseThisImageToCreateYour_3D:
        "If you're happy, we'll use this image to create your 3D",
      avatarInTheVirtualSpace: 'avatar in the virtual space',
      startAgain: 'Start again',
    },
  },
}
