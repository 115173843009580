<template>
  <div>
    <div class="onboarding-header">
      <div class="wizard-title" data-cy="wizard-onboarding-title">
        {{ $t('avatars.onboarding.createYourAvatar') }}
      </div>
      <div class="intro-text">
        {{
          $t('avatars.onboarding.personaliseYourAppearanceInTheVrEnvironmentMeetYourVirtualSelf')
        }}
      </div>
    </div>

    <div class="wizard-central-content">
      <img src="/images/Avatar.png" alt="Create your avatar" class="create-avatar-image" />
    </div>

    <!-- <div class="carousel-container">
      <v-carousel :show-arrows="false" class="carousel">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
    </div> -->

    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center">
        <v-btn
          fab
          color="primary"
          class="wizard-btn"
          @click="takePhoto"
          depressed
          rounded
          data-cy="wizard-onboarding-take-photo"
        >
          <div>
            {{ $t('avatars.wizard.takePhotoWithCamera') }}
          </div>
        </v-btn>
      </div>

      <div class="mt-5 pretend-link" @click="chooseFile" data-cy="wizard-onboarding-select-file">
        {{ $t('avatars.onboarding.uploadExistingPhoto') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
        },
      ],
    }
  },
  methods: {
    takePhoto() {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-1' } })
    },
    chooseFile() {
      this.$router.push({ path: '/avatars', query: { step: 'file-select' } })
    },
  },
}
</script>
<style lang="scss" scoped>
// .carousel {
//   max-width: 100%;
//   max-height: 350px;
// }

// @media screen and (min-width: 500px) {
//   .carousel {
//     max-width: 500px;
//     margin: 0 auto;
//   }
// }

// @media screen and (min-width: 600px) and (min-height: 700px) {
//   .carousel {
//     max-height: 400px !important;
//   }
// }

// @media screen and (min-width: 1904px) {
//   .carousel {
//     max-width: 700px;
//     margin: 0 auto;
//   }
// }
.create-avatar-image {
  max-width: 320px;
  margin: auto;
}
</style>
