<template>
  <v-text-field class="maskInput" :name="name" v-model="local" v-mask="mask" v-bind="$props" />
</template>

<script>
export default {
  props: {
    autofocus: Boolean,
    color: String,
    placeholder: String,
    prefix: String,
    mask: String,
    name: String,
    value: [Number, String],
  },
  data: () => ({ local: null }),
  watch: {
    local() {
      if (this.local !== this.value) this.$emit('input', this.local)
    },
    value() {
      if (this.local !== this.value) this.local = this.value
    },
  },
}
</script>
