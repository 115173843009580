<template>
  <v-container v-if="sessionData !== undefined" fluid class="sessions-list-container">
    <!-- Title and timezone menu -->
    <div class="d-flex justify-start align-center session-card-column mb-4">
      <div class="session-title">{{ $t('sessions.titles.list') }}</div>
    </div>

    <!-- Action bar -->
    <div
      v-if="sessions.length > 0"
      class="d-flex justify-space-between align-center mb-3 mb-sm-0 session-card-column"
    >
      <c-session-search-input :sessions="sessions" v-on:showSession="showSession" />

      <!-- Timezone and toggle -->
      <div class="d-flex">
        <c-session-time-zone-select
          v-if="sessions.length > 0 && $vuetify.breakpoint.smAndUp"
          class="mr-sm-6"
          v-on:change="changeTimeZone"
        />

        <!-- Toggle -->
        <div class="d-flex align-center justify-center session-view-toggle clickable">
          <div
            class="pa-2 d-flex justify-center align-center toggle toggle-right"
            :class="!showCalendar ? 'toggled-on' : 'toggled-off'"
            @click="toggleOn('cards')"
          >
            <v-icon size="20" :color="showCalendar ? 'primary' : 'white'" :dark="!showCalendar"
              >mdi-view-grid</v-icon
            >
          </div>
          <div
            class="pa-2 d-flex justify-center align-center toggle toggle-left"
            :class="showCalendar ? 'toggled-on' : 'toggled-off'"
            @click="toggleOn('calendar')"
          >
            <v-icon size="20" :color="!showCalendar ? 'primary' : 'white'" :dark="showCalendar"
              >mdi-calendar-blank</v-icon
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Sessions cards/calendar -->
    <div v-if="sessions.length > 0" class="session-card-column">
      <!-- Have to use class binding to show or hide this because v-show interferes with the scrollToTime -->
      <c-session-calendar
        :class="showCalendar ? 'd-block' : 'd-none'"
        :sessions="sessions"
        :onShow="showCalendar"
        :timeZone="timeZone"
        v-on:changeTimeZone="changeTimeZone"
        v-on:showSession="showSession"
      />

      <div class="flex-wrap" :class="showCalendar ? 'd-none' : 'd-flex'">
        <c-session-card
          v-for="session in sessions"
          :key="session.id"
          :session="session"
          :timeZone="timeZone"
          v-on:showSession="showSession"
          v-on:remindMe="remindMe"
        />
      </div>
    </div>

    <!-- No sessions to show message -->
    <div v-if="sessions.length === 0" class="union-shape mb-10 d-flex justify-center">
      <c-session-union />
    </div>

    <v-dialog v-model="showDialog" :width="sessionDialogWidth" :fullscreen="$vuetify.breakpoint.xs">
      <c-session-dialog-details
        v-if="dialogContent === 'details'"
        :session="dialogSession"
        :timeZone="timeZone"
        v-on:close="showDialog = false"
        v-on:next="dialogContent = 'remindMe'"
      />

      <c-session-dialog-remind-me
        v-if="dialogContent === 'remindMe'"
        :session="dialogSession"
        v-on:back="dialogContent = 'details'"
        v-on:close="
          showDialog = false
          dialogContent = 'details'
        "
      />
    </v-dialog>
  </v-container>

  <v-container v-else class="d-flex justify-center align-center mt-12">
    <c-loading-spinner />
  </v-container>
</template>
<script>
import axios from 'axios'
import { DateTime } from 'luxon'
import SessionMixin from '@/components/sessions/SessionMixin'
export default {
  mixins: [SessionMixin],
  data() {
    return {
      sessionData: undefined,
      timeZone: 'Session', // or "Local"
      showCalendar: false,
      showDialog: false,
      dialogContent: 'details',
      dialogSession: undefined,
    }
  },
  computed: {
    sessions() {
      let sessions = []

      if (this.sessionData === undefined) {
        return sessions
      }

      if (Object.keys(this.sessionData).length === 0) {
        return sessions
      }

      // if user hasn't been invited to at least one morning star event, don't show them any morning star sessions...
      let gatheringsFilter = process.env.VUE_APP_MORNINGSTAR_GATHERING_IDS

      if (gatheringsFilter) {
        let morningstarSpaceIdArray = gatheringsFilter.split(',')
        let userGatheringInviteIds = this.$store.getters['auth/user'].invites?.map(i => i.id) ?? []

        let hasMorningStarGatheringInvite = morningstarSpaceIdArray.some(
          r => userGatheringInviteIds.indexOf(r) >= 0
        )

        if (!hasMorningStarGatheringInvite) {
          return sessions
        }
      }

      let days = this.sessionData.days

      let inUserTimeZone = this.timeZone === 'Local' ? true : false

      let dateFormat = 'yyyy/M/dd HH:mm z'

      let oneHour = 1000 * 60 * 60
      let now = +new Date() // establish the utc time 1 hr from now, so we show events for 1hr after they end

      days.forEach(day => {
        day.entries.forEach(session => {
          let eventTimeZone = this.abbreviationToIANA(session.timeZone) // e.g. CDT meaning central daylight savings time in Chicago
          let endTimeStamp = DateTime.fromFormat(
            session.endTime + ' ' + eventTimeZone,
            dateFormat
          ).toMillis()

          let showUntil = endTimeStamp + oneHour

          // If the (universal) end time of the event plus one hour
          // is still after the (universal) current time then display the event
          if (showUntil > now) {
            sessions = sessions.concat(session)
          }
        })
      })

      sessions = sessions.map(session => {
        let [start, end] = this.getSessionStartAndEnd(session, inUserTimeZone)

        let durationInMinutes = end.diff(start, ['minutes']).toObject().minutes
        let lineLength = this.$vuetify.breakpoint.smAndUp ? 20 : 40
        let allowedLines = durationInMinutes <= 30 ? 1 : durationInMinutes > 60 ? 3 : 2
        let maxTitleLength = lineLength * allowedLines

        // YYYY-MM-DD hh:mm
        session.start = start.toFormat('yyyy-MM-dd HH:mm')
        session.end = end.toFormat('yyyy-MM-dd HH:mm')
        session.timeZoneAbbreviation = this.sessionTimeZoneAbbreviation(inUserTimeZone)

        session.name =
          session.header.length > maxTitleLength
            ? session.header.substring(0, maxTitleLength - 3) + '...'
            : session.header

        return session
      })

      return sessions
    },
    userTimeZone() {
      return this.$store.getters['auth/user'].userTimeZone ?? 'America/Chicago'
    },
    sessionTimeZone() {
      return this.abbreviationToIANA(event)
    },
    sessionDialogWidth() {
      return this.$vuetify.breakpoint.xs ? '100%' : '600px'
    },
  },
  methods: {
    changeTimeZone(timeZone) {
      this.timeZone = timeZone
    },
    toggleOn(type) {
      if (type === 'calendar') {
        this.$router.push({ path: '/sessions', query: { show: 'calendar' } })
        this.showCalendar = true
      } else {
        this.$router.push({ path: '/sessions', query: { show: 'cards' } })
        this.showCalendar = false
      }
    },
    getSessionStartAndEnd(session, inUserTimeZone = false) {
      let start = DateTime.fromFormat(session.startTime, 'yyyy/M/d H:mm', {
        zone: this.sessionTimeZone,
      })
      let end = DateTime.fromFormat(session.endTime, 'yyyy/M/d H:mm', {
        zone: this.sessionTimeZone,
      })

      if (inUserTimeZone) {
        start = start.setZone(this.userTimeZone)
        end = end.setZone(this.userTimeZone)
      }

      return [start, end]
    },
    showSession(selectedSession) {
      this.dialogSession = selectedSession
      this.showDialog = true
    },
    remindMe() {
      this.dialogContent = 'remindMe'
      this.showDialog = true
    },
  },
  created() {
    let blobUrl = process.env.VUE_APP_MORNINGSTAR_EVENTS_DATA_FILE
    axios(blobUrl)
      .then(response => {
        this.sessionData = response.data
      })
      .catch(error => {
        this.$store.dispatch('ui/launchSnackbar', {
          color: 'error',
          message: this.$t('sessions.list.sorrySomethingWentWrongFetchingTheSessionData'),
          buttonColor: 'white',
        })
        /* eslint-disable no-console */
        console.error(error)
        return error
      })
  },
  mounted() {
    if (this.$route.query.show && this.$route.query.show === 'calendar') {
      this.showCalendar = true
    }
  },
}
</script>
<style lang="scss" scoped>
.session-title {
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 41px;
  color: #363332;
}

// Top container
.sessions-list-container {
  align-self: start;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 30px;
}

@media screen and (min-width: 600px) {
  .sessions-list-container {
    margin-top: 60px;
  }
}

@media screen and (min-width: 960px) {
  .sessions-list-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

// Next container
.session-card-column {
  margin: auto;
}

@media screen and (min-width: 600px) {
  .session-card-column {
    margin-top: 30px;
    max-width: 600px;
  }
}

@media screen and (min-width: 1000px) {
  .session-card-column {
    max-width: 900px;
  }
}

@media screen and (min-width: 1200px) {
  .session-card-column {
    max-width: 1200px;
  }
}

.session-view-toggle {
  box-shadow: 0px 8px 20px rgba(85, 80, 100, 0.1);
  width: 88px;
  height: 45px;
  margin-top: 1px;
}

.toggle {
  width: 50%;
  height: 100%;
}

.toggle-right {
  border-radius: 6px 0 0 6px;
}

.toggle-left {
  border-radius: 0 6px 6px 0;
}

.toggled-on {
  background-color: #807ef0;
}

.toggled-off {
  background-color: white;
}

::v-deep .v-calendar .v-session-timed {
  white-space: normal;
  padding: 5px;
  background-color: #f2f2fd !important;
  width: 100%;
  color: black !important;
  border: none !important;
  border-left: 5px solid #807ef0 !important;
}
</style>
