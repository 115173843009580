<template>
  <div class="shape">
    <svg class="svg" viewBox="0 0 360 336" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M243.997 255.501C221.907 255.778 204 273.911 204 296.002C204 318.094 221.907 335.778 243.997 335.501C266.087 335.224 283.994 317.092 283.994 295C283.994 272.909 266.087 255.224 243.997 255.501ZM330.131 173.119C336.986 170.67 344.345 169.294 352.005 169.199C389.005 168.736 419 198.314 419 235.262C419 272.211 389.005 302.539 352.005 303.002C323.957 303.353 299.935 286.442 289.952 262.144C288.664 259.948 287.356 257.257 286.03 254.025C276.908 231.803 252.778 233.766 230.25 240.134C212.91 249.166 193.281 254.381 172.49 254.641C102.079 255.522 45 199.237 45 128.924C45 58.6118 102.079 0.898428 172.49 0.0177764C242.901 -0.862876 299.98 55.4226 299.98 125.735C299.98 133.007 299.369 140.145 298.197 147.099C295.498 189.221 307.998 183.166 320.97 176.883C324.069 175.382 327.195 173.868 330.147 172.995L330.131 173.119ZM0.00375924 44.6263C0.00376016 30.8191 11.1958 19.4861 25.0018 19.3131C38.8078 19.1402 49.9998 30.1929 49.9998 44C49.9998 57.8071 38.8078 69.1402 25.0018 69.3131C11.1958 69.4861 0.00376042 58.4334 0.00375924 44.6263Z"
        fill="#FFF5F5"
      />
    </svg>
    <v-icon dark color="#F25C5C" class="warning-icon"> mdi-alert </v-icon>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.warning-icon {
  position: absolute;
  left: calc(50% - 50px);
  bottom: 50%;
  font-size: 100px;
}

.shape {
  position: relative;
  width: 100vw;
  height: calc(100vw - 33%);
}
.svg {
  width: 100vw;
  height: calc(100vw - 33%);
}

@media screen and (min-width: 600px) {
  .shape {
    width: 570px;
    height: 380px;
  }
  .svg {
    margin: auto 0;
    width: 570px;
    height: 380px;
  }
}
</style>
