<template>
  <div>
    <!-- Bulb icon -->
    <div>
      <v-icon data-cy="lightbulb-icon" x-large class="primary--text">
        mdi-lightbulb-on-outline
      </v-icon>
      <h1 class="primary--text">{{ $t('onboarding.tips.tips') }}</h1>
    </div>
    <!-- Tips -->
    <div class="cardText tips-container">
      <div class="tip">
        <strong>
          <h2 class="mb-2">
            {{ $t('onboarding.tips.keepSpacesLimitedTo_30Guests.title') }}
          </h2>
          <p class="mb-2 grey--text text--darken-1">
            {{ $t('onboarding.tips.keepSpacesLimitedTo_30Guests.detail') }}
          </p>
        </strong>
      </div>
    </div>

    <p class="pt-2">
      {{ $t('onboarding.tips.forMoreHelpAndTipsVisit') }}<br />
      <a target="_blank" :href="$links.support">gatheringsvr.com/support</a>
    </p>

    <!-- Next button -->
    <v-btn fab color="secondary" data-cy="next-button" class="mt-8" @click="next"
      ><v-icon>{{ 'chevron_right' }}</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      this.$router.push('/')
    },
  },
}
</script>
<style scoped>
.cardText {
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
.tips-container {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}
.tip {
  width: 60%;
}
</style>
