import account from '@/i18n/en/account'
import auth from '@/i18n/en/auth'
import avatars from '@/i18n/en/avatars'
import common from '@/i18n/en/common'
import events from '@/i18n/en/events'
import currencies from '@/i18n/en/currencies'
import dashboard from '@/i18n/en/dashboard'
import devices from '@/i18n/en/devices'
import docs from '@/i18n/en/docs'
import errors from '@/i18n/en/errors'
import sessions from '@/i18n/en/sessions'
import fields from '@/i18n/en/fields'
import onboarding from '@/i18n/en/onboarding'
import plurals from '@/i18n/en/plurals'
import ui from '@/i18n/en/ui'
import validations from '@/i18n/en/validations'
import files from '@/i18n/en/files'

export default {
  ...account,
  ...auth,
  ...avatars,
  ...common,
  ...events,
  ...currencies,
  ...dashboard,
  ...devices,
  ...docs,
  ...errors,
  ...sessions,
  ...fields,
  ...onboarding,
  ...plurals,
  ...ui,
  ...validations,
  ...files,
}
