<template>
  <div v-if="deviceCode">
    <!-- Page header -->
    <div class="cardHeader">
      <strong>
        <h2 class="mb-2 primary--text">{{ $t('devices.start.letsPairYourDevice') }}</h2>
        <p class="mb-2 grey--text text--darken-1">
          {{ $t('devices.start.putOnYourVrHeadsetAndFollowTheStepsBelow') }}
        </p>
      </strong>
    </div>

    <!-- Device code -->
    <div>
      <ol class="font-weight-medium numbered-list py-3 device-bullet-text">
        <li>{{ $t('devices.start.inYourHeadsetOpenGatheringsVr') }}</li>
        <li>{{ $t('devices.start.enterTheCodeBelowThenClickGo') }}</li>
      </ol>
      <!-- Device code -->
      <div class="device-code">
        <div data-cy="device-code">{{ deviceCode }}</div>
      </div>
    </div>

    <!-- Request another device code -->
    <div class="pt-5">
      <small>
        <p class="mb-0 grey--text text--darken-2">
          {{ $t('devices.start.thisCodeIsValidFor_15Minutes') }}
        </p>
        <a @click="getNewCode" class="primary--text noUnderline" data-cy="get-another-code">{{
          $t('devices.start.getAnotherCode')
        }}</a>
      </small>
    </div>

    <!-- Done button -->
    <v-btn fab color="secondary" class="mt-8" @click="done" data-cy="done-button">
      <div class="device-done-button-text">{{ $t('common.done') }}</div>
    </v-btn>

    <!-- Cancel -->
    <div class="skipButtonContainer pt-4">
      <a class="primary--text noUnderline" @click="skip">
        {{ $t('common.skipForNow') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  async created() {
    this.getNewCode()
  },
  data: () => ({
    deviceCode: '',
  }),
  methods: {
    skip() {
      this.$router.push({ path: '/tips' })
    },
    done() {
      this.$router.push({ path: '/tips' })
    },
    getNewCode() {
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('devices/getPairingCode').then(
        () => {
          this.deviceCode = this.$store.getters['devices/pairingCode']
          this.$store.dispatch('ui/toggleLoading', { show: false })
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
  },
}
</script>
<style scoped>
.cardHeader {
  line-height: 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
</style>
