<template>
  <v-form class="cForm" @submit.prevent="submitForm" ref="form">
    <template v-for="field in fields">
      <component
        @keyup.enter="onEnter(field)"
        :key="'field' + field.id"
        :id="'field' + '-' + field.id + '-' + field.name"
        :is="getField(field)"
        :class="field.class"
        :color="field.color"
        :elevation="field.elevation"
        :items="field.items"
        :label="field.label"
        :outlined="field.outlined"
        :placeholder="field.placeholder"
        :rounded="field.rounded"
        :type="getType(field)"
        v-model="values[field.name]"
        :rules="getRules(field)"
        :autofocus="field.autofocus"
        :flat="field.flat"
        :solo="field.solo"
        :maxlength="field.maxlength"
        :data-cy="field.dataCy || field.name"
      ></component>
    </template>
    <slot name="card-form-footer"></slot>
    <v-btn
      fab
      color="secondary"
      class="mt-8"
      @click="submitForm"
      :disabled="$store.getters['ui/loading']"
      type="submit"
      data-cy="formSubmitButton"
    >
      <v-icon>
        {{ submitButtonIcon || defaults.SUBMIT_BUTTON_ICON }}
      </v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import { FORM } from '@/utilities/constants'
import { hasProp } from '@/utilities/functions'

const { FIELD, SUBMIT_BUTTON_ICON } = FORM
const { COMPONENT, RULES, TYPE } = FIELD

export default {
  props: { fields: Array, submitButtonIcon: String },
  data: () => ({ antiForgeryToken: '', invalid: false, rulesActive: false, values: {} }),
  computed: {
    defaults() {
      return { COMPONENT, RULES, SUBMIT_BUTTON_ICON, TYPE }
    },
  },
  created() {
    this.setInitialValues()
  },
  methods: {
    getField(field) {
      if (hasProp(field, 'component')) return field.component
      else return this.defaults.COMPONENT
    },
    getRules(field) {
      if (!this.rulesActive) return []
      else if (hasProp(field, 'rules')) return field.rules
      else return this.defaults.RULES
    },
    getType(field) {
      if (hasProp(field, 'type')) return field.type
      else return this.defaults.TYPE
    },
    onEnter(field) {
      if (field.submitOnEnter || this.getField(field) === 'v-text-field') {
        this.submitForm()
      }
    },
    resetValues() {
      this.fields.forEach(f => {
        this.values[f.name] = hasProp(f, 'value') ? f.value : ''
      })
    },
    setInitialValues() {
      this.resetValues()
    },
    submit() {
      this.$emit('submit', this.values)
    },
    submitForm() {
      this.rulesActive = true
      this.$nextTick(() => {
        const valid = this.$refs.form.validate()
        if (valid) this.submit()
      })
    },
  },
}
</script>
