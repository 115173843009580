import cloneDeep from 'lodash/cloneDeep'

const config = cloneDeep(process.env) || {}

Object.keys(config).forEach(k => {
  const vueAppIndex = k.indexOf('VUE_APP_')
  if (vueAppIndex !== -1) config[k.slice(vueAppIndex + 8)] = config[k]
})

export { config }

export default {
  install(Vue) {
    Vue.$env = cloneDeep(config)
    Vue.prototype.$env = cloneDeep(config)
  },
}
