export default {
  dashboard: {
    downloadNowFromOculus: 'Download now from Oculus',
    connectToYourHeadset: 'Connect to your headset',
    avatars: 'Avatars',
    getYourBestFaceOn:
      'Get your best face on. Create and personalise your avatar to represent you in Gatherings VR',
    devices: 'Devices',
    pairAHeadset:
      'Pair a headset with your account to sign in and start experiencing Gatherings VR straight away',
    hi: 'Hi,',
    accountEdit: 'Account',
    accountEditBlurb:
      'Manage or update your personal details - change your name, job title or company',
    support: 'Support',
    checkOutOur: 'Check out our',
    setupInstructions: 'setup instructions',
    andView: 'and view',
    answersToFrequentlyAskedQuestions: 'answers to frequently asked questions',
    events: 'Sessions',
    donTMissABeatBrowseUpcomingEventsAndSetupReminders:
      'Don’t miss a beat. Browse upcoming sessions and set up reminders.',
    fileList: 'Files',
    fileListBlurb: 'Add, Update or Delete your files',
  },
}
