<template>
  <div class="session-container">
    <c-session-card
      :session="session"
      :timeZone="timeZone"
      v-on:showModal="showModal = true"
      v-on:remindMe="
        show = 'remindMe'
        showModal = true
      "
      class="clickable"
    />

    <v-dialog v-model="showModal" :width="sessionDialogWidth" :fullscreen="$vuetify.breakpoint.xs">
      <c-session-dialog-details
        v-if="show === 'details'"
        :session="session"
        :timeZone="timeZone"
        v-on:close="showModal = false"
        v-on:next="show = 'remindMe'"
      />

      <c-session-dialog-remind-me
        v-if="show === 'remindMe'"
        :session="session"
        v-on:back="show = 'details'"
        v-on:close="
          showModal = false
          show = 'details'
        "
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['session', 'timeZone'],
  data() {
    return {
      showModal: false,
      show: 'details',
    }
  },
  computed: {
    sessionDialogWidth() {
      return this.$vuetify.breakpoint.xs ? '100%' : '600px'
    },
  },
}
</script>
<style lang="scss" scoped>
.session-container {
  width: 100%;
  margin-bottom: 24px;
}
@media screen and (min-width: 600px) {
  .session-container {
    width: auto;
    margin: 24px 10px 12px 0;
  }
}
@media screen and (min-width: 1260px) {
  .session-container {
    width: auto;
    margin: 24px 24px 12px 0;
  }
}
</style>
