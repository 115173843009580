<template>
  <div class="split-content-stretch">
    <!-- Rules list -->
    <div class="d-flex justify-space-between countdown">
      <div :class="{ purpleNumber: this.second == 2 }">3</div>
      <div :class="{ purpleNumber: this.second == 1 }">2</div>
      <div :class="{ purpleNumber: this.second == 0 }">1</div>
    </div>

    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center">
        <v-btn disabled fab class="wizard-btn" depressed rounded>
          <div>
            {{ $t('avatars.wizard.takePhoto') }}
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stopTimer: {
      type: Boolean,
    },
  },
  data() {
    return {
      second: 3,
      timer: undefined,
    }
  },
  methods: {
    count() {
      if (this.second == 3) {
        this.$store.dispatch('sounds/play', 'countdown')
      }
      if (this.second < 1) {
        this.$emit('capture')
        this.$store.dispatch('sounds/play', 'camera')
        clearInterval(this.timer)
        this.next()
      }
      this.second--
    },

    next() {
      this.$router.push({ path: '/avatars', query: { step: 'photo-taken' } })
    },
  },
  mounted() {
    this.timer = setInterval(this.count, 1000)
  },
  watch: {
    stopTimer: {
      immediate: true,
      handler(newValue) {
        if (newValue == true) {
          clearInterval(this.timer)
          this.$store.dispatch('sounds/stop', 'countdown')
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.purpleNumber {
  color: #5151ce;
}

.countdown {
  width: 175px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #d3d2fa;
  margin: auto;

  div {
    font-weight: 700;
  }
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #e6e5fc !important;
}
</style>
