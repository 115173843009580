<template>
  <v-menu
    bottom
    :close-on-content-click="false"
    :nudge-bottom="55"
    :nudge-left="10"
    class="user-menu"
    data-cy="user-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="d-flex" data-cy="user-menu-title">
        <c-user-avatar-circle size="48" avatarStyle="previewIconHeadonlyT" />
        <div>
          <div class="user-name">{{ user.givenName }} {{ user.surname }}</div>
          <div class="d-flex">
            <div class="user-email">{{ user.email }}</div>
            <v-icon>mdi-menu-down</v-icon>
          </div>
        </div>
      </div>
    </template>

    <v-list class="user-menu">
      <v-list-item @click="$router.push('/account/edit')">
        <v-list-item-title class="user-menu-item"> My account </v-list-item-title>
      </v-list-item>
      <v-list-item class="py-1 d-flex align-center logout clickable">
        <v-icon size="16" color="#1A1E38" class="mr-2">fa-sign-out-alt</v-icon>
        <v-list-item-title class="user-menu-item" data-cy="log-out-desktop" @click="logOut"
          >Log out</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    userInitials() {
      if (
        this.user?.givenName &&
        this.user?.givenName.length &&
        this.user?.surname &&
        this.user?.surname.length
      ) {
        return `${this.user.givenName[0]}${this.user.surname[0]}`.toUpperCase()
      }
      return ''
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="scss" scoped>
.user-menu .v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  padding-top: 0;
  padding-left: 0.5rem;
}
.user-name {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #363333;
}
.user-email {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #363333;
  margin-top: 3px;
}

.v-menu__content {
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border: none;
}
::v-deep .v-list-item:hover {
  background: #f3efed;
}
.user-menu {
  padding-bottom: 0px;
}
.logout {
  padding-left: 2rem;
  border-top: 1px solid #dfe2e6;
}
.user-menu-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #1a1e38;
}
</style>
