<template>
  <v-progress-circular
    :color="color"
    :loading="loading"
    :indeterminate="indeterminate"
    :size="size"
  ></v-progress-circular>
</template>

<script>
export default {
  props: {
    color: { default: 'primary', type: String },
    indeterminate: { default: true, type: Boolean },
    loading: { default: true, type: Boolean },
    size: { default: 60, type: [Number, String] },
  },
}
</script>
