import { render, staticRenderFns } from "./SmallAvatar.vue?vue&type=template&id=36a33c7e&scoped=true&"
import script from "./SmallAvatar.vue?vue&type=script&lang=js&"
export * from "./SmallAvatar.vue?vue&type=script&lang=js&"
import style0 from "./SmallAvatar.vue?vue&type=style&index=0&id=36a33c7e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a33c7e",
  null
  
)

export default component.exports