export default {
  common: {
    about: 'About',
    add: 'Add',
    agreed: 'Agreed',
    accept: 'Accept',
    all: 'All',
    back: 'Back',
    backToTop: 'Back to top',
    cancel: 'Cancel',
    close: 'Close',
    comment: 'Comment | Comments',
    confirm: 'Confirm',
    create: 'Create',
    created: 'Created',
    createdAt: 'Created at',
    date: 'Date',
    delete: 'Delete',
    done: 'Done',
    dateRange: 'Date Range',
    description: 'Description',
    disputed: 'Disputed',
    download: 'Download',
    edit: 'Edit',
    edited: 'Edited',
    email: 'Email',
    enabled: 'enabled',
    error: 'Error',
    example: 'Example',
    expired: 'Expired',
    failed: 'Failed',
    file: 'File',
    filter: 'Filter',
    filters: 'Filters',
    for: 'For',
    full: 'Full',
    half: 'Half',
    home: 'Home',
    id: 'Id',
    jobNo: 'Job No',
    loading: 'Loading',
    name: 'Name',
    no: 'No',
    none: 'None',
    notes: 'Notes',
    null: 'Null',
    ok: 'OK',
    open: 'Open',
    password: 'Password',
    price: 'Price',
    privacyPolicy: 'Privacy Policy',
    processed: 'Processed',
    protection: 'Protection',
    quantity: 'Quantity',
    raw: 'Raw',
    remove: 'Remove',
    reset: 'Reset',
    result: 'Result | Results',
    search: 'Search',
    settings: 'Settings',
    skipForNow: 'Skip for now',
    sortBy: 'Sort by',
    status: 'Status',
    submit: 'Submit',
    supplier: 'Supplier | Suppliers',
    surcharge: 'Surcharge',
    time: 'Time',
    total: 'Total',
    unknown: 'Unknown',
    updated: 'Updated',
    updatedAt: 'Updated at',
    upload: 'Upload',
    user: 'User',
    username: 'Username',
    users: 'Users',
    weight: 'Weight',
    yes: 'Yes',
  },
}
