export const setLayout = (routes, layoutName) => {
  return routes.map(route => {
    return {
      ...route,
      meta: {
        ...route.meta,
        layout: `${layoutName}-layout`,
      },
    }
  })
}
