import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'
import i18n from '@/plugins/i18n'

const defaultState = () => ({
  ecosystemFiles: {},
  ecosystemFileTypes: {},
  ecosystemFilesUploadedBy: {},
  loading: false,
  filesDownloading: [],
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  getForEcosystem: state => ecosystemId => {
    return state.ecosystemFiles[ecosystemId] ?? undefined
  },
  getForEcosystemFileTypes: state => ecosystemId => {
    return state.ecosystemFileTypes[ecosystemId] ?? undefined
  },
  getForEcosystemUploadedBy: state => ecosystemId => {
    return state.ecosystemFilesUploadedBy[ecosystemId] ?? undefined
  },
  fileLoading: state => {
    return state.loading
  },
  filesDownloading: state => {
    return state.filesDownloading
  },
}

const actions = {
  upload(context, { ecosystemId, files }) {
    let data = new FormData()

    for (let file of files) {
      data.append('files', file)
    }

    let options = {
      data,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
      json: false,
      urlAppSettingKey: 'VUE_APP_UPLOAD_API_ROOT_URL',
    }

    const endpoint = `ecosystem/${ecosystemId}/files`
    return api(endpoint, options)
  },

  fetchForEcosystem({ commit }, ecosystemId) {
    let options = { method: 'GET' }
    const endpoint = `ecosystem/${ecosystemId}/files`
    return api(endpoint, options).then(response => {
      commit('setEcosystemFiles', { files: response.data.data, ecosystemId })
      commit('setEcosystemFileTypes', { files: response.data.data, ecosystemId })
      commit('setEcosystemFilesUploadedBy', { files: response.data.data, ecosystemId })
    })
  },
  fetchFile({ commit }, { file, ecosystemId }) {
    const { id } = file
    const endpoint = `ecosystem/${ecosystemId}/files/${id}`

    const options = {
      responseType: 'blob',
      json: false,
    }

    commit('setLoading', true)

    return api(endpoint, { method: 'GET' }, options).finally(() => {
      commit('setLoading', false)
    })
  },
  async downloadFile({ commit }, { file, ecosystemId }) {
    commit('setDownloading', { file, downloading: true })
    const { id, name } = file
    const endpoint = `/ecosystem/${ecosystemId}/files/${id}`

    const options = {
      responseType: 'blob',
      json: false,
    }

    api(endpoint, { method: 'GET' }, options)
      .then(response => response.data)
      .then(blob => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = name
        document.body.appendChild(a)
        a.click()
        a.remove()
        commit('setDownloading', { file, downloading: false })
      })
      .catch(() => {
        commit('setDownloading', { file, downloading: false })
      })
  },
  remove({ commit }, { ecosystemId, files }) {
    let options = { method: 'DELETE', data: files.map(f => f.id) }
    const endpoint = `ecosystem/${ecosystemId}/files`
    return api(endpoint, options).finally(() => {
      commit('removeFiles', { ecosystemId, files })
    })
  },
  async update(context, { ecosystemId, file, payload }) {
    const { id } = file
    let data = [
      {
        fileId: id,
        fileName: payload,
      },
    ]

    const options = {
      data,
      method: 'PUT',
    }
    const endpoint = `/ecosystem/${ecosystemId}/files`
    return api(endpoint, options)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  setEcosystemFiles(state, { files, ecosystemId }) {
    state.ecosystemFiles[ecosystemId] = files
  },
  setEcosystemFileTypes(state, { files, ecosystemId }) {
    const fileTypes = []
    files.forEach(file => {
      if (!fileTypes.includes(file.contentType)) {
        fileTypes.push(file.contentType)
      }
    })
    state.ecosystemFileTypes[ecosystemId] = fileTypes
  },
  setEcosystemFilesUploadedBy(state, { files, ecosystemId }) {
    const people = []
    let noOwner = false
    files.forEach(file => {
      if (!file.owner) {
        noOwner = true
        return
      }
      let fullName = `${file.owner.givenName} ${file.owner.surname}`
      if (!people.includes(fullName)) {
        people.push(fullName)
      }
    })
    if (noOwner) {
      people.push(i18n.t('files.filter.nA'))
    }
    state.ecosystemFilesUploadedBy[ecosystemId] = people
  },
  removeFiles(state, { ecosystemId, files }) {
    state.ecosystemFiles[ecosystemId] = state.ecosystemFiles[ecosystemId].filter(
      esFile => !files.find(file => file.id === esFile.id)
    )
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setDownloading(state, { file, downloading }) {
    if (downloading) {
      state.filesDownloading.push(file)
    } else {
      const index = state.filesDownloading.indexOf(file)
      state.filesDownloading.splice(index, 1)
    }
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
