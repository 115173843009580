/**
 * Convert a string to a page title.
 *
 * @param {String} s string to be converted
 *
 * @returns {String} converted string
 */
export function pageTitle(s) {
  return `${s} - ${process.env.VUE_APP_NAME}`
}
