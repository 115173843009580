<template>
  <v-dialog
    :value="show"
    :fullscreen="$vuetify.breakpoint.height < 900 || $vuetify.breakpoint.width < 600"
    persistent
    data-cy="avatar-wizard"
    :width="1000"
    @click:outside="clickOutside"
    @keydown="onKeydown"
  >
    <c-loading-bar />
    <v-card class="wizard-height">
      <div class="px-4 pt-4">
        <div class="d-flex justify-space-between align-center">
          <v-icon
            v-if="!['onboarding', 'generating', 'photo-taken'].includes(currentStep)"
            class="wizard-icon"
            @click="back"
            :disabled="$store.getters['ui/loading']"
            data-cy="wizard-back"
            >mdi-chevron-left</v-icon
          >
          <div v-else></div>
          <v-icon
            class="wizard-icon"
            data-cy="wizard-close"
            @click="close"
            :disabled="$store.getters['ui/loading']"
            >mdi-close</v-icon
          >
        </div>

        <!-- Web cam components - need to split them so that we only ever have one web cam instance and keep it alive -->
        <div v-show="this.steps[this.currentStep].hasWebCam" class="wizard-container">
          <!-- Current component header -->
          <component v-if="this.steps[this.currentStep].hasWebCam" v-bind:is="headerComponent" />

          <!-- Web cam, either hidden and off or visible and on (with permission) -->
          <c-avatar-wizard-web-cam
            :show="
              this.steps[this.currentStep].hasWebCam &&
              this.$store.getters['avatars/showCreateWizard']
            "
            :capture="capture"
            v-on:image="handleFile"
          />

          <!-- Current component footer -->
          <component
            v-if="this.steps[this.currentStep].hasWebCam"
            v-bind:is="footerComponent"
            v-on:confirm="confirm"
            v-on:close="close"
            v-on:capture="captureImage"
            :decisionAnswer="confirmDialog.decision.answer"
            :decisionCallBack="confirmDialog.decision.callBack"
            :stopTimer="stopTimer"
          />
        </div>

        <!-- Non web cam components -->
        <component
          class="wizard-container"
          v-if="!this.steps[this.currentStep].hasWebCam"
          v-bind:is="currentComponent"
          v-on:confirm="confirm"
          v-on:close="close"
          v-on:image="handleFile"
          :file="file"
          :decisionAnswer="confirmDialog.decision.answer"
          :decisionCallBack="confirmDialog.decision.callBack"
        />
      </div>
    </v-card>
    <c-confirm-dialog
      :show="confirmDialog.show"
      :question="confirmDialog.question"
      :text="confirmDialog.text"
      v-on:decision="decide"
    />
  </v-dialog>
</template>

<script>
import { delay } from '@/utilities/functions'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: {
        file: undefined,
        fileName: '',
        url: '',
        dimensions: {
          width: 0,
          height: 0,
        },
      },
      steps: {
        onboarding: {
          previous: undefined,
          hasWebCam: false,
        },
        'file-select': {
          previous: 'onboarding',
          hasWebCam: false,
        },
        'adjust-image': {
          previous: 'file-select',
          hasWebCam: false,
        },
        'take-photo-1': {
          previous: 'onboarding',
          hasWebCam: true,
        },
        'take-photo-2': {
          previous: 'take-photo-1',
          hasWebCam: true,
        },
        'take-photo-3': {
          previous: 'take-photo-2',
          hasWebCam: true,
        },
        timer: {
          previous: 'take-photo-3',
          hasWebCam: true,
        },
        'photo-taken': {
          previous: 'timer',
          hasWebCam: false,
        },
        generating: {
          previous: undefined,
          hasWebCam: false,
        },
      },
      confirmDialog: {
        show: false,
        question: '',
        text: '',
        decision: {
          answer: undefined,
          callBack: undefined,
        },
      },
      capture: false,
      stopTimer: false,
    }
  },
  methods: {
    clickOutside() {
      this.close()
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    captureImage() {
      this.capture = true
    },
    async close() {
      if (this.currentStep === 'timer') {
        await this.cancelCountdown()
      }

      // If the user has uploaded a file or taken a picture, check they really want to close
      if (this.file['file'] && ['adjust-image', 'photo-taken'].includes(this.currentStep)) {
        this.confirmDialog.question = 'Are you sure you want to quit the avatar creation?'
        this.confirmDialog.text =
          "Your progress won't be saved. You will need to  start from the beginning next time."
        this.confirmDialog.decision.callBack = this.quit
        this.confirmDialog.show = true
        return
      }

      this.$emit('close')
    },
    async back() {
      if (this.currentStep === 'timer') {
        await this.cancelCountdown()
      }

      let previousStep = this.steps[this.currentStep].previous
      if (previousStep) {
        this.$router.push({ path: '/avatars', query: { step: previousStep } })
      } else {
        this.$router.push({ path: '/avatars' })
      }
    },
    async cancelCountdown() {
      // If user is navigating away mid countdown, turn the sound effects off
      // And wait for the setInterval to be cleared
      this.stopTimer = true
      await delay(1000)
      this.stopTimer = false
    },
    setVideoDimensions(e) {
      this.videoDimensions = e
    },
    handleFile(file) {
      this.file = file
      this.capture = false
    },
    confirm({ question, callBack }) {
      this.confirmDialog.question = question
      this.confirmDialog.decision.callBack = callBack
      this.confirmDialog.show = true
    },
    async decide(decision) {
      this.confirmDialog.decision.answer = decision
      this.confirmDialog.show = false

      if (decision === 'yes') {
        await Promise.resolve(this.confirmDialog.decision.callBack())
        this.confirmDialog.decision.answer = undefined
        this.confirmDialog.decision.callBack = undefined
        return
      }

      if (decision === 'no') {
        this.confirmDialog.question = ''
        this.confirmDialog.text = ''
        this.confirmDialog.decision = { answer: undefined, function: undefined }
      }
    },
    quit() {
      this.$emit('close')
    },
    removeImage() {
      this.resetFile()
      this.$router.push({ path: '/avatars', query: { step: 'file-select' } })
    },
    takePhoto() {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-1' } })
    },
    resetFile() {
      this.file = {
        file: undefined,
        fileName: '',
        dimensions: {
          width: 0,
          height: 0,
        },
      }
    },
  },
  computed: {
    currentStep() {
      return this.$route.query.step ?? 'onboarding'
    },
    currentComponent() {
      return `c-avatar-wizard-${this.currentStep}`
    },
    headerComponent() {
      return `c-avatar-wizard-${this.currentStep}-header`
    },
    footerComponent() {
      return `c-avatar-wizard-${this.currentStep}-footer`
    },
  },
  watch: {
    $route(to, from) {
      if (from.query.step === 'photo-taken') {
        this.resetFile()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.wizard-icon {
  font-size: 22px;
  color: #2d3142;
}
.wizard-height {
  height: 100%;
}

.wizard-height {
  height: 100%;

  .px-4 {
    height: calc(100% - 16px);
  }
}

@media screen and (min-width: 600px) and (min-height: 900px) {
  .wizard-height {
    height: 750px;
  }
}
</style>
