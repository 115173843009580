export default {
  ui: {
    appName: 'Supplier portal',
    changeProfilePicture: 'Change profile picture',
    cancel: 'Cancel',
    clear: 'Clear',
    clearFilters: 'Clear filters',
    clickToCopyToClipboard: 'Click to copy to clipboard',
    clickToSortByX: 'Click to sort by {x}',
    closeDrawer: 'Close drawer',
    currentlyUnavailable: 'Currently unavailable',
    dashboard: 'Dashboard',
    dragYourXHereToUploadIt: 'Drag your {x} here to upload it',
    export: 'Export',
    exportToCsv: 'Export to CSV',
    fileSizeTooBig: 'File size too big',
    footer: 'Footer',
    home: 'Home',
    itemsPerPage: 'Items per page',
    lastUpdated: 'Last updated',
    mostRecent: 'Most recent',
    navigation: 'Navigation',
    noFileSelected: 'No file selected',
    noResultsToShow: 'No results to show',
    noXProvided: 'No {x} provided',
    opensNewTab: 'Opens new tab',
    orClickToBrowse: 'Or click to browse',
    pill: 'Pill',
    pleaseSeletFromTheFollowing: 'Please selet from the following',
    pleaseSelectAnX: 'Please select an {x}',
    pleaseSelectAX: 'Please select a {x}',
    pleaseSelectNXOnly: 'Please select {n} {x} only',
    profilePicture: 'Profile picture',
    resultsPerPage: 'Results per page',
    relevance: 'Relevance',
    saveChanges: 'Save changes',
    seeAllFilterOptions: 'See all filter options',
    selected: 'Selected',
    selectX: 'Select {x}',
    selectADifferentX: 'Select a different {x}',
    sortBy: 'Sort by',
    theXHasBeenDeleted: 'The {x} has been deleted',
    titleAZ: 'Title A-Z',
    titleZA: 'Title Z-A',
    uploadStatus: 'Upload status',
    uploading: 'Uploading',
    uploadNewX: 'Upload new {x}',
    uploadX: 'Upload {x}',
    userProfile: 'User profile',
    version: 'version',
    xMissing: '{x} missing',
    cookies: {
      dialog: {
        weUseCookiesToImproveYourExperience: 'We use cookies to improve your experience.',
        clickAcceptToConsentToTheUseOfAllCookiesYouMayRemoveYourConsentAtAnyTime:
          'Click "Accept" to consent to the use of all cookies - you may remove your consent at any time',
        youHavePreviouslyAcceptedAllCookiesForThisSiteToChangeThisClickReject:
          'You have previously accepted all cookies for this site - to change this click reject',
      },
    },
  },
}
