import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'

const routes = [
  {
    component: () => import(/* webpackChunkName: "DevicesStart" */ '@/views/devices/Start.vue'),
    meta: {
      title: pageTitle(String(i18n.t('devices.titles.start'))),
      requires: requirements,
      layout: 'onboarding-layout',
    },
    name: 'Start',
    path: '/devices/start',
  },
  {
    component: () => import(/* webpackChunkName: "Start" */ '@/views/devices/Connect.vue'),
    meta: {
      title: pageTitle(String(i18n.t('devices.titles.connect'))),
      requires: requirements,
    },
    name: 'Connect',
    path: '/devices/connect',
  },
]

export default routes
