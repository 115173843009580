import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'

const routes = [
  // Note: 'layout' and 'title'  provided in the 'meta' object of a route are referenced in the
  // store action 'ui/onRouteChange' and are set there if available.
  {
    component: () => import(/* webpackChunkName: "AccountEdit" */ '@/views/account/Edit.vue'),
    meta: {
      title: pageTitle(String(i18n.t('account.titles.edit'))),
      requires: requirements,
    },
    name: 'AccountEdit',
    path: '/account/edit',
  },
]

export default routes
