<template>
  <div class="passcodePageContainer">
    <div class="cardHeader">
      <strong>
        <p class="mb-0 primary--text font-weight-bold" data-cy="weVeJustSentYouAnEmail">
          {{ $t('auth.passcode.weVeJustSentYouAnEmail') }}!
        </p>
        <p class="mb-2 blacken" data-cy="checkYourEmailAndEnterYourCodeBelow">
          {{ $t('auth.passcode.checkYourEmailAndEnterYourCodeBelow') }}
        </p>
      </strong>
    </div>
    <c-form :fields="fields" @submit="onSubmit" class="mt-2 pa-2">
      <template v-slot:card-form-footer>
        <div class="pt-5">
          <p class="error--text my-2" v-if="showPasscodeValidationError">
            <small>{{ $t('auth.passcode.pleaseProvideAValid_6DigitCode') }}</small>
          </p>
          <p class="error--text my-2" v-if="showPasscodeApiError">
            {{ $t('auth.passcode.passcodeInvalidOrMayHaveExpired') }}
          </p>
          <p class="mb-0 grey--text text--darken-2" data-cy="canTFindACode_checkYourJunkFolder">
            {{ $t('auth.passcode.canTFindACode') }}? {{ $t('auth.passcode.checkYourJunkFolder') }}
          </p>
          <router-link class="primary--text noUnderline" to="/login" data-cy="sendMeANewCode">{{
            $t('auth.passcode.sendMeANewCode')
          }}</router-link>
        </div>
      </template>
    </c-form>
  </div>
</template>

<script>
import { isRequired } from '@/utilities/validations'
import { addId } from '@/utilities/functions'

export default {
  data: () => {
    return {
      isSubmitting: false,
      showPasscodeValidationError: false,
      showPasscodeApiError: false,
    }
  },
  computed: {
    passcodeField() {
      return {
        class: 'mt-3 text-center passcode-input',
        color: 'grey darken-2',
        // label: title(this.$t('auth.passcode.enterYourPasscode')),
        rules: [isRequired],
        name: 'passcode',
        autofocus: true,
        flat: true,
        solo: true,
        maxlength: 6,
        dataCy: 'passcode',
      }
    },
    fields() {
      return addId([this.passcodeField], true)
    },
  },
  methods: {
    onSubmit(e) {
      this.showPasscodeApiError = false
      this.showPasscodeValidationError = false
      if (e.passcode?.length < 6) {
        this.showPasscodeValidationError = true
        return
      }

      if (this.isSubmitting) {
        return // To stop form submitting twice - unable to work out how otherwise to stop this
      }
      this.isSubmitting = true
      this.$store.dispatch('ui/toggleLoading', { show: true })
      this.$store.dispatch('auth/verifyPasscode', { passcode: e.passcode }).then(
        async () => {
          this.isSubmitting = false
          this.$store.dispatch('ui/toggleLoading', { show: false })
          if (!this.$store.getters['auth/authenticated']) {
            this.$router.push('/login')
            return
          }

          await this.$store.dispatch('timeZones/fetch')
          await this.$store.dispatch('avatars/fetch')

          if (!this.$store.getters['auth/userIsRegistered']) {
            try {
              await this.$store.dispatch('auth/registerIPAddress')
            } catch (error) {
              /* eslint-disable no-console */
              console.error(error)
            }
            this.$router.push('/register')
          } else {
            this.$router.push('/')
            await this.$store.dispatch('auth/registerIPAddress')
          }
        },
        error => {
          this.isSubmitting = false
          this.$store.dispatch('ui/toggleLoading', { show: false })
          this.showPasscodeApiError = true
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.cardHeader {
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}
</style>
