<template>
  <div id="root" v-cloak>
    <keep-alive>
      <component :is="layout">
        <router-view class="viewContainer" id="routerViewport"></router-view>
      </component>
    </keep-alive>
  </div>
</template>

<script>
import layouts from '@/layouts'
import { routeHasChanged } from '@/utilities/router'
import { getAppSetting } from './utilities/settings'

export default {
  components: { ...layouts },
  computed: {
    layout() {
      // check for api root env variable - if missing, disable app by loading disabled layout
      if (!getAppSetting('VUE_APP_API_ROOT_URL')) return 'disabled-layout'
      else return this.$store.getters['ui/layout'] || 'default-layout'
    },
  },
  methods: {
    checkSessionLength() {
      if (this.$store.getters['auth/loginTimestamp']) {
        const timestampLoggedIn = this.$store.getters['auth/loginTimestamp']
        const millisecondsInAMinute = 60000
        const sessionLength = process.env.VUE_APP_SESSION_LENGTH_MINUTES ?? 10080 // default to 7 days
        const millisecondSessionLength = sessionLength * millisecondsInAMinute
        const timestampToLogout = +timestampLoggedIn + +millisecondSessionLength
        const timestampNow = +new Date()

        if (timestampNow >= timestampToLogout) {
          this.logMeOut()
        }
      } else if (this.$store.getters['auth/authenticated']) {
        // If for some reason the user is authenticated
        // but has no login timestamp,
        // log them out
        this.logMeOut()
      }
    },
    logMeOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/login')
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'warning',
        message: this.$t(
          'auth.youVeBeenLoggedOutDueToSessionTimeoutSignBackInToContinueUsingGatherings'
        ),
        buttonColor: 'white',
        timeout: 10000,
      })
    },
  },
  watch: {
    $route(to, from) {
      if (routeHasChanged(to, from)) this.$store.dispatch('ui/onRouteChange', { from, to })
    },
  },
  created() {
    this.$store.dispatch('ui/onInitialLoad', {
      initialStateData: window.initialStateData || {},
    })

    this.checkSessionLength()
    const sessionCheckInterval = process.env.VUE_APP_SESSION_CHECK_INTERVAL_SECONDS ?? 60 // default to 1 minute
    setInterval(this.checkSessionLength, sessionCheckInterval * 1000)
  },
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
</style>
