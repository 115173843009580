<template>
  <v-container class="mt-md-12">
    <v-row class="align-self-center">
      <v-col>
        <v-card flat max-width="900px" class="marginAuto text-center mesmerise-background">
          <!-- Hi user and small buttons -->
          <v-row>
            <!-- Hi user -->
            <v-col :cols="12" :md="6">
              <div>
                <h1 class="text-left">
                  {{ $t('dashboard.hi') }}
                  <span class="primary--text">{{
                    this.$store.getters['auth/user'].givenName
                  }}</span>
                </h1>
              </div>
            </v-col>

            <!-- Small buttons -->
            <v-col :cols="12" :md="6">
              <v-row>
                <v-col :cols="12" :sm="8" :md="12" class="px-0">
                  <a
                    class="small-action"
                    :href="$links.downloadFromOculus"
                    target="_blank"
                    data-cy="downloadNowFromOculus"
                  >
                    <span>
                      <v-icon class="action-icon">{{ 'cloud_download' }}</v-icon>
                      {{ $t('dashboard.downloadNowFromOculus') }}
                    </span>
                  </a>
                </v-col>

                <v-col :cols="12" :sm="8" :md="12" class="px-0">
                  <router-link
                    class="noUnderline"
                    to="/devices/connect"
                    data-cy="connectToYourHeadset"
                  >
                    <div class="small-action">
                      <span>
                        <v-icon class="action-icon">mdi-google-cardboard</v-icon>
                        {{ $t('dashboard.connectToYourHeadset') }}
                      </span>
                    </div>
                  </router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Large buttons -->
          <v-row>
            <v-col cols="12" :md="6">
              <router-link class="noUnderline" to="/avatars">
                <div class="big-action" data-cy="avatars-button">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-icon class="action-icon">mdi-account</v-icon>
                      <div class="big-action-title">{{ $t('dashboard.avatars') }}</div>
                    </div>
                    <v-icon class="next-icon">
                      {{ 'chevron_right ' }}
                    </v-icon>
                  </div>
                  <div class="py-4 px-2">{{ $t('dashboard.getYourBestFaceOn') }}</div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" :md="6">
              <router-link class="noUnderline" to="/devices/connect">
                <div class="big-action" data-cy="devices-button">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-icon class="action-icon">mdi-face-agent</v-icon>
                      <div class="ml-4 big-action-title">{{ $t('dashboard.devices') }}</div>
                    </div>
                    <v-icon class="next-icon">
                      {{ 'chevron_right ' }}
                    </v-icon>
                  </div>
                  <div class="py-4 px-2">{{ $t('dashboard.pairAHeadset') }}</div>
                </div>
              </router-link>
            </v-col>

            <v-col cols="12" :md="6">
              <router-link class="noUnderline" to="/account/edit">
                <div class="big-action" data-cy="account-button">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-icon class="action-icon">mdi-account-edit</v-icon>
                      <div class="ml-4 big-action-title">{{ $t('dashboard.accountEdit') }}</div>
                    </div>
                    <v-icon class="next-icon">
                      {{ 'chevron_right ' }}
                    </v-icon>
                  </div>
                  <div class="py-4 px-2">{{ $t('dashboard.accountEditBlurb') }}</div>
                </div>
              </router-link>
            </v-col>

            <v-col v-if="showFiles" cols="12" :md="6">
              <router-link class="noUnderline" :to="`/ws/${ecosystemId}/files`">
                <div class="big-action" data-cy="files-button">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-icon class="action-icon">mdi-account-edit</v-icon>
                      <div class="ml-4 big-action-title">{{ $t('dashboard.fileList') }}</div>
                    </div>
                    <v-icon class="next-icon">
                      {{ 'chevron_right ' }}
                    </v-icon>
                  </div>
                  <div class="py-4 px-2">{{ $t('dashboard.fileListBlurb') }}</div>
                </div>
              </router-link>
            </v-col>

            <!-- <v-col cols="12" :md="6">
              <router-link class="noUnderline" to="/events">
                <div class="big-action" data-cy="events-button">
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-icon class="action-icon">mdi-calendar</v-icon>
                      <div class="ml-4 big-action-title">{{ $t('dashboard.events') }}</div>
                    </div>
                    <v-icon class="next-icon">
                      {{ 'chevron_right ' }}
                    </v-icon>
                  </div>
                  <div class="py-4 px-2">
                    {{ $t('dashboard.donTMissABeatBrowseUpcomingEventsAndSetupReminders') }}
                  </div>
                </div>
              </router-link>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    showFiles() {
      var ecosystems = this.$store.getters['ecosystems/myEcosystems']
      return !(ecosystems === null || ecosystems.length == 0)
    },
    ecosystemId() {
      var ecosystems = this.$store.getters['ecosystems/myEcosystems']
      var selectedEcosystemId = ecosystems[0].id
      for (var ecosystem of ecosystems) {
        if (ecosystem.id === '69568d6d-2409-4724-8cf1-adc5cf02ffe9') {
          selectedEcosystemId = ecosystem.id
          break
        }
      }
      return selectedEcosystemId
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('ecosystems/fetchMine', true)
  },
}
</script>
<style lang="scss" scoped>
.small-action {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  border: 1px solid transparent;
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
  border-radius: 50px;
  box-sizing: border-box;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  text-align: left;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  width: 100%;
}

.small-action:hover {
  background-color: #807ef0;
  color: white;
}

.small-action:hover .action-icon {
  background-color: #807ef0;
  color: white;
}

.big-action:hover {
  background-color: #807ef0;
  color: white;
}
.big-action:hover .setup-links a {
  color: white;
  text-decoration: underline;
}
.big-action:hover .action-icon {
  background-color: #807ef0;
  color: white;
}
.big-action:hover .next-icon {
  background-color: #807ef0;
  color: white;
}
.next-icon {
  transition: none;
}
.action-icon {
  margin-right: 12px;
  transition: none;
}

.big-action {
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
  border-radius: 20px;
  box-sizing: border-box;
  color: #212529;
  cursor: pointer;
  display: block;
  font-family: kiro, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 32px;
  text-align: left;
  text-decoration: none !important;
  user-select: none;
  min-height: 175px;
  height: 100%;
}

@media screen and (min-width: 350px) {
  .small-action {
    font-size: 1.25rem;
  }
}

.row {
  margin: 0px;
}
</style>
