import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'
import api from '@/utilities/api'
import { rawTimeZones } from '@vvo/tzdb'
const ipLocation = require('iplocation')
import axios from 'axios'
import router from '@/plugins/router'

const defaultState = () => ({
  authenticated: false,
  authType: null,
  deviceId: null,
  token: null,
  loginTimestamp: null,
  passcodeRequested: false,
  user: {
    email: null,
    givenName: null,
    surname: null,
    consents: {
      items: {},
    },
    userTimeZone: null,
  },
  ipDetails: {
    address: null,
    location: null,
  },
  onlyAdminInEcosystem: true,
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  userIsRegistered(state) {
    return !!(state.user.givenName?.length && state.user.surname?.length)
  },
  userHasAvatarConsent(state) {
    return !!state.user.consents.items.SpecialData?.length > 0
  },
  userHasBasicConsents(state) {
    return (
      !!state.user.consents.items.Privacy?.length > 0 &&
      !!state.user.consents.items.TermsOfUse?.length > 0 &&
      !!state.user.consents.items.Age?.length > 0
    )
  },
  userHasConfirmedAge(state) {
    return !!state.user.consents.items.Age?.length > 0
  },
  userHasAvatarButHasntGivenAvatarConsent(state) {
    return (
      state.user.avatars.length > 0 &&
      (state.user.consents.items.SpecialData === undefined ||
        state.user.consents.items.SpecialData.length < 1)
    )
  },
  userTimeZoneAbbreviation(state) {
    if (!state.user.userTimeZone) {
      return null
    }
    let matches = rawTimeZones.filter(tz => tz.name === state.user.userTimeZone)
    if (matches.length > 0) {
      return matches[0].abbreviation
    }
    return null
  },
  userOnlyAdminInEcosystem(state) {
    return state.onlyAdminInEcosystem
  },
}

const actions = {
  forceLogout({ commit }) {
    commit('resetAllState', {}, { root: true })
    router.push('/login')
  },

  onInitialLoad({ commit }, { initialStateData }) {
    commit('loadInitialStateData', initialStateData)
  },

  requestPasscode({ commit }, data) {
    // Data is an object like { email: "test@example.com" }
    let options = { data, method: 'POST' }
    let endpoint = `authenticate/request-email-code`
    commit('updateUser', data)

    return api(endpoint, options).then(() => {
      commit('setPasscodeRequested', true)
    })
  },

  verifyPasscode({ commit }, data) {
    const deviceId = this.getters['auth/deviceId'] ?? 'web-browser'
    commit('setDeviceId', deviceId)
    const email = this.getters['auth/user'].email
    data = {
      passcode: data.passcode,
      email: email,
      deviceId: deviceId,
    }

    let options = { data, method: 'POST' }
    let endpoint = `authenticate/email`

    return api(endpoint, options).then(response => {
      commit('updateUser', { ...response.data.data.user, id: response.data.data.id })
      commit('setToken', response.data.data.token)
      commit('setLoginTimestamp', +new Date())
      commit('setAuthenticated', true)
    })
  },

  async registerIPAddress({ commit }) {
    try {
      const ipResponse = await axios('https://api.ipify.org')
      const userIPAddress = ipResponse.data
      const userLocation = await ipLocation(userIPAddress)
      let ipDetails = {
        address: userIPAddress,
        location: userLocation,
      }

      commit('setIpDetails', ipDetails)
    } catch (error) {
      /* eslint-disable no-console */
      console.log('Failed to fetch IP details and determine user location')
      console.error(error)
    }
  },

  register({ commit }, data) {
    let options = { data, method: 'POST' }
    let endpoint = `users/register`
    return api(endpoint, options).then(response => {
      commit('updateUser', response.data.data)
    })
  },

  async grantBasicConsents({ commit }) {
    let options = { method: 'POST' }
    let endpoint = `users/profile/grant-consents`

    return api(endpoint, options).then(response => {
      const consentItems = response.data.data.items
      commit('updateConsents', consentItems)
    })
  },

  async registerAvatarConsent({ commit }) {
    let options = { method: 'POST' }
    let endpoint = `users/profile/special-data`

    return api(endpoint, options).then(response => {
      commit('updateConsents', { SpecialData: response.data.data.items.SpecialData })
    })
  },

  accountEdit({ commit }, data) {
    let options = { data, method: 'POST' }
    let endpoint = `users/update`
    return api(endpoint, options).then(response => {
      commit('updateUser', response.data.data)
    })
  },

  async isUserOnlyAdminInEcosystem({ dispatch, commit }) {
    // setting this to true keeps the delete function disabled to the user until all the checks have returned.
    // helps when the user has a slow internet connect. Stops any unwanted deletions
    commit('updateOnlyAdminInEcosystem', true)

    const getEcosystemsWhereUserIsAdmin = async () => {
      let ecosystems = await dispatch('fetchUserEcosystems')
      ecosystems = ecosystems.data.data.filter(ecosystem => {
        if (ecosystem.admin) {
          return ecosystem
        }
      })
      return ecosystems
    }

    const getEcosystemUsers = async ecosystem => {
      const {
        data: { data: users },
      } = await dispatch('fetchUsers', ecosystem)
      return users
    }

    const getEcosystemAdmins = async ecosystem => {
      const users = await getEcosystemUsers(ecosystem)
      if (users.length === 1) {
        return true
      }
      if (users.length > 1) {
        let admins = 0
        for (const user of users) {
          if (user.userType === 2) {
            admins++
          }

          // break as we have already met the criteria (more than 1 admin in an ecosystem) and we no longer need to loop through any more users in this ecosystem
          if (admins >= 2) {
            return false
          }
        }

        if (admins === 1) {
          return true
        }
      }
    }

    const ecosystemsAsAdmin = await getEcosystemsWhereUserIsAdmin()

    if (!ecosystemsAsAdmin.length) {
      commit('updateOnlyAdminInEcosystem', false)
      return
    } else {
      let onlyAdminInEcosystem = false
      for (let index = 0; index < ecosystemsAsAdmin.length; index++) {
        // return here as we dont need to loop through any more ecosystems
        if (onlyAdminInEcosystem) {
          return
        }
        const ecosystem = ecosystemsAsAdmin[index]
        const isOnlyAdmin = await getEcosystemAdmins(ecosystem)
        if (isOnlyAdmin) {
          onlyAdminInEcosystem = true
          return
        }
      }
      if (!onlyAdminInEcosystem) {
        commit('updateOnlyAdminInEcosystem', false)
      }
    }
  },
  fetchUserEcosystems() {
    let options = { method: 'GET' }
    let endpoint = `api/users/workspaces`
    return api(endpoint, options)
  },
  fetchUsers(_, ecosystem) {
    let options = { method: 'GET' }
    let endpoint = `/workspaces/${ecosystem.id}/users`
    return api(endpoint, options)
  },
  async accountDelete({ dispatch }) {
    let options = { method: 'POST' }
    let endpoint = `users/delete`

    return api(endpoint, options).then(() => {
      dispatch('forceLogout')
    })
  },
  setIpDetails({ commit }, ipDetails) {
    commit('setIpDetails', ipDetails)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  updateUser(state, userData) {
    state.user = { ...state.user, ...userData }
  },
  updateConsents(state, consentItems) {
    state.user.consents.items = { ...state.user.consents.items, ...consentItems }
  },
  updateOnlyAdminInEcosystem(state, data) {
    state.onlyAdminInEcosystem = data
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
