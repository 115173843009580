<template>
  <div class="split-content-stretch">
    <!-- Rules list -->
    <div class="pa-1 tip-text flex-grow-1 mt-4">
      <div class="d-flex justify-start align-start">
        <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
        <div class="ml-3">
          {{ $t('avatars.takePhoto1.selectAPlainUnclutteredBackgroundUseBrightWhiteLighting') }}
        </div>
      </div>

      <div class="d-flex justify-start align-start">
        <v-icon class="mt-1" color="green" size="15">mdi-check</v-icon>
        <div class="ml-3">{{ $t('avatars.takePhoto1.useBrightWhiteLighting') }}</div>
      </div>
    </div>

    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center">
        <v-btn
          fab
          color="primary"
          class="wizard-btn"
          @click="next"
          depressed
          rounded
          :disabled="cameraIsBlocked"
        >
          <div>
            {{ $t('avatars.wizard.nextStep') }}
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cameraIsBlocked: false,
    }
  },
  methods: {
    next() {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-2' } })
    },
  },
}
</script>
