<template>
  <div>
    <div class="wizard-header">
      <div class="wizard-title">
        {{ $t('avatars.adjustImage.finalAdjustments') }}
      </div>

      <div class="intro-text">
        {{ $t('avatars.adjustImage.zoomAndDragYourPhotoToLineUpYourFaceWithTheGuidesBelow') }}
      </div>
    </div>

    <div class="wizard-central-content">
      <!-- Image -->
      <div class="pa-2 d-flex justify-center align-center">
        <!-- Container for image and floating button -->
        <div class="image-container upload">
          <div class="image-circle">
            <img
              class="image-upload"
              :src="imageUrl"
              @load="checkDimensions"
              id="avatarImage"
              data-cy="avatar-image"
              ref="avatarImage"
            />
            <!-- canvas for image capture -->
            <canvas
              id="avatarCanvas"
              ref="canvasElement"
              :width="targetSize"
              :height="targetSize"
              class="d-none"
            ></canvas>
          </div>
          <!-- Remove button -->
          <v-btn fab dark color="#FFFFFF" class="remove-btn" @click="confirmRemoveImage">
            <v-icon dark color="black" size="8px"> mdi-close </v-icon>
          </v-btn>
          <!-- Avatar mask -->
          <c-avatar-wizard-avatar-mask class="adjust-mask" />
        </div>
      </div>

      <!-- Slider zoom -->
      <div class="d-flex justify-center">
        <v-slider
          style="width: 250px"
          v-model="scale"
          append-icon="mdi-magnify-plus-outline"
          prepend-icon="mdi-magnify-minus-outline"
          @input="zoom"
          @click:append="zoom('in')"
          @click:prepend="zoom('out')"
          :disabled="generating"
          min="1"
          :max="maxScale"
          :step="step"
          data-cy="image-range-slider"
          v-if="maxScale > 1"
        ></v-slider>
      </div>
    </div>

    <!-- Generate Avatar button -->
    <div class="wizard-footer">
      <div class="d-flex justify-content-center align-center mt-2">
        <v-btn
          fab
          color="primary"
          class="wizard-btn"
          @click="generateAvatar"
          depressed
          rounded
          :disabled="generating"
        >
          <div>
            {{ $t('avatars.wizard.generateAvatar') }}
          </div>
        </v-btn>
      </div>

      <!-- Go to take photo link -->
      <div class="mt-5 pretend-link" @click="confirmTakePhoto">
        {{ $t('avatars.wizard.takePhotoWithCamera') }}
      </div>
    </div>
  </div>
</template>

<script>
import Panzoom from '@panzoom/panzoom'
export default {
  props: ['file', 'decisionAnswer', 'decisionCallback'],
  data() {
    return {
      imageUrl: '',
      minScale: 1,
      maxScale: 100,
      scale: 1,
      step: 0.01,
      panZoom: undefined,
      croppedImage: undefined,
      targetSize: 1000,
      previewSize: 250,
      canvas: undefined,
      generating: false,
      shape: 'square',
    }
  },
  methods: {
    checkDimensions() {
      // This function allows user to pan vertically if the image is portrait or horizontal if landscape
      // even when the scale is 1
      if (this.shape === 'portrait') {
        document.getElementById('avatarImage').style.height = 'auto'
      }
      if (this.shape === 'landscape') {
        document.getElementById('avatarImage').style.width = 'auto'
      }
    },
    async generateAvatar() {
      this.generating = true
      const scale = this.panZoom.getScale()

      const shortestDimension =
        this.shape === 'landscape' ? this.file['dimensions'].height : this.file['dimensions'].width

      let x = 0
      let y = 0

      // Account for object-fit clipping
      if (this.shape === 'portrait') {
        y = (this.file['dimensions'].height - this.file['dimensions'].width) / 2
      }
      if (this.shape === 'landscape') {
        x = (this.file['dimensions'].width - this.file['dimensions'].height) / 2
      }

      // Account for zoom
      if (scale > 1) {
        let zoomOffset = (shortestDimension - shortestDimension / scale) / 2
        x = x + zoomOffset
        y = y + zoomOffset
      }

      // Account for panning - NB x and y returned are relative to the original 250 px image
      const pan = this.panZoom.getPan()
      x = x - pan.x * (shortestDimension / this.previewSize)
      y = y - pan.y * (shortestDimension / this.previewSize)

      // Draw the image on the canvas
      const canvas = document.getElementById('avatarCanvas')
      const context = canvas.getContext('2d')
      const image = document.getElementById('avatarImage')

      const sWidth = shortestDimension / scale
      const sHeight = shortestDimension / scale

      context.drawImage(image, x, y, sWidth, sHeight, 0, 0, this.targetSize, this.targetSize)

      const vm = this

      canvas.toBlob(
        function (blob) {
          vm.$store.dispatch('ui/toggleLoading', { show: true })
          vm.$store.dispatch('avatars/add', { file: blob, fileName: vm.file['name'] }).then(
            () => {
              vm.$store.dispatch('ui/toggleLoading', { show: false })
              vm.$router.push({ path: '/avatars', query: { step: 'generating' } })
            },
            error => {
              vm.generating = false
              vm.$store.dispatch('ui/toggleLoading', { show: false })
              vm.loading = false
              vm.message =
                (error.response && error.response.data) || error.message || error.toString()
            }
          )
        },
        'image/jpeg',
        0.9
      )

      // if (process.env.NODE_ENV === 'development') {
      //   let anchor = document.createElement('a')
      //   anchor.href = canvas.toDataURL('image/jpeg')
      //   anchor.download = 'IMAGE.jpeg'
      //   anchor.click()
      // }
    },
    confirmTakePhoto() {
      if (this.generating) {
        return
      }
      this.$emit('confirm', {
        question: this.$t('avatars.adjustImage.areYouSureYouWantToUseYourCameraInstead'),
        callBack: this.takePhoto,
      })
    },
    takePhoto() {
      this.$router.push({ path: '/avatars', query: { step: 'take-photo-1' } })
    },
    confirmRemoveImage() {
      if (this.generating) {
        return
      }
      this.$emit('confirm', {
        question: this.$t('avatars.adjustImage.areYouSureYouWantToRemoveThisPhotoAndSelectAnother'),
        callBack: this.removeImage,
      })
    },
    removeImage() {
      this.$emit('image', {
        file: undefined,
        fileName: '',
        dimensions: {
          width: 0,
          height: 0,
        },
      })
      this.$router.push({ path: '/avatars', query: { step: 'file-select' } })
    },
    zoom(way = '') {
      // If the string is not empty the user is clicking the icons
      let jump = this.step * 10
      if (way === 'in') {
        if (this.scale >= this.maxScale) {
          return
        }
        this.scale = this.scale + jump
        this.panZoom.zoom(this.scale)
        return
      }

      if (way === 'out') {
        if (this.scale === 1) {
          return
        }
        this.scale = this.scale - jump
        this.panZoom.zoom(this.scale)
        return
      }

      // else the user is dragging the range slider
      this.panZoom.zoom(this.scale)
    },
  },
  mounted() {
    if (!this.file['file']) {
      // Perhaps user has refreshed page so image is no longer present...
      this.$router.push({ path: '/avatars', query: { step: 'file-select' } })
      return
    }

    let avatarImage = document.querySelector('#avatarImage')

    let shortestSide =
      this.file['dimensions'].height < this.file['dimensions'].width
        ? this.file['dimensions'].height
        : this.file['dimensions'].width

    this.maxScale = shortestSide / 1000

    this.panZoom = Panzoom(avatarImage, {
      minScale: 1,
      maxScale: 100,
      contain: 'outside',
      startScale: 1,
      step: this.step,
    })

    let URL = window.URL || window.webkitURL
    if (URL && URL.createObjectURL) {
      this.imageUrl = URL.createObjectURL(this.file['file'])
    }

    this.shape =
      this.file['dimensions'].height < this.file['dimensions'].width
        ? 'landscape'
        : this.file['dimensions'].width < this.file['dimensions'].height
        ? 'portrait'
        : 'square'
  },
}
</script>
<style lang="scss" scoped>
.remove-btn {
  border: solid 1px #dddddf;
  height: 32px;
  width: 32px;
  right: -16px;
  top: calc(50% - 32px / 2);
  position: absolute;
}

.image-circle {
  width: 250px;
  height: 250px;
  background-color: lightgray;
  border-radius: 50%;
  overflow: hidden;
}
</style>
