import axios from 'axios'
import { prefixUrl } from '@/utilities/urls'
import store from '@/plugins/store'
import Vue from 'vue'
import Vuex from 'vuex'
import { getAppSetting } from './settings'

Vue.use(Vuex)

function addApiHeaders(headers, { json = true } = {}) {
  headers['Access-Control-Allow-Origin'] = '*'
  const accessToken = store?.getters['auth/token']
  if (json) addJsonHeaders(headers)
  if (!accessToken) return addNoAuthHeaders(headers)
  else return addBearerTokenHeader(headers, { accessToken })
}

function addBearerTokenHeader(headers, { accessToken } = {}) {
  if (accessToken) headers['Authorization'] = 'Bearer ' + accessToken
  return headers
}

function addJsonHeaders(headers) {
  headers['Content-Type'] = 'application/json'
  headers['Accept'] = 'application/json'
  return headers
}

function addNoAuthHeaders(headers) {
  return headers
}

/**
 * A function for making api requests.
 *
 * @param {String} url Target api URL
 * @param {Object} options Optional parameters (optional)
 * @param {Boolean} options.auth Indicates if url requires authentication
 * @param {Mixed} options.data Request payload
 * @param {Object} options.headers Request headers (default: {})
 * @param {Object} options.json Convert body to json (default: false)
 * @param {String} options.method Request method (default: 'GET')
 * @param {String} options.prefix URL prefix (default: 'api')
 * @param {Object} options.params Query parameters (default: {})
 * @param {Object} options.urlAppSettingKey App settings key for url (default: 'VUE_APP_API_ROOT_URL')
 * @param {Object} axiosOptions Additional options passed directly through to axios (default: {})
 *
 * @returns {Mixed} The result of the api request
 */
export default async function api(url, options, axiosOptions) {
  return await request(url, options, axiosOptions)
}

function getBody(data, stringify) {
  if (!data) return ''
  if (typeof data === 'string') return data
  const body = data
  return stringify ? JSON.stringify(body) : body
}

function getUrl(prefix, url, urlAppSettingKey) {
  return prefixUrl(prefixUrl(url, prefix), getAppSetting(urlAppSettingKey))
}

function onUndefinedError(e) {
  /* eslint-disable no-console */
  console.error('api error (undefined error)')
  if (typeof e !== 'undefined') console.error(e)
  // if nothing passed, assume for now 400 bad request
  else store.dispatch('ui/onApiError', { status: 400 })
}

async function request(url, opts, axiosOptions = {}) {
  if (!url) throw 'api needs a valid target url'
  const {
    auth,
    data,
    json,
    headers = {},
    method = 'GET',
    prefix = '',
    params = {},
    urlAppSettingKey = 'VUE_APP_API_ROOT_URL',
  } = opts || {}

  store.commit('ui/setApiEndpointsLoading', { method, url, value: true })

  const options = {
    data: getBody(data, json),
    headers: addApiHeaders(headers, opts),
    method,
    params: params,
    url: getUrl(prefix, url, urlAppSettingKey),
    // pass through additional options last to override previous options where necessary
    ...axiosOptions,
    withCredentials: !!auth || false,
  }

  return await axios(options)
    .then(r => {
      return r
    })
    .catch(e => {
      if (!e?.response?.status) onUndefinedError(e?.response)
      else {
        /* eslint-disable no-console */
        console.error('api error', e)
        store.dispatch('ui/onApiError', e?.response)
      }
      throw new Error(e)
    })
    .finally(() => {
      store.commit('ui/setApiEndpointsLoading', { method, url, value: false })
    })
}
