export default {
  account: {
    titles: {
      back: 'Back',
      settings: 'Settings',
      edit: 'Update your profile settings',
      generalSettings: 'General Settings',
    },
    delete: {
      deleteAccount: 'Delete account',
      youCanDeleteYourAccount:
        'You can delete your account and with it all personal information on Gatherings VR',
      youAreUnableToDeleteThisAccount:
        'You are unable to delete this account as you are the only admin user within your ecosystem.',
      ifYouWouldLikeToDeleteYourAccount: 'If you would like to delete your account please',
      contactUs: 'contact us',
      forMoreInformationPleaseRead: 'For more information please read',
      privacyPolicy: 'Privacy policy',
      areYouSureYouWantToDeleteYourAccount: 'Are you sure you want to delete your account?',
      byDeletingYourAccountYouWillLoseAllData:
        'By deleting your account you will lose all data. Data recovery will not be possible',
    },
  },
}
