import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'

const routes = [
  { path: '/', redirect: '/login' },
  {
    component: () =>
      import(/* webpackChunkName: "ErrorByStatus" */ '@/views/errors/ErrorByStatus.vue'),
    meta: {
      title: pageTitle(String(i18n.t('errors.sorrySomethingWentWrong'))),
      layout: 'empty-layout',
    },
    name: 'Error',
    path: '/error/:status',
  },
]

export default routes
