import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'

const routes = [
  {
    component: () => import(/* webpackChunkName: "EventsShow" */ '@/views/events/Show.vue'),
    meta: {
      title: pageTitle(String(i18n.t('events.titles.show'))),
      requires: requirements,
    },
    name: 'Events',
    path: '/events/:eventId',
    props: true,
  },
]

export default routes
