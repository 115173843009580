<template>
  <v-container
    fluid
    data-cy="dashboard-nav"
    class="dashboard-nav-container d-flex justify-center dashboard-nav-shadow"
  >
    <!-- Desktop navbar -->
    <div
      class="d-flex justify-space-between align-center nav-items-container"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <!-- Left hand content -->
      <div class="d-flex justify-space-between align-center" style="width: 475px">
        <!-- Logo -->
        <div @click="$router.push('/')" data-cy="go-home" class="d-flex clickable align-center">
          <c-logo />
        </div>

        <!-- Nav Links -->
        <div
          v-for="(link, name) in navLinks"
          :key="name"
          class="link-container"
          :class="{ activePage: isActivePage(link) }"
        >
          <router-link :to="`/${link}`" class="nav-link" :class="{ dark: isActivePage(link) }">{{
            linkTitle(name)
          }}</router-link>
        </div>

        <!-- 'Support' nav item -->
        <v-menu
          bottom
          :close-on-content-click="false"
          :nudge-bottom="70"
          :nudge-left="10"
          class="user-menu"
          data-cy="user-menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              class="link-container"
              :class="{ activePage: isActivePage('support') }"
            >
              <div class="nav-link" :class="{ dark: isActivePage('support') }">Support</div>
            </div>
          </template>

          <v-list class="user-menu">
            <v-list-item class="clickable" :href="$links.setup" target="_blank">
              <v-list-item-title class="user-menu-item"> Setup instructions </v-list-item-title>
            </v-list-item>
            <v-list-item class="py-1 clickable border-top" :href="$links.setupFAQs" target="_blank">
              <v-list-item-title class="user-menu-item"
                >Frequently asked questions</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- Right hand user menu -->
      <c-user-menu />
    </div>

    <!-- Mobile/Ipad nav bar -->
    <div v-else class="d-flex justify-space-between align-center flex-grow-1">
      <!-- Left hand logo and title -->
      <div class="d-flex align-center" @click="$router.push('/')">
        <!-- Logo -->
        <div data-cy="go-home" class="d-flex clickable align-center">
          <c-logo />
        </div>
        <!-- Title -->
        <div class="ml-2 mobile-title">Gatherings</div>
      </div>

      <!-- User menu/mobile menu -->
      <div class="d-flex" @click="showMobileMenu = !showMobileMenu">
        <c-user-avatar-circle size="40" avatarStyle="previewIconHeadonlyT" />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="40" v-bind="attrs" v-on="on">mdi-menu</v-icon>
          </template>
          <v-list width="200px">
            <v-list-item v-for="(link, name) in navLinks" :key="name" :to="`/${link}`">
              <v-list-item-title>
                {{ linkTitle(name) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item :href="$links.setup" target="_blank">
              <v-list-item-title> Support </v-list-item-title>
            </v-list-item>

            <v-list-item :to="`/account/edit`">
              <v-list-item-title> My account </v-list-item-title>
            </v-list-item>

            <v-list-item class="border-top d-flex align-center" @click="logOut" data-cy="log-out">
              <v-icon size="16" color="#1A1E38" class="mr-2">fa-sign-out-alt</v-icon>
              <v-list-item-title> Log out </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-container>
</template>

<script>
import { toStartCase } from '../../utilities/functions'
export default {
  data() {
    return {
      showMobileMenu: false,
      navLinks: {
        avatars: 'avatars',
        // sessions: 'sessions',
        devices: 'devices/connect',
      },
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/login')
    },
    isActivePage(string) {
      return this.$route.path.includes(string)
    },
    linkTitle(title) {
      return toStartCase(title)
    },
  },
}
</script>
<style lang="scss" scoped>
.link-container {
  display: flex;
  align-items: center;
  height: 80px;
}
.nav-link {
  font-family: 'Kiro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #363332;
  opacity: 0.6;
  text-decoration: none;
}
.nav-link:hover {
  color: #807ef0;
}
.activePage {
  border-bottom: solid 4px #807ef0;
  margin-bottom: -4px;
}
.dark {
  opacity: 1;
}
.user-menu {
  padding-bottom: 0px;
}
.user-menu-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #1a1e38;
}
.user-menu .v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  padding-top: 0;
  padding-left: 0.5rem;
}
.v-menu__content {
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border: none;
}
::v-deep .v-list-item:hover {
  background: #f3efed;
}
.border-top {
  border-top: 1px solid #dfe2e6;
}
.dashboard-nav-container {
  background-color: white !important;
  align-self: start;
  padding-right: 16px;
  padding-left: 16px;
  height: 80px;
}
@media screen and (min-width: 960px) {
  .dashboard-nav-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.mobile-title {
  font-size: 20px;
  color: #707070;
}
.nav-items-container {
  min-width: 850px;
  flex-grow: 1;
  max-width: 1200px;
}

.v-list .v-list-item--active {
  color: #807ef0;
}

.dashboard-nav-shadow {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05));
}
</style>
