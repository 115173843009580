<template>
  <v-dialog v-model="show" class="dialog" persistent max-width="450px" max-height="300px">
    <v-card>
      <v-card-title class="dialog-question">
        {{ question }}
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <p v-if="text" class="dialog-text mx-auto font-weight-light">
          {{ text }}
        </p>
        <div class="d-flex align-center justify-space-around">
          <div>
            <v-btn
              type="submit"
              width="135"
              height="50"
              class="btn-text mr-1"
              @click.prevent="$emit('decision', 'no')"
              color="#F4F6FA"
              rounded
              depressed
              data-cy="remove-no"
            >
              {{ $t('common.cancel') }}
            </v-btn>
          </div>

          <div>
            <v-btn
              type="submit"
              width="135"
              height="50"
              class="btn-text"
              @click.prevent="$emit('decision', 'yes')"
              color="primary"
              rounded
              depressed
              data-cy="remove-yes"
            >
              {{ $t('common.yes') }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['show', 'question', 'text'],
}
</script>
<style lang="scss" scoped>
.dialog-question {
  text-align: center;
  padding: 20px 40px !important;
  word-break: normal;
}
.dialog-text {
  font-family: Kiro;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 150% !important;
  text-align: center;
  color: #2d3142;
}

::v-deep .v-dialog {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(112, 136, 210, 0.102);
  border-radius: 20px;
}
</style>
