import account from './account'
import avatars from './avatars'
import events from './events'
import dashboard from './dashboard'
import devices from './devices'
import sessions from './sessions'
import onboarding from './onboarding'
import files from './files'

const routes = [
  ...account,
  ...avatars,
  ...events,
  ...dashboard,
  ...devices,
  ...sessions,
  ...onboarding,
  ...files,
]

export default routes
