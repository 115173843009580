<template>
  <v-app v-bind:class="{ loading: isLoading }" class="mesmerise-background">
    <v-main>
      <c-errors></c-errors>
      <c-snackbar></c-snackbar>

      <v-container fluid fill-height class="pa-0 d-flex flex-column align-center flex-nowrap">
        <c-loading-bar></c-loading-bar>
        <c-dashboard-nav></c-dashboard-nav>
        <slot />
      </v-container>

      <v-container fluid>
        <!-- Footer -->
        <v-row align-self-end class="align-self-stretch">
          <v-col class="align-self-end">
            <c-footer></c-footer>
          </v-col>
        </v-row>

        <!-- Cookie dialog -->
        <c-cookie-dialog />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    authenticated() {
      return this.$store.getters['auth/authenticated']
    },
    isLoading() {
      return this.$store.getters['ui/loading']
    },
    hasGeneralCookie() {
      return this.$cookies.get('gatherings_accept_cookies') !== undefined
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  cursor: progress;
}
</style>
