import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import Cookies from 'js-cookie'
import VueCookies from 'vue-cookies-reactive'

const defaultState = () => ({
  cookies: {
    gatherings_accept_cookies: Cookies.get('gatherings_accept_cookies'),
  },
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  acceptsAll() {
    return VueCookies.get('gatherings_accept_cookies') !== undefined
  },
}

const actions = {
  create({ commit }, name) {
    // TODO - Check we have permission to create the type of cookie - if "gatherings_accept_cookies" is set then we do
    let dateSet = +new Date()
    let expiresAfter = '1y' // one year
    VueCookies.set(name, dateSet, expiresAfter)
    commit('updateCookies', { name, dateSet })
  },
  get(name) {
    return VueCookies.get(name)
  },
  destroy({ commit }, name) {
    VueCookies.remove(name)
    commit('removeCookie', name)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  updateCookies(state, { name, dateSet }) {
    Vue.set(state.cookies, name, dateSet)
  },
  removeCookie(state, name) {
    Vue.delete(state.cookies, name)
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
