<template>
  <v-snackbar
    bottom
    right
    class="pa-2"
    :color="color"
    @input="close"
    shaped
    :timeout="timeout"
    :value="visible"
    :vertical="vertical"
    :multi-line="true"
  >
    <div class="text-subtitle-1" v-html="message"></div>
    <template v-slot:action="{ attrs }">
      <v-btn rounded class="mr-1" icon :color="buttonColor" text v-bind="attrs" @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    buttonColor() {
      return this.snackbar?.buttonColor
    },
    color() {
      return this.snackbar?.color
    },
    message() {
      return this.snackbar?.message
    },
    snackbar() {
      return this.$store.getters['ui/snackbar']
    },
    timeout() {
      return this.snackbar?.timeout
    },
    vertical() {
      return this.snackbar?.vertical
    },
    visible() {
      return this.snackbar?.visible
    },
  },
  methods: {
    close() {
      this.$store.dispatch('ui/closeSnackbar')
    },
  },
}
</script>
