import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'

const routes = [
  {
    component: () => import(/* webpackChunkName: "Avatars" */ '@/views/avatars/List.vue'),
    meta: {
      title: pageTitle(String(i18n.t('avatars.titles.list'))),
      requires: { ...requirements, avatarConsent: true },
    },
    name: 'Avatars',
    path: '/avatars',
  },
]

export default routes
