import { DateTime } from 'luxon'
import { getNumberSuffix } from '../../utilities/functions'
import { rawTimeZones, getTimeZones } from '@vvo/tzdb'
export default {
  data() {
    return {
      dateFormat: 'yyyy/M/d H:mm',
      defaultTimeZoneString: 'Central Daylight Time',
    }
  },
  computed: {
    userTimeZone() {
      return this.$store.getters['auth/user'].userTimeZone ?? this.sessionTimeZone
    },
    sessionTimeZone() {
      return 'America/Chicago'
    },
    sessionIsoDate() {
      return DateTime.fromFormat(this.session.startTime, this.dateFormat, {
        zone: this.sessionTimeZone,
      })
        .setZone(this.userTimeZone)
        .toISO()
    },
    sessionDurationArray() {
      let start = DateTime.fromFormat(this.session.startTime, this.dateFormat, {
        zone: this.sessionTimeZone,
      }).setZone(this.userTimeZone)

      let end = DateTime.fromFormat(this.session.endTime, this.dateFormat, {
        zone: this.sessionTimeZone,
      }).setZone(this.userTimeZone)

      let durationInMinutes = end.diff(start, ['minutes']).toObject()

      return [durationInMinutes.minutes, 'minutes']
    },
  },
  methods: {
    getSessionStartAndEnd(inUserTimeZone = false) {
      let start = DateTime.fromFormat(this.session.startTime, this.dateFormat, {
        zone: this.sessionTimeZone,
      })
      let end = DateTime.fromFormat(this.session.endTime, this.dateFormat, {
        zone: this.sessionTimeZone,
      })

      if (inUserTimeZone) {
        start = start.setZone(this.userTimeZone)
        end = end.setZone(this.userTimeZone)
      }

      return [start, end]
    },
    sessionDate(inUserTimeZone = false) {
      // Incoming startDate looks like this: 16/5/2022 15:30 and is based on the time in Chicago AKA "Central Daylight Time"
      const [start] = this.getSessionStartAndEnd(inUserTimeZone)

      let weekDay = start.toFormat('EEE')
      let dayOfMonth = start.toFormat('dd')
      let suffix = getNumberSuffix(dayOfMonth)
      let month = start.toFormat('MMM')

      return `${weekDay} ${dayOfMonth}${suffix} ${month}`
    },
    sessionTime(inUserTimeZone = false) {
      // e.g. 10:00am
      let [start] = this.getSessionStartAndEnd(inUserTimeZone)
      let startTime = start.toFormat('h:mm')
      let startMeridiem = start.toFormat('a')
      return `${startTime}${startMeridiem}`
    },
    sessionDuration() {
      // e.g. 1hr 30mins or 45mins or 1hr
      let [start, end] = this.getSessionStartAndEnd()
      let timeBetween = end.diff(start, ['hours', 'minutes']).toObject() // e.g. { hours: 1, minutes: 2 }

      let duration = ''

      if (timeBetween.hours) {
        let hrs = timeBetween.hours === 1 ? 'hr' : 'hrs'
        duration = duration + timeBetween.hours + hrs
        if (timeBetween.minutes) {
          duration = duration + ' '
        }
      }

      if (timeBetween.minutes) {
        let mins = timeBetween.minutes === 1 ? 'min' : 'mins'
        duration = duration + timeBetween.minutes + mins
      }
      return duration
    },
    sessionTimeZoneAbbreviation(inUserTimeZone = false) {
      // e.g. GMT, CET, CDT
      let timeZone = inUserTimeZone ? this.userTimeZone : this.sessionTimeZone
      let matchingTimeZone = rawTimeZones.filter(tz => tz.name === timeZone)[0]
      if (
        matchingTimeZone.abbreviation === 'CST' &&
        matchingTimeZone.countryName === 'United States'
      ) {
        return 'CDT'
      }
      return matchingTimeZone.abbreviation
    },
    abbreviationToIANA(acronym = null) {
      // Three letter acronyms for timezones are not very helpful
      // But they are all we have at the moment
      // So instead of returning the time in Belize or China, make sure we return the time in Chicago
      // If the acronyms CDT or CST are used...
      if (acronym === 'CDT' || acronym === 'CST') {
        return 'America/Chicago'
      }

      if (!acronym) {
        return 'America/Chicago'
      }

      // Might be multiple matches - eg CST can be (US) central standard time or (chinese) Chinese Standard time - just return first match
      let matchingTimeZone = rawTimeZones.find(tz => tz.abbreviation === acronym)

      if (!matchingTimeZone) {
        return 'America/Chicago'
      }

      return matchingTimeZone.name
    },
    toolTipTime(inUserTimeZone = false) {
      // e.g. Central Daylight Time (CST)
      let userWindowsTimeZone = inUserTimeZone
        ? this.$store.getters['auth/user'].userTimeZone ?? this.defaultTimeZoneString
        : this.defaultTimeZoneString
      let userTimeZoneAbbreviation = this.sessionTimeZoneAbbreviation(inUserTimeZone)
      return `${userWindowsTimeZone} (${userTimeZoneAbbreviation})`
    },
    toolTipUTCAndCities(inUserTimeZone = false) {
      // e.g. UTC+3:30 Tehran
      let timeZoneIANAName = inUserTimeZone ? this.userTimeZone : this.sessionTimeZone
      let timeZoneDataMatches = getTimeZones().filter(tz => tz.name === timeZoneIANAName)
      if (timeZoneDataMatches.length === 0) {
        return ''
      }
      let timeZoneData = timeZoneDataMatches[0]
      let offSetMins = timeZoneData.currentTimeOffsetInMinutes
      let utcOffset =
        offSetMins % 60 === 0
          ? (offSetMins / 60).toString()
          : `${(offSetMins - (offSetMins % 60)) / 60}:${offSetMins % 60}`
      let positive = utcOffset > 0 ? '+' : ''

      let mainCities = timeZoneData.mainCities
      let cities = mainCities.length > 1 ? `${mainCities[0]}, ${mainCities[1]}` : `${mainCities[0]}`
      return `UTC${positive}${utcOffset} ${cities}`
    },
  },
}
