export default {
  auth: {
    titles: {
      signInOrCreateAccountGatherings: 'Sign in or create account',
      enterYourPasscode: 'Enter your passcode',
    },
    login: {
      welcomeTo: 'Welcome to',
      gatherings: 'Gatherings',
      signInOrCreateAnAccountUsingYourEmail: 'Sign in or create an account using your email',
    },
    passcode: {
      enterYourPasscode: 'Enter your passcode',
      pleaseProvideAValid_6DigitCode: 'Please provide a valid 6 digit passcode',
      canTFindACode: "Can't find a passcode",
      checkYourJunkFolder: 'Check your junk folder',
      checkYourEmailAndEnterYourCodeBelow: 'Check your email and enter your passcode below',
      sendMeANewCode: 'Send me a new passcode',
      weVeJustSentYouAnEmail: "We've just sent you an email",
      passcodeInvalidOrMayHaveExpired: 'Passcode invalid or may have expired',
    },
    logOut: 'Log out',
    youVeBeenLoggedOut: "You've been logged out",
    youVeBeenLoggedOutDueToSessionTimeoutSignBackInToContinueUsingGatherings:
      'You’ve been logged out due to session timeout. <br>Sign back in to continue using Gatherings.',
  },
}
