<template>
  <div class="shape-position">
    <svg
      width="400"
      height="463"
      viewBox="0 0 400 463"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="shape-position"
    >
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M219.463 25.3754C203.65 50.6522 211.344 83.9484 236.647 99.7446C261.95 115.541 295.281 107.855 311.093 82.5784C326.906 57.3016 319.212 24.0054 293.909 8.20922C268.606 -7.58695 235.275 0.0986239 219.463 25.3754ZM271.607 173.936C272.959 170.767 274.573 167.662 276.458 164.648C296.023 133.374 337.263 123.864 368.571 143.409C399.878 162.953 409.397 204.151 389.832 235.425C374.582 259.804 346.161 270.958 319.54 265.434C319.588 265.786 319.634 266.133 319.679 266.476C317.185 265.745 314.579 264.612 311.959 263.387C308.08 262.089 304.273 260.416 300.585 258.358C289.525 254.645 281.091 258.374 284.214 298.073C289.365 330.832 283.181 365.525 264.208 395.854C222.366 462.739 134.17 483.076 67.2156 441.278C0.261231 399.479 -20.0966 311.374 21.745 244.489C60.8134 182.037 140.296 160.168 205.165 191.57C231.111 199.203 259.686 202.468 270.216 177.123C270.681 176.002 271.145 174.94 271.607 173.936ZM184.486 159.468C172.707 152.114 169.125 136.614 176.486 124.848C183.847 113.081 199.363 109.503 211.142 116.856C222.921 124.21 226.503 139.71 219.142 151.476C211.781 163.243 196.265 166.821 184.486 159.468Z"
        fill="#CBC9F7"
      />
    </svg>
    <div class="text-position">
      <div class="you-dont mb-4 mt-0">{{ $t('sessions.list.noSessionsTitle') }}</div>
      <div class="start-now">{{ $t('sessions.list.noSessionsMessage') }}</div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.shape-position {
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 463px;
  margin-right: -15px;
  margin-left: 15px;
}
.text-position {
  position: absolute;
  width: 208px;
  height: 130px;
  left: 55px;
  top: 250px;
}
@media screen and (min-width: 430px) {
  .shape-position {
    margin-right: 0px;
    margin-left: 0px;
  }
  .text-position {
    left: 45px;
  }
}

.you-dont {
  font-family: Kiro;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130%;
  text-align: center;
  color: #807ef0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.start-now {
  font-family: Kiro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #807ef0;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
}
</style>
