<template>
  <v-container class="event-container">
    <!-- Top row with back button and event title -->
    <router-link class="back-button pt-5 px-5 d-flex text-decoration-none" to="/events">
      <v-icon data-cy="go-back">mdi-chevron-left </v-icon>
      <div>Back</div>
    </router-link>

    <div class="text-center event-title mt-1 mt-md-n6">{{ event.title }}</div>
    <div class="text-center mt-4 event-date">{{ eventDates }}</div>
    <div class="text-center mt-8">
      <img :src="eventImage" class="event-image" :alt="eventName" />
    </div>

    <div class="event-details">
      <div class="event-description">{{ eventDescription }}</div>

      <!-- speakers -->
      <div class="section-title">Speakers</div>

      <div class="d-flex flex-wrap mt-8">
        <div v-for="(speaker, index) in eventSpeakers" :key="index" class="d-flex mt-2 speaker">
          <!-- Speaker image -->
          <c-user-avatar-circle
            v-if="speaker.imageLink"
            size="32"
            class="ml-2"
            :url="speaker.imageLink"
            :name="`${speaker.name}`"
          />
          <div v-else class="ml-2">
            <v-avatar :size="32" class="mr-4" color="#EEEEEE">
              <img :src="'/images/person.svg'" :alt="`${speaker.name}`" class="no-image" />
            </v-avatar>
          </div>

          <!-- Speaker description -->
          <div>
            <div class="speaker-title">{{ speakerTitle(speaker) }}</div>
            <div class="speaker-about text-wrap mt-2">
              {{ shortSpeakerDescription(speaker.about) }}
            </div>
          </div>
        </div>
      </div>
      <!-- schedule -->
      <div class="section-title">Schedule</div>

      <v-row v-if="events.length > 0" class="justify-center mt-4">
        <v-col class="event-card-column">
          <c-session
            v-for="event in events"
            :key="event.id"
            :event="event"
            :timeZone="timeZoneType"
          />
        </v-col>
      </v-row>

      <!-- sponsors -->
      <div
        v-if="eventSponsors && eventSponsors.length > 0 && eventSponsors.find(sp => sp.imageLink)"
      >
        <div class="section-title">Sponsors</div>
        <div class="sponsor-logos-container d-flex flex-wrap justify-space-between mt-8">
          <div
            v-for="(sponsor, index) in eventSponsors"
            :key="index"
            class="sponsor-logo-container"
          >
            <img v-if="sponsor.imageLink" class="sponsor-logo" :src="sponsor.imageLink" />
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import eventsData from './eventsData'
import { DateTime } from 'luxon'
export default {
  props: ['eventId', 'inUserTimeZone'],
  data() {
    return {
      eventsData: eventsData,
      dateFormat: 'yyyy/M/d H:mm',
    }
  },
  methods: {
    speakerTitle(speaker) {
      let string = speaker.name
      if (speaker.role) {
        string = `${string} | ${speaker.role}`
      }

      if (speaker.company) {
        string = `${string} @ ${speaker.company}`
      }

      return string
    },
    shortSpeakerDescription(aboutSpeakerString) {
      let aboutLength = this.$vuetify.breakpoint.smAndUp ? 90 : 60
      return aboutSpeakerString.length > aboutLength
        ? aboutSpeakerString.substring(0, aboutLength - 3) + '...'
        : aboutSpeakerString
    },
  },
  computed: {
    timeZoneType() {
      return this.inUserTimeZone ? 'Local' : 'Event'
    },
    eventImage() {
      if (this.event.imageLink && this.event.imageLink.length > 0) {
        return this.event.imageLink
      }
      return '/images/event-header.png'
    },
    eventName() {
      return this.event && this.event.name ? this.event.name : ''
    },
    eventDescription() {
      return this.event && this.event.description ? this.event.description : ''
    },
    event() {
      if (this.eventsData === undefined) {
        return null
      }

      if (Object.keys(this.eventsData.events).length === 0) {
        return null
      }

      let matchingEvent = this.eventsData.events.find(event => event.id === this.eventId)

      if (!matchingEvent) {
        return null
      }

      return matchingEvent
    },
    eventDates() {
      // eg May 16–18, 2022
      if (!this.event) {
        return ''
      }

      let timeZone = this.inUserTimeZone ? this.userTimeZone : this.eventTimeZone

      let start = DateTime.fromFormat(this.event.startTime, this.dateFormat, {
        zone: timeZone,
      })
      let end = DateTime.fromFormat(this.event.endTime, this.dateFormat, {
        zone: timeZone,
      })

      let month = start.toFormat('EEEE')
      let startDay = start.toFormat('d')
      let endDay = end.toFormat('d')
      let year = start.toFormat('yyyy')

      return `${month} ${startDay}-${endDay}, ${year}`
    },
    eventSpeakers() {
      let speakers = []

      if (!this.events) {
        return speakers
      }

      this.events.forEach(event => {
        speakers = speakers.concat(event.speakers)
      })

      let deDuplicatedSpeakers = []

      speakers.forEach(speaker => {
        let matchingSpeaker = deDuplicatedSpeakers.find(s => {
          return s.name === speaker.name
        })

        if (!matchingSpeaker) {
          deDuplicatedSpeakers.push(speaker)
        }
      })

      return deDuplicatedSpeakers
    },
    eventSponsors() {
      let sponsors = []

      if (!this.events) {
        return sponsors
      }

      this.events.forEach(event => {
        if (event.sponsors && event.sponsors.length) {
          sponsors = sponsors.concat(event.sponsors)
        }
      })

      let deDuplicatedSponsors = []

      sponsors.forEach(sponsor => {
        let matchingSponsor = deDuplicatedSponsors.find(s => {
          return s.name === sponsor.name
        })

        if (!matchingSponsor) {
          deDuplicatedSponsors.push(sponsor)
        }
      })

      return deDuplicatedSponsors
    },
    events() {
      let events = []
      this.event.days.forEach(day => (events = events.concat(day.entries)))
      return events
    },
    userTimeZone() {
      return this.$store.getters['auth/user'].userTimeZone ?? this.eventTimeZone
    },
    eventTimeZone() {
      return 'America/Chicago'
    },
  },
}
</script>
<style lang="scss" scoped>
.event-container {
  background: white;
}

@media screen and (min-width: 900px) {
  .event-container {
    max-width: 75%;
  }
}

@media screen and (min-width: 1264px) {
  .event-container {
    max-width: 1050px;
  }
}
.back-button {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #273443;
  display: flex;
  align-items: center;
}
.event-image {
  max-width: 80%;
  border-radius: 20px;
}

@media screen and (min-width: 960px) {
  .event-image {
    max-width: 620px;
    width: 620px;
    border-radius: 20px;
  }
}

.event-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #363332;
}
.event-date {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #363332;
}
.event-details {
  margin: 40px 46px 0 46px;
}
.event-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #363332;
  opacity: 0.8;
}
.section-title {
  margin-top: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 18px;
  color: #363332;
}
.speaker-grid {
  margin-top: 32px;
}
.speaker-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #363332;
  margin-bottom: 8px;
}

.speaker-about {
  overflow: hidden;
  max-height: 100px;
}

.event-card-column {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .event-card-column {
    max-width: 644px;
  }
}

@media screen and (min-width: 1170px) {
  .event-card-column {
    max-width: 966px;
  }
}

.sponsor-logo-container {
  width: 170px;
  height: 50px;
  padding-left: 5px;
}
.sponsor-logo {
  width: 120px;
  vertical-align: middle;
}

.speaker {
  padding: 20px 0px;
}

@media screen and (min-width: 600px) {
  .speaker {
    padding: 20px 10px;
  }
}

@media screen and (min-width: 960px) {
  .sponsor-logo-container {
    width: 250px;
    height: 100px;
  }
  .sponsor-logo {
    width: 200px;
    vertical-align: middle;
  }
  .speaker {
    width: 50%;
  }
}

@media screen and (min-width: 1264px) {
  .sponsor-logo {
    width: 250px;
    vertical-align: middle;
  }
}

.sponsor-logos-container {
  max-width: 600px;
}

@media screen and (min-width: 960px) {
  .sponsor-logos-container {
    max-width: none;
  }
}
</style>
