import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "Login" */ '@/views/auth/Login.vue'),
    meta: {
      title: pageTitle(String(i18n.t('auth.titles.signInOrCreateAccountGatherings'))),
      requires: { registration: false, authentication: false },
    },
    name: 'Login',
    path: '/login',
  },
  {
    component: () => import(/* webpackChunkName: "Passcode" */ '@/views/auth/Passcode.vue'),
    meta: {
      title: pageTitle(String(i18n.t('auth.titles.enterYourPasscode'))),
      requires: { storedEmail: true, authentication: false },
    },
    name: 'Passcode',
    path: '/passcode',
  },
  {
    name: 'Logout',
    path: '/logout',
    meta: {
      requires: { authentication: true },
    },
    beforeEnter: () => {
      store.dispatch('auth/forceLogout')
      router.push('/login')
    },
  },
]

routes = setLayout(routes, 'login')

export default routes
